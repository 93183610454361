// components/RemoteSession/SessionError.js
import React from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  errorMessage: {
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const SessionError = ({ error }) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h4" color="error" gutterBottom>
        Session Error
      </Typography>
      <Typography variant="body1" className={classes.errorMessage}>
        {error || 'An unexpected error occurred during the remote session.'}
      </Typography>
      <Typography variant="body2">
        Please try again later or contact support if the problem persists.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleGoBack}
        className={classes.button}
      >
        Go Back to Home
      </Button>
    </Box>
  );
};

export default SessionError;
