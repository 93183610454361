import { useState, useCallback, useRef } from 'react';
import { initializeStream, cleanupStream } from '../utils/streamUtils';
import { ScreenShareError } from '../utils/errors';

export const useScreenSharing = (options = {}) => {
  const {
    onError,
    onStart,
    onStop
  } = options;

  const [isSharing, setIsSharing] = useState(false);
  const [stream, setStream] = useState(null);
  const streamRef = useRef(null);

  const start = useCallback(async () => {
    try {
      const newStream = await initializeStream({
        video: {
          cursor: 'always',
          displaySurface: 'monitor',
          logicalSurface: true,
          width: { max: 1920 },
          height: { max: 1080 },
          frameRate: { max: 30 }
        }
      });

      streamRef.current = newStream;
      setStream(newStream);
      setIsSharing(true);
      onStart?.(newStream);

      newStream.getVideoTracks()[0].onended = () => {
        stop();
      };

      return newStream;
    } catch (error) {
      onError?.(new ScreenShareError('Failed to start screen sharing', error));
      throw error;
    }
  }, [onStart, onError]);

  const stop = useCallback(() => {
    if (streamRef.current) {
      cleanupStream(streamRef.current);
      streamRef.current = null;
      setStream(null);
      setIsSharing(false);
      onStop?.();
    }
  }, [onStop]);

  return {
    isSharing,
    stream,
    start,
    stop
  };
};