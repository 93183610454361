// components/RemoteSession/index.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RemoteSessionProvider } from '../../contexts/RemoteSessionContext';
import { socketManager } from '../../services/SocketManager';
import { useAuth } from '../../contexts/AuthContext';
import { showNotification } from '../../utils/notification';
import Layout from './Layout';
import ErrorBoundary from '../ErrorBoundary';
import SessionLoader from './SessionLoader';
import SessionError from './SessionError';
import useWebRTC from '../../hooks/useWebRTC';
import RemoteControlPanel from './RemoteControlPanel/index';
import ChatPanel from './ChatPanel';
import VideoPanel from './VideoPanel';
import ControlsPanel from './ControlsPanel';
import SessionInfo from './SessionInfo';
import { RemoteControlProvider } from '../../contexts/RemoteControlContext';
import { useSocketConnection } from '../../hooks/useSocketConnection';


const RemoteSession = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isLoading: authLoading } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const [sessionInfo, setSessionInfo] = useState(null);
  const [sessionDuration, setSessionDuration] = useState(0);
  const [networkStats, setNetworkStats] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isControlPanelOpen, setIsControlPanelOpen] = useState(false);
  const [isSessionInfoOpen, setIsSessionInfoOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('video');
  const [isAudioEnabled, setIsAudioEnabled] = useState(true);
  const [isVideoEnabled, setIsVideoEnabled] = useState(true);
  const [isRemoteAudioEnabled, setIsRemoteAudioEnabled] = useState(true);
  const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [mediaDevices, setMediaDevices] = useState({ audio: [], video: [] });
  const [activeDevices, setActiveDevices] = useState({ audio: null, video: null });
  const [connectionQuality, setConnectionQuality] = useState('good');
  const [remoteUserInfo, setRemoteUserInfo] = useState(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 3;
  const [isRecording, setIsRecording] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorder = useRef(null);
  const recordedChunks = useRef([]);
  const sessionTimer = useRef(null);
  const statsInterval = useRef(null);
  const recordingTimer = useRef(null);
  const pingInterval = useRef(null);
  const [messages, setMessages] = useState([]);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [error, setError] = useState(null);

  const {
    peerConnection,
    localStreamRef,
    remoteStreamRef,
    isRemoteControlActive,
    isControlling,
    screenSharingStream,
    isRemoteControlRequested,
    addLocalStream,
    initializeDataChannel,
    createOffer,
    cleanup,
    getComputerInfo,
    requestRemoteControl,
    approveRemoteControl,
    denyRemoteControl,
    stopRemoteControl,
    handleRemoteControlData,
    addScreenShare,
    stopScreenSharing,
    switchAudioDevice,
    switchVideoDevice,
    toggleAudio,
    toggleVideo,
    restartConnection,
    handleMediaError,
    dataChannel
  } = useWebRTC(roomId);

  const handleUserJoined = useCallback((data) => {
    setRemoteUserInfo(data);
    showNotification(`${data.name} joined the session`, 'info');
  }, []);

  const handleUserLeft = useCallback((data) => {
    showNotification(`${data.name} left the session`, 'info');
    if (data.role === 'serviceWorker') {
      cleanupSession();
      navigate('/dashboard');
    }
  }, [cleanupSession, navigate]);

  const handleSessionEnded = useCallback(() => {
    showNotification('Session ended by host', 'info');
    cleanupSession();
    navigate('/dashboard');
  }, [cleanupSession, navigate]);

  const handlePong = useCallback((data) => {
    const latency = Date.now() - data.timestamp;
    setNetworkStats(prev => ({ ...prev, latency }));
  }, []);

  const { isConnected, socketError, emit } = useSocketConnection({
    userId: currentUser?._id,
    role: currentUser?.role,
    handlers: {
      userJoined: handleUserJoined,
      userLeft: handleUserLeft,
      sessionEnded: handleSessionEnded,
      pong: handlePong,
    }
  });

  useEffect(() => {
    if (socketError) {
      setError(socketError);
    }
  }, [socketError]);

  const handleNewMessage = useCallback((message) => {
    setMessages(prev => [...prev, message]);
    if (!isChatOpen) {
      setUnreadMessages(prev => prev + 1);
      showNotification('New message received', 'info');
    }
  }, [isChatOpen]);

  const initializeSession = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 1280 }, height: { ideal: 720 } },
        audio: true
      });

      await addLocalStream(stream);

      initializeDataChannel();

      const sessionResponse = await emit('socket:getSessionInfo', { roomId });
      setSessionInfo(sessionResponse.sessionInfo);

      if (currentUser.role === 'serviceWorker') {
        await createOffer();
      }

      if (currentUser.role === 'user') {
        const computerInfo = await getComputerInfo();
        if (computerInfo) {
          await emit('socket:computerInfo', { roomId, ...computerInfo });
        }
      }

      setIsInitialized(true);
      showNotification('Session initialized successfully', 'success');

      pingInterval.current = setInterval(() => {
        emit('socket:ping', { roomId, timestamp: Date.now() });
      }, 5000);
    } catch (error) {
      console.error('Session initialization error:', error);
      setError('Failed to initialize session: ' + error.message);
      showNotification('Failed to initialize session', 'error');
    }
  }, [roomId, currentUser, addLocalStream, initializeDataChannel, createOffer, getComputerInfo, emit]);

  const cleanupSession = useCallback(() => {
    [sessionTimer, statsInterval, recordingTimer, pingInterval].forEach(timer => {
      if (timer.current) clearInterval(timer.current);
    });

    if (mediaRecorder.current?.state === 'recording') {
      mediaRecorder.current.stop();
    }

    cleanup();
    socketManager.emit('socket:leaveRoom', { roomId });
  }, [roomId, cleanup]);

  useEffect(() => {
    const handlers = {
      'userJoined': (data) => {
        setRemoteUserInfo(data);
        showNotification(`${data.name} joined the session`, 'info');
      },
      'userLeft': (data) => {
        showNotification(`${data.name} left the session`, 'info');
        if (data.role === 'serviceWorker') {
          cleanupSession();
          navigate('/dashboard');
        }
      },
      'sessionEnded': () => {
        showNotification('Session ended by host', 'info');
        cleanupSession();
        navigate('/dashboard');
      },
      'error': (error) => {
        showNotification(error.message, 'error');
        setError(error.message);
      },
      'pong': (data) => {
        const latency = Date.now() - data.timestamp;
        setNetworkStats(prev => ({ ...prev, latency }));
      }
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      socketManager.on(event, handler);
    });

    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        socketManager.off(event, handler);
      });
    };
  }, [navigate, cleanupSession]);

  const handleScreenShare = useCallback(async () => {
    try {
      if (!isScreenSharing) {
        const stream = await addScreenShare();
        if (stream) {
          setIsScreenSharing(true);
          socketManager.emit('socket:screenShareStarted', { roomId });
        }
      } else {
        stopScreenSharing();
        setIsScreenSharing(false);
        socketManager.emit('socket:screenShareStopped', { roomId });
      }
    } catch (error) {
      console.error('Screen sharing error:', error);
      showNotification('Failed to handle screen sharing', 'error');
    }
  }, [roomId, isScreenSharing, addScreenShare, stopScreenSharing]);

  const startConnectionMonitoring = useCallback(() => {
    if (!peerConnection) return;

    statsInterval.current = setInterval(async () => {
      try {
        const stats = await peerConnection.getStats();
        let totalPacketsLost = 0;
        let totalPackets = 0;

        stats.forEach(stat => {
          if (stat.type === 'inbound-rtp' || stat.type === 'outbound-rtp') {
            totalPacketsLost += stat.packetsLost || 0;
            totalPackets += stat.packetsSent || stat.packetsReceived || 0;
          }
        });

        const lossRate = totalPackets ? (totalPacketsLost / totalPackets) : 0;
        setConnectionQuality(
          lossRate < 0.01 ? 'good' :
          lossRate < 0.03 ? 'fair' : 'poor'
        );
      } catch (error) {
        console.error('Error monitoring connection:', error);
      }
    }, 5000);
  }, [peerConnection]);

  const fetchRemoteUserInfo = useCallback(async () => {
    try {
      const response = await socketManager.emit('socket:getRemoteUserInfo', { roomId });
      setRemoteUserInfo(response.userInfo);
    } catch (error) {
      console.error('Failed to fetch remote user info:', error);
    }
  }, [roomId]);

  const toggleRecording = useCallback(async () => {
    if (!isRecording) {
      try {
        const stream = new MediaStream([
          ...localStreamRef.current.getTracks(),
          ...(remoteStreamRef.current?.getTracks() || [])
        ]);
        
        mediaRecorder.current = new MediaRecorder(stream);
        recordedChunks.current = [];

        mediaRecorder.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            recordedChunks.current.push(event.data);
          }
        };

        mediaRecorder.current.onstop = () => {
          const blob = new Blob(recordedChunks.current, { type: 'video/webm' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.href = url;
          a.download = `session-${roomId}-${Date.now()}.webm`;
          a.click();
          URL.revokeObjectURL(url);
        };

        mediaRecorder.current.start();
        setIsRecording(true);
        showNotification('Recording started', 'info');
      } catch (error) {
        console.error('Failed to start recording:', error);
        showNotification('Failed to start recording', 'error');
      }
    } else {
      mediaRecorder.current?.stop();
      setIsRecording(false);
      showNotification('Recording stopped', 'info');
    }
  }, [roomId, isRecording]);

  const initializeMediaDevices = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setMediaDevices({
        audio: devices.filter(device => device.kind === 'audioinput'),
        video: devices.filter(device => device.kind === 'videoinput')
      });
    } catch (error) {
      console.error('Failed to get media devices:', error);
      handleMediaError(error);
    }
  }, [handleMediaError]);

  useEffect(() => {
    initializeMediaDevices();
    navigator.mediaDevices.ondevicechange = initializeMediaDevices;

    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, [initializeMediaDevices]);

  useEffect(() => {
    if (isInitialized) {
      startConnectionMonitoring();
      fetchRemoteUserInfo();
    }

    return () => {
      if (statsInterval.current) {
        clearInterval(statsInterval.current);
      }
      if (mediaRecorder.current && isRecording) {
        mediaRecorder.current.stop();
      }
    };
  }, [isInitialized, startConnectionMonitoring, fetchRemoteUserInfo]);

  const runDiagnostics = useCallback(async () => {
    try {
      const stats = await peerConnection.getStats();
      const diagnostics = {
        bandwidth: 0,
        packetsLost: 0,
        roundTripTime: 0,
        jitter: 0
      };

      stats.forEach(stat => {
        if (stat.type === 'inbound-rtp') {
          diagnostics.bandwidth = stat.bytesReceived;
          diagnostics.packetsLost = stat.packetsLost;
          diagnostics.jitter = stat.jitter;
        }
        if (stat.type === 'candidate-pair' && stat.state === 'succeeded') {
          diagnostics.roundTripTime = stat.currentRoundTripTime;
        }
      });

      setNetworkStats(diagnostics);
    } catch (error) {
      console.error('Diagnostics error:', error);
    }
  }, [peerConnection]);

  const handleTrackEnabled = useCallback((type, enabled) => {
    const stream = localStreamRef.current;
    if (!stream) return;

    const tracks = type === 'audio' ? stream.getAudioTracks() : stream.getVideoTracks();
    tracks.forEach(track => {
      track.enabled = enabled;
    });

    if (type === 'audio') {
      setIsAudioEnabled(enabled);
      socketManager.emit('socket:audioStateChanged', { roomId, enabled });
    } else {
      setIsVideoEnabled(enabled);
      socketManager.emit('socket:videoStateChanged', { roomId, enabled });
    }
  }, [roomId]);

  const handleRemoteMediaState = useCallback((data) => {
    if (data.type === 'audio') {
      setIsRemoteAudioEnabled(data.enabled);
    } else {
      setIsRemoteVideoEnabled(data.enabled);
    }
  }, []);

  const startSessionTimer = useCallback(() => {
    sessionTimer.current = setInterval(() => {
      setSessionDuration(prev => prev + 1);
    }, 1000);
  }, []);

  const handleMediaFailure = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: activeDevices.video ? { deviceId: activeDevices.video } : true,
        audio: activeDevices.audio ? { deviceId: activeDevices.audio } : true
      });
      
      if (localStreamRef.current) {
        localStreamRef.current.getTracks().forEach(track => track.stop());
      }
      
      localStreamRef.current = stream;
      addLocalStream(stream);
      
      showNotification('Media connection restored', 'success');
    } catch (error) {
      console.error('Media recovery failed:', error);
      showNotification('Failed to restore media connection', 'error');
    }
  }, [activeDevices, addLocalStream]);

  const handleDeviceChange = useCallback(async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices.filter(d => d.kind === 'audioinput');
      const videoDevices = devices.filter(d => d.kind === 'videoinput');

      const audioExists = audioDevices.some(d => d.deviceId === activeDevices.audio);
      const videoExists = videoDevices.some(d => d.deviceId === activeDevices.video);

      if (!audioExists || !videoExists) {
        await handleMediaFailure();
      }

      setMediaDevices({ audio: audioDevices, video: videoDevices });
    } catch (error) {
      console.error('Device change handling failed:', error);
    }
  }, [activeDevices, handleMediaFailure]);

  useEffect(() => {
    if (isInitialized) {
      startSessionTimer();
      statsInterval.current = setInterval(runDiagnostics, 3000);
    }

    return () => {
      if (sessionTimer.current) clearInterval(sessionTimer.current);
      if (statsInterval.current) clearInterval(statsInterval.current);
    };
  }, [isInitialized, runDiagnostics]);

  useEffect(() => {
    socketManager.on('remoteMediaStateChanged', handleRemoteMediaState);
    navigator.mediaDevices.ondevicechange = handleDeviceChange;

    return () => {
      socketManager.off('remoteMediaStateChanged', handleRemoteMediaState);
      navigator.mediaDevices.ondevicechange = null;
    };
  }, [handleRemoteMediaState, handleDeviceChange]);

  if (authLoading || !currentUser) {
    return (
      <ErrorBoundary>
        <SessionLoader message="Authenticating..." />
      </ErrorBoundary>
    );
  }

  if (!currentUser?.role) {
    return (
      <ErrorBoundary>
        <SessionError 
          error="Invalid user role" 
          onRetry={() => navigate('/dashboard')} 
        />
      </ErrorBoundary>
    );
  }

  if (error) {
    return (
      <ErrorBoundary>
        <SessionError error={error} onRetry={initializeSession} />
      </ErrorBoundary>
    );
  }

  if (!isInitialized || !localStreamRef.current) {
    return (
      <ErrorBoundary>
        <SessionLoader />
      </ErrorBoundary>
    );
  }

  return (
    <RemoteSessionProvider
      roomId={roomId}
      initialStream={localStreamRef.current}
      user={currentUser}
      sessionInfo={{
        ...sessionInfo,
        duration: sessionDuration,
        networkStats,
        activeDevices,
        unreadMessages
      }}
    >
      <RemoteControlProvider>
        <Layout
          localStream={localStreamRef.current}
          remoteStream={remoteStreamRef.current}
          screenStream={screenSharingStream}
          isRemoteControlActive={isRemoteControlActive}
          isControlling={isControlling}
          isRemoteControlRequested={isRemoteControlRequested}
          onRequestRemoteControl={requestRemoteControl}
          onApproveRemoteControl={approveRemoteControl}
          onDenyRemoteControl={denyRemoteControl}
          onStopRemoteControl={stopRemoteControl}
          onRemoteControlData={handleRemoteControlData}
          isChatOpen={isChatOpen}
          setIsChatOpen={setIsChatOpen}
          isControlPanelOpen={isControlPanelOpen}
          setIsControlPanelOpen={setIsControlPanelOpen}
          sessionInfo={sessionInfo}
          isScreenSharing={isScreenSharing}
          onScreenShare={handleScreenShare}
          connectionQuality={connectionQuality}
          mediaDevices={mediaDevices}
          onSwitchAudioDevice={switchAudioDevice}
          onSwitchVideoDevice={switchVideoDevice}
          onToggleAudio={() => handleTrackEnabled('audio', !isAudioEnabled)}
          onToggleVideo={() => handleTrackEnabled('video', !isVideoEnabled)}
          isRecording={isRecording}
          onToggleRecording={toggleRecording}
          remoteUserInfo={remoteUserInfo}
          onRestartConnection={restartConnection}
          isAudioEnabled={isAudioEnabled}
          isVideoEnabled={isVideoEnabled}
          isRemoteAudioEnabled={isRemoteAudioEnabled}
          isRemoteVideoEnabled={isRemoteVideoEnabled}
          sessionDuration={sessionDuration}
          networkStats={networkStats}
          onMediaFailure={handleMediaFailure}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          messages={messages}
          unreadMessages={unreadMessages}
          onNewMessage={handleNewMessage}
          isSessionInfoOpen={isSessionInfoOpen}
          setIsSessionInfoOpen={setIsSessionInfoOpen}
          recordingTime={recordingTime}
          onEndSession={() => {
            socketManager.emit('socket:endSession', { roomId });
            cleanupSession();
            navigate('/dashboard');
          }}
        />
      </RemoteControlProvider>
    </RemoteSessionProvider>
  );
};

export default RemoteSession;