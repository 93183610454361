export const getDashboardRoute = (user) => {
  if (!user || typeof user !== 'object') {
    return '/';
  }

  const role = user.role?.toLowerCase();
  
  switch (role) {
    case 'admin':
      return '/admin-dashboard';
    case 'service_worker':
      return '/service-worker-dashboard';
    case 'user':
      return '/user-dashboard';
    case 'new':
      return '/onboarding';
    default:
      return '/';
  }
};

export const isValidUser = (user) => {
  return user && 
         typeof user === 'object' && 
         'role' in user &&
         typeof user.role === 'string';
};