// utils/streamUtils.js
import { ScreenShareError } from '../errors/ScreenShareError';

export const initializeStream = async (constraints) => {
    try {
      return await navigator.mediaDevices.getDisplayMedia(constraints);
    } catch (error) {
      throw new ScreenShareError('Failed to initialize stream', error);
    }
  };
  
  export const cleanupStream = (stream) => {
    if (stream) {
      stream.getTracks().forEach(track => track.stop());
    }
  };