//FrontEnd/src/config.js
const BASE_URL = process.env.REACT_APP_API_URL || 'https://it-guy.seo-top.dev';
const API_BASE = `${BASE_URL}/api`;

export const config = {
  BASE_URL,
  API_GATEWAY_URL: API_BASE,
  SOCKET_GATEWAY_URL: `${BASE_URL.replace('http', 'ws').replace('https', 'wss')}/socket`,
  AUTH_URL: `${API_BASE}/auth`,
  AUTH: {
    GOOGLE_CLIENT_ID: process.env.REACT_APP_GOOGLE_CLIENT_ID || '51585799797-qf3svhlj7kn5eg2lonn4q5b8cmq9u6u3.apps.googleusercontent.com',
    CALLBACK_URL: `${BASE_URL}/auth/callback`
  },
  SERVICES: {
    AUTH: '/auth',
    TICKETS: '/tickets',
    REMOTE: '/remote',
    USERS: '/users',
    CHAT: '/chat',
    NOTIFICATIONS: '/notifications'
  },
  VERSION: process.env.REACT_APP_VERSION || 'v1'
};