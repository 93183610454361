import React, { useEffect, useRef, useState } from 'react';
import { Box, IconButton, Typography, Badge } from '@mui/material';
import {
  MicOff,
  VideocamOff,
  PictureInPicture,
  Fullscreen,
  FullscreenExit,
  SignalCellular4Bar,
  SignalCellular2Bar,
  SignalCellular0Bar
} from '@mui/icons-material';
import { useRemoteSessionContext } from '../../../contexts/RemoteSessionContext';
import VideoStream from './VideoStream';
import styles from './VideoPanel.module.css';

const VideoPanel = ({
  localStream,
  remoteStream,
  screenStream,
  isScreenSharing,
  isLocalAudioEnabled,
  isLocalVideoEnabled,
  isRemoteAudioEnabled,
  isRemoteVideoEnabled,
  connectionQuality
}) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isPiP, setIsPiP] = useState(false);
  const [activeStream, setActiveStream] = useState('remote');
  const mainVideoRef = useRef(null);
  const pipVideoRef = useRef(null);
  const containerRef = useRef(null);
  
  const { currentUser } = useRemoteSessionContext();

  // Handle fullscreen
  const toggleFullscreen = async () => {
    try {
      if (!document.fullscreenElement) {
        await containerRef.current.requestFullscreen();
        setIsFullscreen(true);
      } else {
        await document.exitFullscreen();
        setIsFullscreen(false);
      }
    } catch (error) {
      console.error('Fullscreen error:', error);
    }
  };

  // Handle Picture-in-Picture
  const togglePiP = async () => {
    try {
      if (document.pictureInPictureElement) {
        await document.exitPictureInPicture();
        setIsPiP(false);
      } else if (pipVideoRef.current) {
        await pipVideoRef.current.requestPictureInPicture();
        setIsPiP(true);
      }
    } catch (error) {
      console.error('PiP error:', error);
    }
  };

  // Connection quality indicator
  const QualityIndicator = () => {
    const indicators = {
      good: <SignalCellular4Bar color="success" />,
      fair: <SignalCellular2Bar color="warning" />,
      poor: <SignalCellular0Bar color="error" />
    };
    return (
      <Badge
        badgeContent={connectionQuality}
        color={connectionQuality === 'good' ? 'success' : connectionQuality === 'fair' ? 'warning' : 'error'}
      >
        {indicators[connectionQuality]}
      </Badge>
    );
  };

  // Handle stream switching
  const handleStreamSwitch = () => {
    setActiveStream(activeStream === 'remote' ? 'local' : 'remote');
  };

  useEffect(() => {
    // Reset active stream when screen sharing starts/stops
    if (isScreenSharing) {
      setActiveStream('screen');
    } else {
      setActiveStream('remote');
    }
  }, [isScreenSharing]);

  return (
    <Box ref={containerRef} className={styles.videoPanelContainer}>
      <Box className={styles.mainVideoContainer}>
        <VideoStream
          ref={mainVideoRef}
          stream={activeStream === 'screen' ? screenStream : 
                 activeStream === 'local' ? localStream : remoteStream}
          muted={activeStream === 'local'}
          className={styles.mainVideo}
        />
        
        <Box className={styles.videoOverlay}>
          <Box className={styles.topControls}>
            <QualityIndicator />
            {!isLocalAudioEnabled && <MicOff color="error" />}
            {!isLocalVideoEnabled && <VideocamOff color="error" />}
          </Box>
          
          <Box className={styles.bottomControls}>
            <Typography variant="body2">
              {activeStream === 'local' ? currentUser.name : 'Remote User'}
            </Typography>
            <Box>
              <IconButton onClick={togglePiP} disabled={!document.pictureInPictureEnabled}>
                <PictureInPicture />
              </IconButton>
              <IconButton onClick={toggleFullscreen}>
                {isFullscreen ? <FullscreenExit /> : <Fullscreen />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={styles.pipVideoContainer} onClick={handleStreamSwitch}>
        <VideoStream
          ref={pipVideoRef}
          stream={activeStream === 'remote' ? localStream : remoteStream}
          muted={activeStream === 'remote'}
          className={styles.pipVideo}
        />
        
        <Box className={styles.pipOverlay}>
          <Typography variant="caption">
            {activeStream === 'remote' ? 'You' : 'Remote User'}
          </Typography>
          {activeStream === 'remote' ? (
            <>
              {!isLocalAudioEnabled && <MicOff fontSize="small" />}
              {!isLocalVideoEnabled && <VideocamOff fontSize="small" />}
            </>
          ) : (
            <>
              {!isRemoteAudioEnabled && <MicOff fontSize="small" />}
              {!isRemoteVideoEnabled && <VideocamOff fontSize="small" />}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default VideoPanel; 