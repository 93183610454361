import React from 'react';
import { Typography, Button, Container, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    padding: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  message: {
    marginBottom: theme.spacing(3),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      <Typography variant="h4" className={classes.title}>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" className={classes.message}>
        We're sorry, but an unexpected error occurred. Our team has been notified and is working on a solution.
      </Typography>
      <Box>
        <Typography variant="body2" color="error">
          Error details: {error.message}
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        onClick={resetErrorBoundary}
        className={classes.button}
      >
        Try Again
      </Button>
    </Container>
  );
};

export default ErrorFallback;
