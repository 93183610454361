// components/shared/ConnectionStatus.js
import React from 'react';
import { Box, Typography, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useExpirationTime } from '../../hooks/useExpirationTime';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  indicator: {
    display: 'inline-block',
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  online: {
    backgroundColor: theme.palette.success.main,
  },
  offline: {
    backgroundColor: theme.palette.error.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  sessionInfo: {
    marginLeft: 'auto',
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  },
  errorText: {
    marginLeft: theme.spacing(1),
    color: theme.palette.error.main,
  }
}));

export const ConnectionStatus = ({ isConnected, isConnecting, error }) => {
  const classes = useStyles();
  const remainingTime = useExpirationTime();

  const getConnectionStatus = () => {
    if (error) return { text: 'Error', color: 'error' };
    if (isConnecting) return { text: 'Connecting...', color: 'warning' };
    return isConnected 
      ? { text: 'Connected', color: 'success' }
      : { text: 'Disconnected', color: 'error' };
  };

  const formatRemainingTime = () => {
    if (!remainingTime) return null;
    const minutes = Math.ceil(remainingTime / 60000);
    if (minutes <= 0) return null;
    
    return {
      text: `Session expires in ${minutes} min`,
      isWarning: remainingTime < 300000 // Less than 5 minutes
    };
  };

  const status = getConnectionStatus();
  const formattedTime = formatRemainingTime();

  return (
    <Box className={classes.root}>
      <div className={classes.statusContainer}>
        <span className={`${classes.indicator} ${classes[status.color]}`} />
        <Typography variant="body2">
          Connection: {status.text}
        </Typography>
        {error && (
          <Typography variant="body2" className={classes.errorText}>
            {error}
          </Typography>
        )}
      </div>
      
      {formattedTime && (
        <Chip
          className={classes.sessionInfo}
          size="small"
          label={formattedTime.text}
          color={formattedTime.isWarning ? 'secondary' : 'default'}
        />
      )}
    </Box>
  );
};