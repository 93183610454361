// FrontEnd/src/components/AuthCallback.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import { getDashboardRoute } from '../utils/routeHelpers';
import { tokenManager } from '../services/TokenManager';
import logger from '../utils/logger';

export default function AuthCallback() {
  const { login, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    const handleAuth = async () => {
      if (isProcessing || isAuthenticated) {
        logger.debug('AuthCallback - Skipping auth processing', { isProcessing, isAuthenticated });
        return;
      }
      
      try {
        setIsProcessing(true);
        logger.debug('AuthCallback - Starting auth callback processing');
        
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const returnedState = params.get('state');
        const storedState = sessionStorage.getItem('oauth_state');
        
        logger.debug('AuthCallback - Validating state', { 
          hasToken: !!token,
          hasReturnedState: !!returnedState,
          hasStoredState: !!storedState
        });

        if (!returnedState || returnedState !== storedState) {
          throw new Error('Invalid OAuth state');
        }
        
        sessionStorage.removeItem('oauth_state');
        
        if (!token) {
          throw new Error('No token received');
        }

        tokenManager.clearSilent();
        const { user } = await login(token);
        
        if (!user || !user.role) {
          throw new Error('Invalid user data received');
        }

        const redirectUrl = sessionStorage.getItem('redirectUrl');
        sessionStorage.removeItem('redirectUrl');
        
        const targetRoute = redirectUrl || getDashboardRoute(user);
        logger.debug('AuthCallback - Authentication successful, redirecting to:', targetRoute);
        navigate(targetRoute, { replace: true });
        
      } catch (error) {
        logger.error('AuthCallback - Auth failed:', error);
        setError(error.message);
        tokenManager.clearSilent();
        navigate('/', { replace: true });
      } finally {
        setIsProcessing(false);
      }
    };

    handleAuth();
  }, [isAuthenticated, isProcessing, login, navigate]);

  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h5" color="error" gutterBottom>
          Authentication Error
        </Typography>
        <Typography color="textSecondary">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
      <CircularProgress />
      <Typography style={{ marginTop: 16 }}>
        Completing authentication...
      </Typography>
    </Box>
  );
}