// FrontEnd/src/services/TokenManager.js
import { EventEmitter } from 'events';
import { jwtDecode } from 'jwt-decode';
import api from '../services/api';

class TokenManager extends EventEmitter {
  static STORAGE_KEYS = {
    ACCESS_TOKEN: 'auth_token',    // Unified key for access token
    REFRESH_TOKEN: 'refresh_token',
    USER: 'current_user'
  };

  constructor() {
    super();
    if (TokenManager.instance) {
      return TokenManager.instance;
    }

    this.token = null;
    this.refreshToken = null;
    this.expirationTimer = null;
    this.refreshTimeout = null;
    this.expirationListeners = new Set();

    TokenManager.instance = this;
    this.initialize();
  }

  initialize() {
    try {
      this.token = localStorage.getItem(TokenManager.STORAGE_KEYS.ACCESS_TOKEN);
      this.refreshToken = localStorage.getItem(TokenManager.STORAGE_KEYS.REFRESH_TOKEN);

      if (this.token && !this.isTokenExpired()) {
        const decoded = jwtDecode(this.token);
        if (decoded.exp) {
          this.setupTokenRefresh(this.token);
        }
      } else {
        this.clear();
      }
    } catch (error) {
      console.error('Token initialization error:', error);
      this.clear();
    }
  }

  setToken(token, refreshToken = null) {
    if (!token) return;
    try {
      this.token = token;
      localStorage.setItem('auth_token', token);

      // Set up expiration timer
      const decoded = jwtDecode(token);
      if (decoded.exp) {
        const expiresIn = decoded.exp * 1000 - Date.now();
        this.setupExpirationTimer(expiresIn);
      }
    } catch (error) {
      console.error('Error setting token:', error);
      this.clear();
    }
  }

  getToken() {
    if (!this.token) {
      this.token = localStorage.getItem('auth_token');
    }

    if (this.token && this.isTokenExpired()) {
      this.clear();
      return null;
    }

    return this.token;
  }

  getRefreshToken() {
    if (!this.refreshToken) {
      this.refreshToken = localStorage.getItem(TokenManager.STORAGE_KEYS.REFRESH_TOKEN);
    }
    return this.refreshToken;
  }

  clear() {
    this.token = null;
    this.refreshToken = null;
    localStorage.removeItem(TokenManager.STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(TokenManager.STORAGE_KEYS.REFRESH_TOKEN);
    localStorage.removeItem(TokenManager.STORAGE_KEYS.USER);

    if (this.expirationTimer) {
      clearTimeout(this.expirationTimer);
      this.expirationTimer = null;
    }
    if (this.refreshTimeout) {
      clearTimeout(this.refreshTimeout);
      this.refreshTimeout = null;
    }
  }

  clearSilent() {
    this.clear();
  }

  setupTokenRefresh(token) {
    try {
      if (this.refreshTimeout) {
        clearTimeout(this.refreshTimeout);
      }

      const decoded = jwtDecode(token);
      const expiresIn = (decoded.exp * 1000) - Date.now();

      // Refresh 1 minute before expiration
      const refreshTime = Math.max(expiresIn - (60 * 1000), 0);

      if (refreshTime > 0) {
        this.refreshTimeout = setTimeout(async () => {
          try {
            await this.refreshTokenRequest();
          } catch (error) {
            this.notifyExpiration();
          }
        }, refreshTime);
      }
    } catch (error) {
      console.error('Error setting up token refresh:', error);
      throw error;
    }
  }

  async refreshTokenRequest() {
    try {
      const refreshToken = this.getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await api.post('/api/auth/refresh', { refreshToken });

      if (response.data?.accessToken) {
        this.setToken(
          response.data.accessToken,
          response.data.refreshToken || this.refreshToken
        );
        return true;
      }

      throw new Error('No access token received');
    } catch (error) {
      console.error('Token refresh failed:', error);
      this.clear();
      window.dispatchEvent(new Event('auth:tokenRefreshFailed'));
      throw error;
    }
  }

  isTokenExpired() {
    const token = this.getToken();
    if (!token) return true;

    try {
      const decoded = jwtDecode(token);
      return decoded.exp * 1000 <= Date.now();
    } catch {
      return true;
    }
  }

  isAuthenticated() {
    return !!this.getToken() && !this.isTokenExpired();
  }

  // Event listener methods
  addExpirationListener(listener) {
    this.expirationListeners.add(listener);
  }

  removeExpirationListener(listener) {
    this.expirationListeners.delete(listener);
  }

  notifyExpiration() {
    this.expirationListeners.forEach(listener => {
      try {
        listener();
      } catch (error) {
        console.error('Error in expiration listener:', error);
      }
    });
  }

  // User management
  setUser(user) {
    if (!user) return false;
    try {
      const userData = {
        ...user,
        id: user._id || user.id,
        _id: user._id || user.id
      };
      localStorage.setItem(TokenManager.STORAGE_KEYS.USER, JSON.stringify(userData));
      return true;
    } catch (error) {
      console.error('Error setting user:', error);
      return false;
    }
  }

  getUser() {
    try {
      const userStr = localStorage.getItem(TokenManager.STORAGE_KEYS.USER);
      return userStr ? JSON.parse(userStr) : null;
    } catch (error) {
      console.error('Error getting user:', error);
      return null;
    }
  }
}

// Create and export singleton instance
export const tokenManager = new TokenManager();