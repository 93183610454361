// FrontEnd/src/AppRoutes.js
import React, { Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from './contexts/AuthContext';
import ErrorBoundary from './ErrorBoundary';
// Import components
import UserDashboard from './components/UserDashboard';
import ServiceWorkerDashboard from './components/ServiceWorkerDashboard';
import AdminDashboard from './components/AdminDashboard';
import Onboarding from './components/Onboarding';
import TicketDetails from './components/TicketDetails';
import LandingPage from './components/LandingPage';
import IssueSelectionPage from './components/IssueSelectionPage';
import CreateTicketPage from './components/CreateTicketPage';
import RemoteSessionWrapper from './components/RemoteSession/RemoteSessionWrapper';
import AuthCallback from './components/AuthCallback';
import ErrorFallback from './components/ErrorFallback';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingSpinner from './components/common/LoadingSpinner';
import { getDashboardRoute, isValidUser } from './utils/routeHelpers';
import Login from './components/Login';
import logger from './utils/logger';

function AppRoutes() {
  const { user, loading, isAuthenticated, isInitialized } = useAuth();
  const location = useLocation();
  const publicRoutes = ['/', '/login', '/auth/callback'];
  const isPublicRoute = publicRoutes.includes(location.pathname);

  if (!isInitialized || loading) {
    return <LoadingSpinner message="Checking authentication..." />;
  }

  logger.debug('AppRoutes - Auth State:', {
    user,
    loading,
    isAuthenticated,
    currentPath: location.pathname,
    isPublicRoute,
    userRole: user?.role,
    isValidUser: isValidUser(user)
  });

  // Only redirect if we're authenticated, on a public route, and have a valid user
  if (isAuthenticated && isPublicRoute && isValidUser(user) && user.role !== 'new') {
    const dashboardRoute = getDashboardRoute(user);
    logger.error('Redirecting to dashboard:', dashboardRoute);
    return <Navigate to={dashboardRoute} replace />;
  }

  // Handle unauthenticated users trying to access protected routes
  if (!isAuthenticated && !isPublicRoute) {
    logger.error('Redirecting unauthenticated user to landing page');
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/" replace />;
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<LoadingSpinner message="Loading..." />}>
        <Routes>
          {/* Public Routes */}
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />

          {/* Protected Routes */}
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                {user ? (
                  <Navigate to={getDashboardRoute(user)} replace />
                ) : (
                  <Navigate to="/" replace />
                )}
              </ProtectedRoute>
            }
          />

          <Route
            path="/service-worker-dashboard/*"
            element={
              <ProtectedRoute allowedRoles={['service_worker', 'admin']}>
                <ServiceWorkerDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/user-dashboard/*"
            element={
              <ProtectedRoute allowedRoles={['user']}>
                <UserDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/admin-dashboard/*"
            element={
              <ProtectedRoute allowedRoles={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            path="/onboarding"
            element={
              user && (!user.role || user.role === 'new') ? (
                <Onboarding />
              ) : (
                <Navigate to={getDashboardRoute(user)} />
              )
            }
          />

          {/* Feature Routes - Protected based on role */}
          <Route
            path="/issue-selection"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin']}>
                <IssueSelectionPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/create-ticket"
            element={
              <ProtectedRoute allowedRoles={['user', 'admin']}>
                <CreateTicketPage />
              </ProtectedRoute>
            }
          />

          <Route
            path="/tickets/:id"
            element={
              <ProtectedRoute allowedRoles={['user', 'service_worker', 'admin']}>
                <TicketDetails />
              </ProtectedRoute>
            }
          />

          <Route
            path="/remote-session/:roomId"
            element={
              <ProtectedRoute>
                <ErrorBoundary>
                  <RemoteSessionWrapper />
                </ErrorBoundary>
              </ProtectedRoute>
            }
          />

          {/* Catch-all route */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

export default AppRoutes;