// contexts/RemoteSessionContext.js
import { createContext, useContext, useReducer, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { socketManager } from '../services/SocketManager';
import { showNotification } from '../utils/notification';

const initialState = {
  localStream: null,
  remoteStream: null,
  screenStream: null,
  isScreenSharing: false,
  isMuted: false,
  isVideoOff: false,
  isRemoteVideoOff: false,
  isRemoteMuted: false,
  isRemoteControlActive: false,
  isControlling: false,
  controlMode: 'mouse',
  chatMessages: [],
  error: null,
  isLoading: true,
  isChatOpen: false,
  isSessionInfoOpen: false
};

function remoteSessionReducer(state, action) {
  switch (action.type) {
    case 'SET_STREAMS':
      return {
        ...state,
        localStream: action.payload.localStream || state.localStream,
        remoteStream: action.payload.remoteStream || state.remoteStream,
        screenStream: action.payload.screenStream || state.screenStream
      };
    case 'TOGGLE_SCREEN_SHARE':
      return {
        ...state,
        isScreenSharing: !state.isScreenSharing
      };
    case 'TOGGLE_AUDIO':
      return {
        ...state,
        isMuted: !state.isMuted
      };
    case 'TOGGLE_VIDEO':
      return {
        ...state,
        isVideoOff: !state.isVideoOff
      };
    case 'SET_REMOTE_CONTROL':
      return {
        ...state,
        isRemoteControlActive: action.payload
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload
      };
    case 'ADD_CHAT_MESSAGE':
      return {
        ...state,
        chatMessages: [...state.chatMessages, action.payload]
      };
    case 'TOGGLE_CHAT':
      return {
        ...state,
        isChatOpen: !state.isChatOpen
      };
    case 'TOGGLE_SESSION_INFO':
      return {
        ...state,
        isSessionInfoOpen: !state.isSessionInfoOpen
      };
    case 'SET_LOCAL_STREAM':
      return {
        ...state,
        localStream: action.payload
      };
    case 'RESET_STATE':
      return {
        ...initialState,
        isLoading: false
      };
    case 'SET_REMOTE_CONTROL_STATE':
      return {
        ...state,
        remoteControl: {
          ...state.remoteControl,
          ...action.payload
        }
      };
    case 'SET_REMOTE_CONTROL_PERMISSIONS':
      return {
        ...state,
        remoteControl: {
          ...state.remoteControl,
          permissions: action.payload
        }
      };
    default:
      return state;
  }
}

export const RemoteSessionContext = createContext();

export function RemoteSessionProvider({ children, roomId, initialStream = null, user }) {
  const [state, dispatch] = useReducer(remoteSessionReducer, {
    ...initialState,
    localStream: initialStream
  });
  const navigate = useNavigate();
  const socketInitialized = useRef(false);
  const cleanupRef = useRef({ localStream: null, remoteStream: null, screenStream: null });

  // Media initialization
  const initializeMedia = useCallback(async () => {
    if (!state.localStream) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: { width: { ideal: 1280 }, height: { ideal: 720 } },
          audio: true
        });
        dispatch({ type: 'SET_LOCAL_STREAM', payload: stream });
        cleanupRef.current.localStream = stream;
      } catch (error) {
        console.error('Failed to get user media:', error);
        dispatch({
          type: 'SET_ERROR',
          payload: 'Failed to access camera and microphone'
        });
      }
    }
  }, [state.localStream]);

  // Socket initialization
  const initializeSocket = useCallback(async () => {
    if (socketInitialized.current || !roomId || !user) return;

    try {
      // Ensure socket is connected
      if (!socketManager.isConnected(user._id)) {
        await socketManager.connect({
          userId: user._id,
          role: user.role,
          socketId: user._id
        });
      }

      // Join room
      await socketManager.joinRoom(roomId, {
        userId: user._id,
        role: user.role,
        name: user.name
      });

      socketInitialized.current = true;
      dispatch({ type: 'SET_LOADING', payload: false });
      showNotification('Connected to remote session', 'success');
    } catch (error) {
      console.error('Failed to initialize socket:', error);
      dispatch({
        type: 'SET_ERROR',
        payload: 'Failed to connect to session'
      });
      showNotification('Failed to connect to session', 'error');
    }
  }, [roomId, user]);

  // Event Handlers
  const handleUserJoined = useCallback((data) => {
    console.log('Remote user joined:', data);
    showNotification(`${data.name} joined the session`, 'info');
  }, []);

  const handleUserLeft = useCallback((data) => {
    console.log('Remote user left:', data);
    showNotification(`${data.name} left the session`, 'info');
  }, []);

  const handleRemoteControl = useCallback((data) => {
    dispatch({ type: 'SET_REMOTE_CONTROL', payload: data.isActive });
    showNotification(
      data.isActive ? 'Remote control activated' : 'Remote control deactivated',
      'info'
    );
  }, []);

  const handleChatMessage = useCallback((message) => {
    dispatch({
      type: 'ADD_CHAT_MESSAGE',
      payload: { ...message, sender: 'remote' }
    });
  }, []);

  const handleRemoteControlRequest = useCallback(async (permissions) => {
    try {
      await socketManager.emit('remoteControl:request', { 
        roomId, 
        permissions 
      });
      showNotification('Remote control request sent', 'info');
    } catch (error) {
      console.error('Failed to request remote control:', error);
      showNotification('Failed to request remote control', 'error');
    }
  }, [roomId]);

  const handleRemoteControlApproval = useCallback((data) => {
    dispatch({ 
      type: 'SET_REMOTE_CONTROL_STATE', 
      payload: { 
        isActive: true, 
        isControlling: true 
      } 
    });
    dispatch({ 
      type: 'SET_REMOTE_CONTROL_PERMISSIONS', 
      payload: data.permissions 
    });
    showNotification('Remote control access granted', 'success');
  }, []);

  // Setup and Cleanup
  useEffect(() => {
    initializeMedia();
    initializeSocket();

    // Event listeners
    socketManager.on('userJoined', handleUserJoined);
    socketManager.on('userLeft', handleUserLeft);
    socketManager.on('remoteControl', handleRemoteControl);
    socketManager.on('chatMessage', handleChatMessage);
    socketManager.on('remoteControl:approved', handleRemoteControlApproval);
    socketManager.on('remoteControl:denied', () => {
      dispatch({ 
        type: 'SET_REMOTE_CONTROL_STATE', 
        payload: { 
          isActive: false, 
          isControlling: false 
        } 
      });
      showNotification('Remote control request denied', 'warning');
    });
    socketManager.on('remoteControl:stopped', () => {
      dispatch({ 
        type: 'SET_REMOTE_CONTROL_STATE', 
        payload: { 
          isActive: false, 
          isControlling: false 
        } 
      });
      showNotification('Remote control stopped', 'info');
    });

    return () => {
      // Cleanup streams
      Object.values(cleanupRef.current).forEach(stream => {
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }
      });

      // Cleanup socket
      if (roomId) {
        socketManager.emit('leaveRoom', { roomId });
      }
      
      socketManager.off('userJoined', handleUserJoined);
      socketManager.off('userLeft', handleUserLeft);
      socketManager.off('remoteControl', handleRemoteControl);
      socketManager.off('chatMessage', handleChatMessage);
      socketManager.off('remoteControl:approved');
      socketManager.off('remoteControl:denied');
      socketManager.off('remoteControl:stopped');
      
      socketInitialized.current = false;
    };
  }, [roomId, initializeMedia, initializeSocket, handleUserJoined, handleUserLeft, handleRemoteControl, handleChatMessage, handleRemoteControlApproval]);

  const contextValue = {
    ...state,
    sendChatMessage: useCallback((message) => {
      socketManager.emit('socket:chatMessage', { roomId, message });
      dispatch({
        type: 'ADD_CHAT_MESSAGE',
        payload: { text: message, sender: 'user', timestamp: new Date() }
      });
    }, [roomId]),

    toggleAudio: useCallback(() => {
      if (state.localStream) {
        const audioTrack = state.localStream.getAudioTracks()[0];
        if (audioTrack) {
          audioTrack.enabled = !audioTrack.enabled;
          dispatch({ type: 'TOGGLE_AUDIO' });
        }
      }
    }, [state.localStream]),

    toggleVideo: useCallback(() => {
      if (state.localStream) {
        const videoTrack = state.localStream.getVideoTracks()[0];
        if (videoTrack) {
          videoTrack.enabled = !videoTrack.enabled;
          dispatch({ type: 'TOGGLE_VIDEO' });
        }
      }
    }, [state.localStream]),

    toggleScreenShare: useCallback(async () => {
      try {
        if (!state.screenStream) {
          const stream = await navigator.mediaDevices.getDisplayMedia({ video: true });
          dispatch({ type: 'SET_STREAMS', payload: { screenStream: stream } });
          cleanupRef.current.screenStream = stream;
          socketManager.emit('socket:screenShareStarted', { roomId });
        } else {
          state.screenStream.getTracks().forEach(track => track.stop());
          dispatch({ type: 'SET_STREAMS', payload: { screenStream: null } });
          cleanupRef.current.screenStream = null;
          socketManager.emit('socket:screenShareStopped', { roomId });
        }
        dispatch({ type: 'TOGGLE_SCREEN_SHARE' });
      } catch (error) {
        console.error('Screen sharing error:', error);
        showNotification('Failed to share screen', 'error');
      }
    }, [roomId, state.screenStream]),

    toggleRemoteControl: useCallback(() => {
      const newState = !state.isRemoteControlActive;
      socketManager.emit('socket:toggleRemoteControl', {
        roomId,
        isActive: newState
      });
      dispatch({ type: 'SET_REMOTE_CONTROL', payload: newState });
    }, [roomId, state.isRemoteControlActive]),

    endCall: useCallback(() => {
      Object.values(cleanupRef.current).forEach(stream => {
        if (stream) {
          stream.getTracks().forEach(track => track.stop());
        }
      });
      socketManager.emit('socket:leaveRoom', { roomId });
      dispatch({ type: 'RESET_STATE' });
      navigate('/dashboard');
    }, [roomId, navigate]),

    toggleChat: useCallback(() => {
      dispatch({ type: 'TOGGLE_CHAT' });
    }, []),

    toggleSessionInfo: useCallback(() => {
      dispatch({ type: 'TOGGLE_SESSION_INFO' });
    }, [])
  };

  return (
    <RemoteSessionContext.Provider value={contextValue}>
      {children}
    </RemoteSessionContext.Provider>
  );
}

export function useRemoteSessionContext() {
  const context = useContext(RemoteSessionContext);
  if (!context) {
    throw new Error('useRemoteSessionContext must be used within a RemoteSessionProvider');
  }
  return context;
}
