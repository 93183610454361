// components/RemoteSession/SessionLoader.js
import React from 'react';
import { CircularProgress, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  progress: {
    marginBottom: theme.spacing(2),
  },
}));

const SessionLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <CircularProgress className={classes.progress} />
      <Typography variant="h6">
        Loading Remote Session...
      </Typography>
    </Box>
  );
};

export default SessionLoader;

