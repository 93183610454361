import { socketManager } from '../services/SocketManager';

class SocketService {
  constructor() {
    this.connectionQueue = [];
    this.isConnecting = false;
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
    this.reconnectDelay = 1000; // Base delay in ms
    this.healthCheckInterval = null;
  }

  async connect() {
    if (socketManager.isConnected()) {
      return socketManager.getSocket();
    }

    if (this.isConnecting) {
      return new Promise((resolve, reject) => {
        this.connectionQueue.push({ resolve, reject });
      });
    }

    try {
      this.isConnecting = true;
      await this.attemptConnection();
      this.reconnectAttempts = 0; // Reset on successful connection
      this.resolveConnectionQueue();
      return socketManager.getSocket();
    } catch (error) {
      console.error('Socket connection error:', error);
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        return this.handleReconnection();
      }
      this.rejectConnectionQueue(error);
      throw new Error('Max reconnection attempts reached');
    } finally {
      this.isConnecting = false;
    }
  }

  async attemptConnection() {
    try {
      await socketManager.connect();
      // Add connection event listeners
      const socket = socketManager.getSocket();
      if (socket) {
        socket.on('disconnect', () => this.handleDisconnect());
        socket.on('error', (error) => this.handleError(error));
      }
    } catch (error) {
      throw error;
    }
  }

  async handleReconnection() {
    const delay = this.reconnectDelay * Math.pow(2, this.reconnectAttempts);
    this.reconnectAttempts++;
    
    console.log(`Attempting reconnection ${this.reconnectAttempts}/${this.maxReconnectAttempts} in ${delay}ms`);
    
    await new Promise(resolve => setTimeout(resolve, delay));
    return this.connect();
  }

  handleDisconnect() {
    console.log('Socket disconnected, attempting reconnection...');
    if (!this.isConnecting) {
      this.connect().catch(console.error);
    }
  }

  handleError(error) {
    console.error('Socket error:', error);
    if (socketManager.isConnected()) {
      this.disconnect();
    }
  }

  resolveConnectionQueue() {
    this.connectionQueue.forEach(({ resolve }) => resolve(socketManager.getSocket()));
    this.connectionQueue = [];
  }

  rejectConnectionQueue(error) {
    this.connectionQueue.forEach(({ reject }) => reject(error));
    this.connectionQueue = [];
  }

  disconnect() {
    this.reconnectAttempts = 0; // Reset attempts on manual disconnect
    return socketManager.disconnect();
  }

  getSocket() {
    return socketManager.getSocket();
  }

  isConnected() {
    return socketManager.isConnected();
  }
}

export const socketService = new SocketService(); 