import React from 'react';
import { CircularProgress, Typography } from '@material-ui/core';

const LoadingSpinner = ({ message = 'Loading...' }) => {
  return (
    <div style={{ textAlign: 'center', padding: '2rem' }}>
      <CircularProgress />
      <Typography variant="body1" style={{ marginTop: '1rem' }}>
        {message}
      </Typography>
    </div>
  );
};

export default LoadingSpinner; 