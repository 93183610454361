// FrontEnd/src/contexts/AuthContext.js
import React, { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react';
import api from '../services/api';
import { tokenManager } from '../services/TokenManager';
import { socketManager } from '../services/SocketManager';
import { showNotification } from '../utils/notification';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [state, setState] = useState({
    user: null,
    loading: true,
    error: null,
    isInitialized: false,
    isAuthenticated: false
  });

  const navigate = useNavigate();
  const socketInitialized = useRef(false);
  const isLoggingOut = useRef(false);
  const mounted = useRef(true);

  // Initialize auth state
  useEffect(() => {
    const initialize = async () => {
      try {
        const token = tokenManager.getToken();
        
        if (!token) {
          setState(prev => ({
            ...prev,
            loading: false,
            isInitialized: true,
            isAuthenticated: false
          }));
          return;
        }

        // Try to get user data with the stored token
        try {
          const response = await api.get('/api/auth/current-user', {
            headers: { Authorization: `Bearer ${token}` }
          });

          if (response.data?.user) {
            setState(prev => ({
              ...prev,
              user: response.data.user,
              loading: false,
              isInitialized: true,
              isAuthenticated: true
            }));
          } else {
            // Invalid response, clear token
            tokenManager.clear();
            setState(prev => ({
              ...prev,
              loading: false,
              isInitialized: true,
              isAuthenticated: false
            }));
          }
        } catch (error) {
          // Handle 401 errors by clearing token
          if (error.response?.status === 401) {
            tokenManager.clear();
          }
          setState(prev => ({
            ...prev,
            loading: false,
            error: error.message,
            isInitialized: true,
            isAuthenticated: false
          }));
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        setState(prev => ({
          ...prev,
          loading: false,
          error: error.message,
          isInitialized: true,
          isAuthenticated: false
        }));
      }
    };

    initialize();
  }, []);

  const login = async (token) => {
    try {
      console.log('Login called with token:', token);
      
      // Store token first
      tokenManager.setToken(token);
      
      // Fetch user data
      const response = await api.get('/api/auth/current-user', {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (!response.data?.user) {
        throw new Error('Invalid user data received');
      }

      const userData = response.data.user;
      
      setState(prev => ({
        ...prev,
        user: userData,
        loading: false,
        error: null,
        isAuthenticated: true,
        isInitialized: true
      }));

      return { user: userData, token };
    } catch (error) {
      console.error('Login error:', error);
      tokenManager.clear();
      setState(prev => ({
        ...prev,
        user: null,
        loading: false,
        error: error.message,
        isAuthenticated: false,
        isInitialized: true
      }));
      throw error;
    }
  };

  const logout = useCallback(async () => {
    if (isLoggingOut.current) return;

    isLoggingOut.current = true;
    try {
      await api.post('/api/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Clear any local state
      setState(prev => ({
        ...prev,
        user: null,
        loading: false,
        error: null,
        isInitialized: true,
        isAuthenticated: false
      }));

      socketManager.disconnect();
      socketInitialized.current = false;
      isLoggingOut.current = false;
      window.dispatchEvent(new CustomEvent('auth:logout'));
    }
  }, []);

  const handleTokenExpired = useCallback(async () => {
    if (window.location.pathname === '/login' || isLoggingOut.current) {
      return;
    }

    isLoggingOut.current = true;

    try {
      setState(prev => ({
        ...prev,
        user: null,
        loading: true,
        error: null,
        isInitialized: false,
        isAuthenticated: false
      }));

      socketManager.disconnect();
      socketInitialized.current = false;
      tokenManager.clearSilent();

      try {
        await Promise.race([
          api.post('/api/auth/logout'),
          new Promise((_, reject) => setTimeout(() => reject(new Error('Logout timeout')), 3000))
        ]);
      } catch (error) {
        console.warn('Logout error:', error);
      }

      navigate('/login', { replace: true });
    } finally {
      isLoggingOut.current = false;
    }
  }, [navigate]);

  const checkAuth = useCallback(async () => {
    try {
      const response = await api.get('/api/auth/current-user');
      const userData = response.data.user;

      setState(prev => ({
        ...prev,
        user: userData,
        loading: false,
        error: null,
        isInitialized: true,
        isAuthenticated: true
      }));

      return true;
    } catch (error) {
      setState(prev => ({
        ...prev,
        user: null,
        loading: false,
        error: error.message,
        isInitialized: true,
        isAuthenticated: false
      }));
      return false;
    }
  }, []);

  useEffect(() => {
    const handleTokenExpiredEvent = () => {
      if (!isLoggingOut.current) {
        logout();
        showNotification('Your session has expired. Please login again.', 'warning');
      }
    };

    tokenManager.addExpirationListener(handleTokenExpiredEvent);

    return () => {
      tokenManager.removeExpirationListener(handleTokenExpiredEvent);
      if (socketInitialized.current) {
        socketManager.disconnect();
        socketInitialized.current = false;
      }
    };
  }, [logout]);

  const initializeSocket = useCallback(async () => {
    if (socketInitialized.current || !state.user?._id) return;

    const abortController = new AbortController();

    try {
      const token = tokenManager.getToken();
      if (!token || tokenManager.isTokenExpired()) {
        console.log('Skipping socket initialization - invalid token or missing user data');
        return;
      }

      await socketManager.connect(token, abortController.signal);
      socketInitialized.current = true;
    } catch (error) {
      console.error('Socket initialization failed:', error);
      socketInitialized.current = false;
    }

    return () => {
      abortController.abort();
      if (socketInitialized.current) {
        socketManager.disconnect();
        socketInitialized.current = false;
      }
    };
  }, [state.user]);

  useEffect(() => {
    const handleTokenRefreshFailed = () => {
      handleTokenExpired();
    };

    window.addEventListener('auth:tokenRefreshFailed', handleTokenRefreshFailed);

    return () => {
      window.removeEventListener('auth:tokenRefreshFailed', handleTokenRefreshFailed);
    };
  }, [handleTokenExpired]);

  const value = {
    user: state.user,
    loading: state.loading,
    error: state.error,
    isInitialized: state.isInitialized,
    isAuthenticated: state.isAuthenticated,
    socketStatus: {
      isConnected: socketInitialized.current,
      isConnecting: false,
      error: null
    },
    login,
    logout,
    checkAuth,
    handleTokenExpired,
    initializeSocket,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};