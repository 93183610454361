// components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { config } from '../config';

export default function Header() {
  const { currentUser, logout } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleGoogleLogin = () => {
    console.log('Initiating Google login');
    const backendUrl = process.env.REACT_APP_API_URL || 'https://it-guy.seo-top.dev';
    window.location.href = `${backendUrl}/auth/google`;
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          IT Support Platform
        </Typography>
        {currentUser ? (
          <>
            <Button color="inherit" component={Link} to="/dashboard">
              Dashboard
            </Button>
            <Avatar 
              alt={currentUser.name} 
              src={currentUser.picture} 
              onClick={handleClick} 
              style={{ cursor: 'pointer', marginLeft: '8px' }}
            />
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose} component={Link} to="/dashboard">
                Dashboard
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </>
        ) : (
          <Button 
            color="inherit" 
            onClick={handleGoogleLogin}
          >
            Login with Google
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}