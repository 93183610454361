const formatLogEntry = (message, type = 'out', meta = {}) => {
  try {
    const formattedMessage = typeof message === 'object' ? 
      JSON.stringify(message, getCircularReplacer()) : 
      String(message);

    return JSON.stringify({
      message: formattedMessage,
      timestamp: new Date().toISOString(),
      type,
      app_name: 'it-guy-frontend',
      level: meta.level || type,
      ...meta
    });
  } catch (err) {
    return JSON.stringify({
      message: '[Unserializable Object]',
      timestamp: new Date().toISOString(),
      type,
      app_name: 'it-guy-frontend',
      level: meta.level || type
    });
  }
};

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return '[Circular]';
      }
      seen.add(value);
    }
    return value;
  };
};

const logger = {
  info: (message, meta = {}) => {
    const logEntry = formatLogEntry(message, 'info', meta);
    console.info(logEntry);
  },
  error: (message, meta = {}) => {
    const logEntry = formatLogEntry(message, 'error', meta);
    console.error(logEntry);
  },
  warn: (message, meta = {}) => {
    const logEntry = formatLogEntry(message, 'warn', meta);
    console.warn(logEntry);
  },
  debug: (message, meta = {}) => {
    const logEntry = formatLogEntry(message, 'debug', meta);
    console.debug(logEntry);
  }
};

export default logger;

