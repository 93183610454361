export const formatDateTime = (date) => {
  return new Date(date).toLocaleString();
};

export const formatTime = (date) => {
  return new Date(date).toLocaleTimeString();
};

export const formatMessageTime = (date) => {
  return new Date(date).toLocaleTimeString([], { 
    hour: '2-digit', 
    minute: '2-digit' 
  });
}; 