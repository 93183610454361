import React, { useState, useEffect, useCallback } from 'react';
import { 
  Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, 
  Paper, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, 
  DialogTitle, Snackbar, IconButton, Grid, Chip, LinearProgress
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Attachment, Close } from '@material-ui/icons';
import { debounce } from 'lodash';
import api from '../services/api';
import { showNotification } from '../utils/notification';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
  priceDisplay: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[100],
    borderRadius: theme.shape.borderRadius,
  },
  attachmentChip: {
    margin: theme.spacing(0.5),
  },
  hidden: {
    display: 'none',
  },
  previewCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
  },
}));

const urgencyLevels = [
  { value: 'low', label: 'Low (up to a week)', multiplier: 1 },
  { value: 'medium', label: 'Medium (up to 3 days)', multiplier: 1.25 },
  { value: 'high', label: 'High (need it today)', multiplier: 1.75 },
  { value: 'urgent', label: 'Urgent (need it in the next hour)', multiplier: 2.5 },
];

const MAX_ATTACHMENT_SIZE = 5 * 1024 * 1024; // 5MB
const MAX_ATTACHMENTS = 3;

export default function CreateTicketPage() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [urgency, setUrgency] = useState('low');
  const [basePrice, setBasePrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'info' });
  const [previewMode, setPreviewMode] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (location.state) {
      const { category, subcategory } = location.state;
      setTitle(`${category} - ${subcategory}`);
    } else {
      navigate('/issue-selection');
    }
  }, [location.state, navigate]);

  useEffect(() => {
    const selectedUrgency = urgencyLevels.find(level => level.value === urgency);
    setFinalPrice(basePrice * selectedUrgency.multiplier);
  }, [urgency, basePrice]);

  const validateInput = (input) => {
    return input.replace(/<script.*?>.*?<\/script>/gi, '')
                .replace(/[&<>"']/g, (m) => ({
                  '&': '&amp;',
                  '<': '&lt;',
                  '>': '&gt;',
                  '"': '&quot;',
                  "'": '&#39;'
                })[m]);
  };

  const validateForm = () => {
    const newErrors = {};
    if (description.trim().length < 10) {
      newErrors.description = 'Description must be at least 10 characters long';
    }
    if (description.length > 1000) {
      newErrors.description = 'Description must not exceed 1000 characters';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleDescriptionChange = (e) => {
    const validatedInput = validateInput(e.target.value);
    setDescription(validatedInput.slice(0, 1000));
    debouncedPriceCalculation(validatedInput);
  };

  const handleUrgencyChange = (e) => {
    setUrgency(e.target.value);
  };

  const handleConfirmSubmit = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleSubmit = async () => {
    setOpenConfirmDialog(false);
    if (!validateForm()) return;

    setIsLoading(true);
    setProgress(0);

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('urgency', urgency);
      attachments.forEach(file => formData.append('attachments', file));

      const response = await api.post('/api/tickets', formData, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgress(percentCompleted);
        }
      });

      setIsLoading(false);
      showNotification('Ticket created successfully', 'success');
      navigate('/dashboard');
    } catch (error) {
      setIsLoading(false);
      console.error('Error creating ticket:', error);
      showNotification('Error creating ticket: ' + error.response?.data?.error || error.message, 'error');
    }
  };

  const calculateBasePrice = async (description) => {
    setIsLoading(true);
    try {
      // In a real application, you would call your AI service here
      const response = await api.post('/api/calculate-price', { description });
      setBasePrice(response.data.basePrice);
    } catch (error) {
      console.error('Error calculating price:', error);
      showNotification('Error calculating price. Using default.', 'error');
      setBasePrice(100); // Default price
    }
    setIsLoading(false);
  };

  const debouncedPriceCalculation = useCallback(
    debounce((description) => calculateBasePrice(description), 500),
    []
  );

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validFiles = files.filter(file => file.size <= MAX_ATTACHMENT_SIZE);

    if (validFiles.length + attachments.length > MAX_ATTACHMENTS) {
      showNotification(`You can only upload up to ${MAX_ATTACHMENTS} files`, 'error');
      return;
    }

    if (validFiles.length !== files.length) {
      showNotification('Some files were too large and were not added', 'warning');
    }

    setAttachments(prevAttachments => [...prevAttachments, ...validFiles]);
  };

  const handleRemoveAttachment = (index) => {
    setAttachments(prevAttachments => prevAttachments.filter((_, i) => i !== index));
  };

  const handleSaveAsDraft = () => {
    // In a real application, you would save the draft to the server or local storage
    localStorage.setItem('ticketDraft', JSON.stringify({ title, description, urgency, attachments }));
    showNotification('Draft saved successfully', 'success');
  };

  const togglePreviewMode = () => {
    setPreviewMode(!previewMode);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h4" gutterBottom>Create New Support Ticket</Typography>
      <Paper className={classes.paper}>
        <form onSubmit={(e) => { e.preventDefault(); handleConfirmSubmit(); }} className={classes.form}>
          <TextField
            label="Title"
            value={title}
            InputProps={{ readOnly: true }}
            fullWidth
          />
          {!previewMode ? (
            <TextField
              label="Description"
              multiline
              rows={4}
              value={description}
              onChange={handleDescriptionChange}
              fullWidth
              required
              error={!!errors.description}
              helperText={errors.description || `${description.length}/1000 characters`}
            />
          ) : (
            <Paper elevation={0} className={classes.previewCard}>
              <Typography variant="body1">{description}</Typography>
            </Paper>
          )}
          <FormControl fullWidth>
            <InputLabel>Urgency</InputLabel>
            <Select
              value={urgency}
              onChange={handleUrgencyChange}
              required
            >
              {urgencyLevels.map((level) => (
                <MenuItem key={level.value} value={level.value}>
                  {level.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.priceDisplay}>
            <Typography variant="subtitle1">
              Estimated Price: {isLoading ? <CircularProgress size={20} /> : `$${finalPrice.toFixed(2)}`}
            </Typography>
            <Typography variant="caption">
              Base Price: ${basePrice.toFixed(2)} | Urgency Multiplier: {urgencyLevels.find(level => level.value === urgency).multiplier}x
            </Typography>
          </div>
          <input
            accept="image/*,.pdf,.doc,.docx"
            className={classes.hidden}
            id="attachment-button-file"
            multiple
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="attachment-button-file">
            <Button
              variant="contained"
              component="span"
              startIcon={<Attachment />}
              disabled={attachments.length >= MAX_ATTACHMENTS}
            >
              Add Attachments (Max {MAX_ATTACHMENTS})
            </Button>
          </label>
          <Grid container spacing={1}>
            {attachments.map((file, index) => (
              <Grid item key={index}>
                <Chip
                  label={file.name}
                  onDelete={() => handleRemoveAttachment(index)}
                  className={classes.attachmentChip}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirmSubmit}
                className={classes.submitButton}
                disabled={isLoading}
              >
                {isLoading ? <CircularProgress size={24} /> : 'Create Ticket'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSaveAsDraft}
                className={classes.submitButton}
              >
                Save as Draft
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={togglePreviewMode}
                className={classes.submitButton}
              >
                {previewMode ? 'Edit' : 'Preview'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Ticket Submission"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit this ticket? The estimated price is ${finalPrice.toFixed(2)}.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert onClose={() => setSnackbar({ ...snackbar, open: false })} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
      {isLoading && <LinearProgress variant="determinate" value={progress} />}
    </div>
  );
}