import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Typography, Paper, TextField, Button, List, ListItem, ListItemText } from '@material-ui/core';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';

export default function TicketDetails() {
  const { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [ticket, setTicket] = useState(null);
  const [comment, setComment] = useState('');

  const fetchTicket = useCallback(async () => {
    try {
      const response = await api.get(`/tickets/${id}`);
      setTicket(response.data);
    } catch (error) {
      console.error('Error fetching ticket:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchTicket();
  }, [fetchTicket]);

  const handleAddComment = async () => {
    try {
      await api.post(`/tickets/${id}/comment`, { content: comment });
      setComment('');
      fetchTicket();
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const handleInitiateRemoteSession = async () => {
    try {
      const response = await api.post('/service-workers/initiate-remote-connection', { ticketId: id });
      navigate(`/remote-session/${response.data.roomId}`);
    } catch (error) {
      console.error('Error initiating remote session:', error);
    }
  };

  if (!ticket) return <Typography>Loading...</Typography>;

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h5">{ticket.title}</Typography>
      <Typography>Status: {ticket.status}</Typography>
      <Typography>Priority: {ticket.priority}</Typography>
      <Typography>Description: {ticket.description}</Typography>
      {ticket.assignedTo && (
        <Typography>Assigned to: {ticket.assignedTo.name}</Typography>
      )}
      {currentUser.role === 'service_worker' && (
        <Button onClick={handleInitiateRemoteSession}>Start Remote Session</Button>
      )}
      <List>
        {ticket.comments.map((comment, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={comment.content}
              secondary={`By ${comment.user.name} on ${new Date(comment.createdAt).toLocaleString()}`}
            />
          </ListItem>
        ))}
      </List>
      <TextField
        label="Add a comment"
        multiline
        rows={4}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        fullWidth
      />
      <Button onClick={handleAddComment}>Add Comment</Button>
    </Paper>
  );
}