// FrontEnd/src/components/Login.js
import React from 'react';
import { Typography, Container, Button, CircularProgress } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { getDashboardRoute } from '../utils/routeHelpers';
import logger from '../utils/logger';

export default function Login() {
  const navigate = useNavigate();
  const { isAuthenticated, isInitialized, user } = useAuth();

  logger.debug('Login component state', {
    isAuthenticated,
    isInitialized,
    hasUser: !!user,
    userRole: user?.role,
    redirectUrl: sessionStorage.getItem('redirectUrl')
  });

  React.useEffect(() => {
    if (isAuthenticated && user?.role) {
      const redirectUrl = sessionStorage.getItem('redirectUrl');
      const targetRoute = redirectUrl || getDashboardRoute(user);
      console.log('Login - Redirecting authenticated user:', {
        redirectUrl,
        targetRoute,
        userRole: user.role
      });
      navigate(targetRoute, { replace: true });
    }
  }, [isAuthenticated, user, navigate]);

  const handleGoogleLogin = () => {
    const backendURL = process.env.REACT_APP_API_URL || 'https://it-guy.seo-top.dev/api';
    const state = Math.random().toString(36).substring(7);
    sessionStorage.setItem('oauth_state', state);
    window.location.href = `${backendURL}/auth/google?state=${state}`;
  };

  if (!isInitialized) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" align="center" gutterBottom>
        Welcome to IT Support Platform
      </Typography>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handleGoogleLogin}
      >
        Login with Google
      </Button>
    </Container>
  );
}