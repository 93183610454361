// App.js
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, createHashRouter } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import AppRoutes from './AppRoutes';
import Header from './components/Header';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CircularProgress } from '@material-ui/core';
import logger from './utils/logger';

// Route observer component
function RouteObserver() {
  const location = useLocation();

  useEffect(() => {
    logger.debug('Route changed', {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
      fullPath: `${location.pathname}${location.search}${location.hash}`
    });
  }, [location]);

  return null;
}

function MainApp() {
  const location = useLocation();
  const { checkAuth } = useAuth();
  const [isInitialized, setIsInitialized] = useState(false);
  const initRef = useRef(false);

  useEffect(() => {
    const initializeAuth = async () => {
      if (initRef.current) {
        console.log('Auth already initialized, skipping');
        return;
      }
      console.log('Initializing auth...');
      initRef.current = true;

      try {
        const result = await checkAuth();
        console.log('Auth check result:', result);
        setIsInitialized(true);
      } catch (error) {
        console.error('Auth initialization failed:', error);
        setIsInitialized(true); // Still set to true to prevent loops
      }
    };

    initializeAuth();
  }, [checkAuth]);

  console.log('MainApp render state:', { isInitialized, pathname: location.pathname });

  if (!isInitialized) {
    return <CircularProgress />;
  }

  return (
    <>
      <RouteObserver />
      {!location.pathname.includes('/auth/') && <Header />}
      <AppRoutes />
    </>
  );
}

function App() {
  return (
    <AuthProvider>
      <MainApp />
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </AuthProvider>
  );
}

export default App;