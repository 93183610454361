import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default function TicketList({ tickets }) {
  return (
    <>
      <Typography variant="h6">Tickets</Typography>
      <List>
        {tickets.map((ticket) => (
          <ListItem key={ticket._id} button component={Link} to={`/tickets/${ticket._id}`}>
            <ListItemText 
              primary={ticket.title} 
              secondary={`Status: ${ticket.status} | Priority: ${ticket.priority}`} 
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}