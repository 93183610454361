// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layoutContainer__fbk6p {
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 16px;
  position: relative;
  background-color: var(--background-color);
}

.Layout_mainPanel__jAWT8 {
  flex: 1 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.Layout_sidePanel__yF7ro {
  background-color: var(--surface-color);
  border-radius: 8px;
  box-shadow: var(--shadow-elevation-2);
  overflow: hidden;
}

@media (max-width: 600px) {
  .Layout_layoutContainer__fbk6p {
    padding: 8px;
  }
  
  .Layout_sidePanel__yF7ro {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50vh;
    z-index: 1000;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/RemoteSession/Layout/Layout.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,aAAa;EACb,kBAAkB;EAClB,yCAAyC;AAC3C;;AAEA;EACE,SAAO;EACP,aAAa;EACb,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,qCAAqC;EACrC,gBAAgB;AAClB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".layoutContainer {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  padding: 16px;\n  position: relative;\n  background-color: var(--background-color);\n}\n\n.mainPanel {\n  flex: 1;\n  min-height: 0;\n  display: flex;\n  flex-direction: column;\n}\n\n.sidePanel {\n  background-color: var(--surface-color);\n  border-radius: 8px;\n  box-shadow: var(--shadow-elevation-2);\n  overflow: hidden;\n}\n\n@media (max-width: 600px) {\n  .layoutContainer {\n    padding: 8px;\n  }\n  \n  .sidePanel {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    height: 50vh;\n    z-index: 1000;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layoutContainer": `Layout_layoutContainer__fbk6p`,
	"mainPanel": `Layout_mainPanel__jAWT8`,
	"sidePanel": `Layout_sidePanel__yF7ro`
};
export default ___CSS_LOADER_EXPORT___;
