import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { tokenManager } from '../services/TokenManager';
import { socketManager } from '../services/SocketManager';
import { showNotification } from '../utils/notification';

export const useTokenHandler = () => {
  const navigate = useNavigate();
  const isHandlingExpiration = useRef(false);

  const handleTokenExpiration = useCallback(() => {
    if (isHandlingExpiration.current || window.location.pathname === '/login') {
      return;
    }

    isHandlingExpiration.current = true;
    try {
      if (typeof socketManager !== 'undefined') {
        socketManager.disconnect();
      }
      tokenManager.removeToken();
      showNotification('Your session has expired. Please log in again.', 'warning');
      navigate('/login', { replace: true });
    } finally {
      isHandlingExpiration.current = false;
    }
  }, [navigate]);

  return {
    handleTokenExpiration,
    checkTokenValidity: useCallback(() => {
      if (sessionStorage.getItem('isAuthenticated') === 'true') {
        return true;
      }

      if (!tokenManager.isAuthenticated() || tokenManager.isTokenExpired()) {
        handleTokenExpiration();
        return false;
      }
      return true;
    }, [handleTokenExpiration])
  };
};