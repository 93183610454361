// hooks/useSocketConnection.js
import { useState, useEffect, useCallback, useRef } from 'react';
import { socketManager } from '../services/SocketManager';
import { tokenManager } from '../services/TokenManager';
import { useAuth } from '../contexts/AuthContext';

export const useSocketConnection = () => {
  const [state, setState] = useState({
    isConnected: false,
    isConnecting: false,
    error: null,
    socket: null
  });

  const mountedRef = useRef(true);
  const reconnectTimerRef = useRef(null);
  const reconnectAttemptsRef = useRef(0);
  const { currentUser } = useAuth();

  const connect = useCallback(async () => {
    if (!currentUser?._id || state.isConnecting) {
      return null;
    }

    try {
      setState(prev => ({ ...prev, isConnecting: true, error: null }));

      const token = tokenManager.getToken();
      if (!token) {
        throw new Error('No authentication token available');
      }

      console.debug('Initializing socket connection:', {
        userId: currentUser._id,
        role: currentUser.role,
        hasToken: !!token
      });

      const socket = await socketManager.initialize({
        token,
        userId: currentUser._id,
        role: currentUser.role
      });

      if (!mountedRef.current) return null;

      socket.on('connect', () => {
        console.debug('Socket connected:', socket.id);
        setState(prev => ({ ...prev, isConnected: true, error: null }));
      });

      socket.on('disconnect', (reason) => {
        console.debug('Socket disconnected:', reason);
        setState(prev => ({ ...prev, isConnected: false }));
      });

      socket.on('connect_error', (error) => {
        console.error('Socket connection error:', error);
        setState(prev => ({ ...prev, error: error.message }));
      });

      reconnectAttemptsRef.current = 0;
      setState({
        isConnected: socket.connected,
        isConnecting: false,
        error: null,
        socket
      });

      return socket;
    } catch (error) {
      console.error('Socket connection error:', error);
      if (mountedRef.current) {
        setState({
          isConnected: false,
          isConnecting: false,
          error: error.message,
          socket: null
        });

        if (reconnectAttemptsRef.current < 3) {
          const delay = Math.min(1000 * Math.pow(2, reconnectAttemptsRef.current), 5000);
          console.debug(`Scheduling reconnection attempt ${reconnectAttemptsRef.current + 1} in ${delay}ms`);
          
          reconnectTimerRef.current = setTimeout(() => {
            reconnectAttemptsRef.current++;
            connect();
          }, delay);
        } else {
          console.warn('Max reconnection attempts reached');
        }
      }
      return null;
    }
  }, [currentUser, state.isConnecting]);

  useEffect(() => {
    mountedRef.current = true;
    
    if (currentUser?._id) {
      console.debug('Initiating socket connection for user:', currentUser._id);
      connect().catch(console.error);
    }

    return () => {
      console.debug('Cleaning up socket connection');
      mountedRef.current = false;
      if (reconnectTimerRef.current) {
        clearTimeout(reconnectTimerRef.current);
      }
      socketManager.disconnect();
    };
  }, [currentUser, connect]);

  const emit = useCallback((event, data) => {
    if (!socketManager.socket) {
      console.warn('Socket not initialized');
      return Promise.reject(new Error('Socket not initialized'));
    }
    
    console.debug('Emitting socket event:', { event, data });
    
    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Socket emit timeout'));
      }, 5000);

      socketManager.emit(event, data, (response) => {
        clearTimeout(timeout);
        if (response?.error) {
          console.error('Socket emit error:', response.error);
          reject(new Error(response.error));
        } else {
          console.debug('Socket emit success:', { event, response });
          resolve(response);
        }
      });
    });
  }, []);

  const on = useCallback((event, callback) => {
    if (!socketManager.socket) {
      console.warn('Socket not initialized');
      return;
    }
    console.debug('Registering socket event listener:', event);
    socketManager.on(event, callback);
  }, []);

  const off = useCallback((event, callback) => {
    if (!socketManager.socket) {
      return;
    }
    console.debug('Removing socket event listener:', event);
    socketManager.off(event, callback);
  }, []);

  return {
    isConnected: state.isConnected,
    isConnecting: state.isConnecting,
    error: state.error,
    connect,
    socketManager,
    on,
    off,
    emit
  };
};