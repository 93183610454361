import { useState, useEffect, useCallback, useRef } from 'react';
import Peer from 'simple-peer';
import { connectSocket, socketEmit, socketOn, socketOff, requestRemoteControl, approveRemoteControl, denyRemoteControl } from '../services/api';
import { showNotification } from '../utils/notification';
import { useRemoteControlSession } from './useRemoteControl';
import { useScreenSharing } from './useScreenSharing';
import { useNavigate } from 'react-router-dom';
import { socketManager } from '../services/SocketManager';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';

const useWebRTC = (roomId) => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  if (!currentUser) {
    console.error('No user found in useWebRTC hook');
    throw new Error('User authentication required');
  }

  const isServiceWorker = currentUser.role === 'serviceWorker';

  const [localStream, setLocalStream] = useState(null);
  const [remoteStream, setRemoteStream] = useState(null);
  const [isCallStarted, setIsCallStarted] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isVideoOff, setIsVideoOff] = useState(false);
  const [isPeerConnected, setIsPeerConnected] = useState(false);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [error, setError] = useState(null);
  const [remoteUser, setRemoteUser] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState('Initializing...');
  const [chatMessages, setChatMessages] = useState([]);
  const peerRef = useRef(null);
  const socketRef = useRef();
  const localStreamRef = useRef();
  const remoteStreamRef = useRef();
  const [isRoomReady, setIsRoomReady] = useState(false);
  const [isInitiator, setIsInitiator] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [remoteReady, setRemoteReady] = useState(false);
  const pendingCandidates = useRef([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [screenSharingStream, setScreenSharingStream] = useState(null);
  const remoteControlRef = useRef(null);
  const screenStreamRef = useRef(null);
  const inputManagerRef = useRef(null);
  const [showRemoteControlUI, setShowRemoteControlUI] = useState(false);
  const [screenShareStream, setScreenShareStream] = useState(null);
  const [isRemoteControlRequested, setIsRemoteControlRequested] = useState(false);
  const [isRemoteControlActive, setIsRemoteControlActive] = useState(false);
  const dataChannelRef = useRef(null);
  const [remoteVideoStream, setRemoteVideoStream] = useState(null);
  const [remoteScreenStream, setRemoteScreenStream] = useState(null);
  const [isScreenSharing, setIsScreenSharing] = useState(false);
  const [remoteControlStream, setRemoteControlStream] = useState(null);
  const [showRemoteControlDialog, setShowRemoteControlDialog] = useState(false);
  const [isControlling, setIsControlling] = useState(false);
  const [peerConnection, setPeerConnection] = useState(null);
  const remoteControlContainerRef = useRef(null);
  const [isConnecting, setIsConnecting] = useState(false);

  const {
    handleRemoteControl,
    handleRemoteControlClick,
    handleRemoteControlScroll,
    remoteControlStreamRef,
    hasJoinedRoom,
  } = useRemoteControlSession(roomId, currentUser.role, peerRef);

  const {
    screenStream,
    setScreenStream,
  } = useScreenSharing(roomId, peerRef);

  const getComputerInfo = useCallback(async () => {
    try {
      const response = await api.get('/api/computer-info');
      return response.data;
    } catch (error) {
      console.error('Error fetching computer info:', error);
      return null;
    }
  }, []);

  //Remote Control
  const handleRemoteControlData = useCallback((data) => {
    if (isRemoteControlActive) {
      switch (data.type) {
        case 'mousemove':
          window.dispatchEvent(new MouseEvent('mousemove', {
            clientX: data.x * window.innerWidth,
            clientY: data.y * window.innerHeight,
            view: window,
            bubbles: true
          }));
          break;
        case 'mousedown':
        case 'mouseup':
          window.dispatchEvent(new MouseEvent(data.type, {
            clientX: data.x * window.innerWidth,
            clientY: data.y * window.innerHeight,
            button: data.button,
            view: window,
            bubbles: true
          }));
          break;
        case 'wheel':
          window.dispatchEvent(new WheelEvent('wheel', {
            deltaX: data.deltaX,
            deltaY: data.deltaY,
            view: window,
            bubbles: true
          }));
          break;
        case 'keydown':
        case 'keyup':
          window.dispatchEvent(new KeyboardEvent(data.type, {
            key: data.key,
            keyCode: data.keyCode,
            which: data.keyCode,
            view: window,
            bubbles: true
          }));
          break;
      }
    }
  }, [isRemoteControlActive]);

  const denyRemoteControl = useCallback(() => {
    socketEmit('denyRemoteControl', { roomId });
    setIsRemoteControlRequested(false);
  }, [roomId]);

  const stopRemoteControl = useCallback(() => {
    console.log('Stopping remote control');
    socketEmit('stopRemoteControl', { roomId });
    setIsRemoteControlActive(false);
    setIsControlling(false);
    if (currentUser.role === 'user' && screenSharingStream) {
      screenSharingStream.getTracks().forEach(track => track.stop());
      setScreenSharingStream(null);
    }
    showNotification('Remote control stopped', 'info');
  }, [roomId, currentUser.role, screenSharingStream]);

  const approveRemoteControl = useCallback(() => {
    console.log('Approving remote control in useWebRTC');
    socketEmit('approveRemoteControl', { roomId });
  }, [roomId]);

  const startRemoteControl = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      if (remoteControlRef.current) {
        remoteControlRef.current.srcObject = stream;
        setRemoteControlStream(stream);
      }
      approveRemoteControl();
    } catch (error) {
      console.error('Error starting remote control:', error);
      showNotification('Failed to start remote control', 'error');
    }
  }, [approveRemoteControl]);

  const requestRemoteControl = useCallback(() => {
    socketEmit('requestRemoteControl', { roomId });
    setIsRemoteControlRequested(true);
    showNotification('Remote control requested', 'info');
  }, [roomId]);

  const startScreenSharing = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: false });
      setScreenSharingStream(stream);
      setIsScreenSharing(true);
      if (peerRef.current && peerRef.current.connected) {
        stream.getTracks().forEach(track => {
          peerRef.current.addTrack(track, stream);
        });
      }
      return stream;
    } catch (error) {
      console.error('Error starting screen share:', error);
      showNotification('Failed to start screen sharing', 'error');
      throw error;
    }
  }, []);

  const sendRemoteControlInput = useCallback((inputData) => {
    if (peerRef.current && peerRef.current.connected) {
      peerRef.current.send(JSON.stringify({ type: 'remoteControl', data: inputData }));
    }
  }, []);

  const sendRemoteControlData = useCallback((data) => {
    if (peerRef.current && isControlling) {
      peerRef.current.send(JSON.stringify(data));
    }
  }, [isControlling]);  

  const startRemoteControlStream = useCallback(async () => {
    try {
      console.log('Starting remote control stream');
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { displaySurface: 'monitor', cursor: 'always' },
        audio: false
      });

      setScreenSharingStream(stream);
      setIsRemoteControlActive(true);

      if (peerRef.current && peerRef.current.connected) {
        stream.getTracks().forEach(track => {
          peerRef.current.addTrack(track, stream);
        });
      }

      // Get and send computer info
      const computerInfo = await getComputerInfo();
      if (computerInfo) {
        socketEmit('computerInfo', { roomId, computerInfo });
      }

      console.log('Remote control stream started successfully');
    } catch (error) {
      console.error('Error starting remote control stream:', error);
      showNotification('Failed to start remote control stream', 'error');
    }
  }, [roomId, getComputerInfo]);

  const handleRequestRemoteControl = useCallback(() => {
    console.log('Requesting remote control');
    if (!isRemoteControlRequested && !isRemoteControlActive) {
      requestRemoteControl();
      setIsRemoteControlRequested(true);
      showNotification('Remote control requested', 'info');
    }
  }, [requestRemoteControl, isRemoteControlRequested, isRemoteControlActive]);

  const handleIncomingStream = useCallback((stream) => {
    const videoTrack = stream.getVideoTracks()[0];
    if (videoTrack) {
      if (videoTrack.label.toLowerCase().includes('screen')) {
        console.log('Received screen sharing stream');
        setRemoteScreenStream(stream);
      } else {
        console.log('Received remote camera stream');
        setRemoteStream(stream);
      }
    }
  }, []);

  const handleDenyRemoteControl = useCallback(() => {
    socketEmit('denyRemoteControl', { roomId });
    setIsRemoteControlRequested(false);
    showNotification('Remote control request denied', 'info');
  }, [roomId]);

  const handleStopRemoteControl = useCallback(() => {
    socketEmit('stopRemoteControl', { roomId });
    setIsRemoteControlActive(false);
    setIsControlling(false);
    if (screenShareStream) {
      screenShareStream.getTracks().forEach(track => track.stop());
    }
    setScreenShareStream(null);
    showNotification('Remote control stopped', 'info');
  }, [roomId, screenShareStream]);

  const handleRemoteControlInput = useCallback((event) => {
    if (isControlling && remoteControlContainerRef.current) {
      const rect = remoteControlContainerRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;

      let inputData;

      switch (event.type) {
        case 'mousemove':
          inputData = { type: 'mousemove', x, y };
          break;
        case 'mousedown':
        case 'mouseup':
          inputData = { type: event.type, x, y, button: event.button };
          break;
        case 'wheel':
          inputData = { type: 'wheel', deltaX: event.deltaX, deltaY: event.deltaY };
          break;
        case 'keydown':
        case 'keyup':
          inputData = { type: event.type, key: event.key, keyCode: event.keyCode };
          break;
        default:
          return;
      }

      if (inputData) {
        socketEmit('remoteControlInput', { roomId, inputData });
      }
    }
  }, [isControlling, roomId]);

  const handleApproveRemoteControl = useCallback(async () => {
    if (isRemoteControlActive) {
      console.log('Remote control is already active');
      return;
    }
    try {
      console.log('Approving remote control');
      setIsRemoteControlActive(true);
      setIsControlling(currentUser.role === 'service_worker');
      approveRemoteControl();
      if (currentUser.role === 'user') {
        await startScreenSharing();
      }
    } catch (error) {
      console.error('Error approving remote control:', error);
      showNotification('Failed to approve remote control', 'error');
      setIsRemoteControlActive(false);
      setIsControlling(false);
    }
  }, [currentUser.role, approveRemoteControl, startScreenSharing]);

  const handleRemoteControlMouseMove = useCallback((event) => {
    if (isRemoteControlActive && remoteControlRef.current) {
      const rect = remoteControlRef.current.getBoundingClientRect();
      const x = (event.clientX - rect.left) / rect.width;
      const y = (event.clientY - rect.top) / rect.height;
      sendRemoteControlInput({ action: 'move', x, y });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlMouseDown = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'click', button: event.button, clickType: 'down' });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlMouseUp = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'click', button: event.button, clickType: 'up' });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyDown = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'keydown', key: event.key, keyCode: event.keyCode });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyUp = useCallback((event) => {
    if (isRemoteControlActive) {
      sendRemoteControlInput({ action: 'keyup', key: event.key, keyCode: event.keyCode });
    }
  }, [isRemoteControlActive, sendRemoteControlInput]);

  const handleRemoteControlKeyPress = useCallback((event) => {
    if (isControlling && peerRef.current) {
      const key = event.key;
      peerRef.current.send(JSON.stringify({ type: 'remoteControl', action: 'key', key }));
    }
  }, [isControlling]);

  //END REMOTE CONTROL

  //Peer Connection
  const createPeer = useCallback((isInitiator) => {
    console.log('Creating peer, initiator:', isInitiator);
    if (peerRef.current) {
      peerRef.current.destroy();
    }

    const peer = new Peer({
      initiator: isInitiator,
      trickle: false,
      stream: localStreamRef.current,
      config: {
        iceServers: [
          { urls: 'stun:stun.l.google.com:19302' },
          { urls: 'stun:global.stun.twilio.com:3478' }
        ]
      }
    });

    peer.on('signal', (data) => {
      console.log('Peer signaling');
      socketEmit('signal', { roomId, signal: data });
    });

    peer.on('stream', handleIncomingStream);


    peer.on('data', (data) => {
      const parsedData = JSON.parse(data.toString());
      if (parsedData.type === 'remoteControl') {
        handleRemoteControlData(parsedData.data);
      }
    });

    peer.on('connect', () => {
      console.log('Peer connected');
      setIsPeerConnected(true);
      setIsCallStarted(true);
      setConnectionStatus('Call in progress');
      dataChannelRef.current = peer;
    });

    peer.on('error', (err) => {
      console.error('Peer error:', err);
      setError(`Peer connection error: ${err.message}`);
      showNotification(`Peer connection error: ${err.message}`, 'error');
    });

    peer.on('close', () => {
      console.log('Peer connection closed');
      setIsPeerConnected(false);
      setIsCallStarted(false);
      setConnectionStatus('Call ended');
    });

    peerRef.current = peer;
    return peer;
  }, [roomId, handleIncomingStream, handleRemoteControlData]);

  const startCall = useCallback((isInitiator) => {
    if (!roomId || !currentUser) {
      console.error('Cannot start call: missing required parameters');
      return;
    }
    console.log('Starting call, initiator:', isInitiator);
    createPeer(isInitiator);
  }, [createPeer, roomId, currentUser]);

  const handleIncomingCall = useCallback((incomingSignal) => {
    console.log('Handling incoming call');
    if (localStreamRef.current) {
      const peer = createPeer(false);
      peer.signal(incomingSignal);
    } else {
      console.error('Local stream not available');
      setError('Local stream not available. Please check your camera and microphone permissions.');
      showNotification('Local stream not available. Please check your camera and microphone permissions.', 'error');
    }
  }, [createPeer]);

  const handleSignal = useCallback((data) => {
    console.log('Received signal');
    if (peerRef.current) {
      peerRef.current.signal(data.signal);
    } else {
      console.warn('Received signal but peer is not initialized');
      pendingCandidates.current.push(data.signal);
    }
  }, []);

  const handleUserJoined = useCallback((user) => {
    console.log('User joined:', user);
    setRemoteUser(user);
    setConnectionStatus('Remote user joined. Waiting for room to be ready...');
  }, []);

  const handleRoomReady = useCallback(({ initiator }) => {
    console.log('Room is ready for WebRTC connection, initiator:', initiator);
    setIsRoomReady(true);
    setConnectionStatus('Room is ready. Starting call...');
    const isInitiator = currentUser.role === 'service_worker';
    setIsInitiator(isInitiator);
    startCall(isInitiator);
  }, [currentUser.role, startCall]);

  const handleUserLeft = useCallback((userId) => {
    console.log('User left:', userId);
    if (peerRef.current) {
      peerRef.current.destroy();
    }
  }, []);

  return {
    handleIncomingCall,
    handleSignal,
    handleUserJoined,
    handleRoomReady,
    handleUserLeft,
    createPeer,
    handleIncomingStream,
    handleRemoteControlInput,
    handleRemoteControlMouseMove,
    handleRemoteControlMouseDown,
    handleRemoteControlMouseUp,
    handleRemoteControlKeyDown,
    handleRemoteControlKeyUp,
    handleRemoteControlKeyPress,
    handleRequestRemoteControl,
    handleDenyRemoteControl,
    handleStopRemoteControl,
    handleApproveRemoteControl,
    startRemoteControlStream,
    sendRemoteControlData,
    startRemoteControl,
    handleRemoteControl,
    handleRemoteControlClick,
    handleRemoteControlScroll,
    remoteControlStreamRef,
    hasJoinedRoom,
    isRemoteControlActive,
    isControlling,
    isRemoteControlRequested,
    remoteControlContainerRef,
    remoteControlRef,
    pendingCandidates,
    dataChannelRef,
    peerRef,
    localStreamRef,
    isInitiator,
    setIsInitiator,
    isPeerConnected,
    setIsPeerConnected,
    isCallStarted,
    setIsCallStarted,
    connectionStatus,
    setConnectionStatus,
    error,
    setError,
    showNotification, 
    localStreamRef,
    remoteStreamRef,
    isScreenSharing,
    setIsScreenSharing
  };
};

export default useWebRTC;
