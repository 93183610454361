import logger from '../utils/logger';

export const initializeLogging = () => {
  const originalConsole = {
    log: console.log.bind(console),
    info: console.info.bind(console),
    error: console.error.bind(console),
    warn: console.warn.bind(console),
    debug: console.debug.bind(console)
  };

  let isLogging = false;

  const wrapConsole = (method, loggerMethod) => {
    return (...args) => {
      if (!isLogging) {
        try {
          isLogging = true;
          const firstArg = args[0];
          if (typeof firstArg === 'string' && !firstArg.includes('"app_name":"it-guy-frontend"')) {
            loggerMethod(...args);
          }
          originalConsole[method](...args);
        } finally {
          isLogging = false;
        }
      } else {
        originalConsole[method](...args);
      }
    };
  };

  console.log = wrapConsole('log', logger.info);
  console.info = wrapConsole('info', logger.info);
  console.error = wrapConsole('error', logger.error);
  console.warn = wrapConsole('warn', logger.warn);
  console.debug = wrapConsole('debug', logger.debug);

  window.addEventListener('error', (event) => {
    if (!isLogging) {
      logger.error('Unhandled error:', {
        message: event.error?.message,
        stack: event.error?.stack
      });
    }
  });

  window.addEventListener('unhandledrejection', (event) => {
    if (!isLogging) {
      logger.error('Unhandled promise rejection:', {
        reason: event.reason
      });
    }
  });
}; 