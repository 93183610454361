// hooks/useExpirationTime.js
import { useState, useEffect, useCallback } from 'react';
import { tokenManager } from '../services/TokenManager';

export const useExpirationTime = () => {
  const [remainingTime, setRemainingTime] = useState(() => tokenManager.getTimeUntilExpiration());

  const updateTime = useCallback(() => {
    const timeLeft = tokenManager.getTimeUntilExpiration();
    if (timeLeft <= 0) {
      setRemainingTime(0);
      tokenManager.handleTokenExpiration();
      return false;
    }
    setRemainingTime(timeLeft);
    return true;
  }, []);

  useEffect(() => {
    // Initial update
    updateTime();

    // Set up interval
    const interval = setInterval(() => {
      const shouldContinue = updateTime();
      if (!shouldContinue) {
        clearInterval(interval);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, [updateTime]);

  return remainingTime;
};