// utils/encryption.js
import CryptoJS from 'crypto-js';

export const createEncryptionKey = () => {
  return crypto.getRandomValues(new Uint8Array(32));
};

export const encrypt = (data, key) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

export const decrypt = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  return bytes.toString(CryptoJS.enc.Utf8);
};