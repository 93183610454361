// FrontEnd/src/services/api.js
import axios from 'axios';
import { tokenManager } from '../services/TokenManager';
import io from 'socket.io-client';

const API_CONSTANTS = {
  MAX_RETRIES: 3,
  RETRY_DELAY: 1000,
  ERROR_THRESHOLD: 5,
  REQUEST_TIMEOUT: 5000
};

// Create axios instance
const api = axios.create({
  baseURL: 'https://it-guy.seo-top.dev',
  timeout: API_CONSTANTS.REQUEST_TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  },
  withCredentials: true
});

let failureCount = 0;
let refreshPromise = null;
let socket = null;

export const socketEmit = (event, data) => {
  if (socket) {
    socket.emit(event, data);
  } else {
    console.error('Socket not initialized');
  }
};

// Request interceptor
api.interceptors.request.use(
  (config) => {
    let token = tokenManager.getToken();
    
    if (!token) {
      token = localStorage.getItem('auth_token');
    }
    
    if (!token) {
      token = sessionStorage.getItem('token');
    }

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      if (!tokenManager.getToken()) {
        tokenManager.setToken(token);
      }
    }
    
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 502) {
      failureCount++;
      if (failureCount >= API_CONSTANTS.ERROR_THRESHOLD) {
        setTimeout(() => { failureCount = 0; }, API_CONSTANTS.RETRY_DELAY * 2);
        throw new Error('Service temporarily unavailable');
      }
      await new Promise(resolve => setTimeout(resolve, API_CONSTANTS.RETRY_DELAY));
      return api(originalRequest);
    }

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (!refreshPromise) {
        refreshPromise = tokenManager.refreshTokenRequest()
          .then(success => {
            if (!success) throw new Error('Token refresh failed');
            return tokenManager.getToken();
          })
          .finally(() => {
            refreshPromise = null;
          });
      }

      try {
        const newToken = await refreshPromise;
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        tokenManager.clearSilent();
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

// API endpoints
const endpoints = {
  auth: {
    getCurrentUser: async () => {
      try {
        const response = await api.get('/auth/current_user');
        return response.data;
      } catch (error) {
        if (error.response?.status === 404) {
          window.location.href = '/login';
        }
        throw error;
      }
    },
    login: (credentials) => api.post('/auth/login', credentials),
    logout: () => api.post('/auth/logout'),
    refresh: (refreshToken) => api.post('/auth/refresh', { refreshToken }),
    googleAuth: () => api.get('/auth/google'),
    googleCallback: (data) => api.post('/auth/google/callback', data),
    verifyToken: async (token) => {
      try {
        const response = await api.post('/auth/verify', { token });
        return response.data;
      } catch (error) {
        if (error.response?.status === 404) {
          tokenManager.clearToken();
        }
        throw error;
      }
    }
  },
  dashboard: {
    getData: () => api.get('/dashboard/data'),
    getStats: () => api.get('/dashboard/stats'),
    getEarnings: () => api.get('/dashboard/earnings')
  },
  tickets: {
    getAll: (params) => api.get('/tickets', { params }),
    getById: (id) => api.get(`/tickets/${id}`),
    create: (data) => api.post('/tickets', data),
    update: (id, data) => api.put(`/tickets/${id}`, data),
    patch: (id, data) => api.patch(`/tickets/${id}`, data),
    assign: (id) => api.post(`/tickets/${id}/assign`),
    addComment: (id, data) => api.post(`/tickets/${id}/comments`, data),
    escalate: (id, data) => api.post(`/tickets/${id}/escalate`, data),
    delete: (id) => api.delete(`/tickets/${id}`),
    getComments: (id) => api.get(`/tickets/${id}/comments`)
  },
  serviceWorkers: {
    getEarnings: () => api.get('/service-workers/earnings'),
    getStats: () => api.get('/service-workers/stats')
  },
  notifications: {
    getAll: () => api.get('/notifications'),
    markAsRead: (id) => api.put(`/notifications/${id}/read`),
    subscribe: (data) => api.post('/notifications/subscribe', data),
    send: (data) => api.post('/notifications/send', data)
  },
  ai: {
    analyze: (data) => api.post('/ai/analyze', data),
    getStatus: () => api.get('/ai/status'),
    train: (data) => api.post('/ai/train', data)
  },
  webSocket: {
    getInfo: () => api.get('/ws-info'),
    reportError: (error) => api.post('/ws-error', error),
    getActiveSessions: () => api.get('/remote-session/active')
  },
  remoteSession: {
    initiate: (data) => socketEmit('initiateRemoteSession', data),
    join: (roomId) => socketEmit('joinRemoteSession', { roomId }),
    leave: (roomId) => socketEmit('leaveRemoteSession', { roomId }),
    sendMessage: (data) => socketEmit('sendMessage', data),
    getStatus: (sessionId) => api.get(`/remote/session/${sessionId}/status`),
    getLogs: (executionId) => api.get(`/remote/logs/${executionId}`)
  }
};

// Attach endpoints to api object
Object.assign(api, endpoints);

export default api;
