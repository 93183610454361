import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import api from '../services/api';

export default function ReportingDashboard() {
  const [ticketStats, setTicketStats] = useState(null);
  const [categoryStats, setCategoryStats] = useState([]);

  useEffect(() => {
    fetchTicketStats();
    fetchCategoryStats();
  }, []);

  const fetchTicketStats = async () => {
    try {
      const response = await api.get('/reports/ticket-stats');
      setTicketStats(response.data);
    } catch (error) {
      console.error('Error fetching ticket stats:', error);
    }
  };

  const fetchCategoryStats = async () => {
    try {
      const response = await api.get('/reports/tickets-by-category');
      setCategoryStats(response.data);
    } catch (error) {
      console.error('Error fetching category stats:', error);
    }
  };

  return (
    <Paper style={{ padding: 16 }}>
      <Typography variant="h6">Reporting Dashboard</Typography>
      {ticketStats && (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography>Total Tickets: {ticketStats.totalTickets}</Typography>
            <Typography>Open Tickets: {ticketStats.openTickets}</Typography>
            <Typography>Resolved Tickets: {ticketStats.resolvedTickets}</Typography>
            <Typography>Average Resolution Time: {ticketStats.averageResolutionTime.toFixed(2)} hours</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={categoryStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="_id" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      )}
    </Paper>
  );
}