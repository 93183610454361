import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import {
  Typography, Grid, Card, CardContent, Button, TextField,
  InputAdornment, Chip, CircularProgress, Fade, Grow, Snackbar,
  useMediaQuery, IconButton, Box, Divider, Paper, Tab, Tabs,
  Drawer, Dialog, DialogTitle, DialogContent, DialogActions,
  List, ListItem, ListItemText, ListItemSecondaryAction
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Computer, Print, Wifi, Email, Security, Backup, AccountCircle, PhoneAndroid,
  VideoCall, BugReport, Search, Whatshot, Add, ExpandMore, ArrowForward, Clear,
  Send, Delete, History, CheckCircle, Edit, AttachMoney, Description
} from '@material-ui/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import api from '../services/api';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default,
  },
  header: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  tabsContainer: {
    marginBottom: theme.spacing(4),
  },
  searchBar: {
    marginBottom: theme.spacing(4),
  },
  cardGrid: {
    marginTop: theme.spacing(4),
  },
  card: {
    height: 300,
    display: 'flex',
    flexDirection: 'column',
    transition: theme.transitions.create(['box-shadow'], {
      duration: theme.transitions.duration.standard,
    }),
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
  },
  cardContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
    }),
  },
  cardContentExpanded: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
    }),
  },
  icon: {
    fontSize: 64,
    color: theme.palette.primary.main,
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
    }),
  },
  iconExpanded: {
    fontSize: 40,
    marginRight: theme.spacing(2),
  },
  categoryTitle: {
    fontWeight: 600,
    textAlign: 'center',
    transition: theme.transitions.create('all', {
      duration: theme.transitions.duration.standard,
    }),
  },
  categoryTitleExpanded: {
    fontSize: '1.2rem',
    marginBottom: theme.spacing(2),
  },
  expandButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandButtonExpanded: {
    transform: 'rotate(180deg)',
  },
  subcategoriesContainer: {
    marginTop: theme.spacing(2),
    overflowY: 'auto',
    width: '100%',
    maxHeight: 180,
  },
  subcategoryButton: {
    justifyContent: 'flex-start',
    textTransform: 'none',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1, 2),
    borderRadius: theme.shape.borderRadius,
    transition: theme.transitions.create('background-color', {
      duration: theme.transitions.duration.shortest,
    }),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  chipContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    margin: theme.spacing(2, 0),
  },
  recentChip: {
    margin: theme.spacing(0.5),
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  popularChip: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    marginLeft: theme.spacing(1),
  },
  customIssueButton: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  customIssueInput: {
    marginTop: theme.spacing(2),
    width: '100%',
  },
  customIssueSubmit: {
    marginTop: theme.spacing(1),
    width: '100%',
  },
  chatContainer: {
    height: '70vh',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
    position: 'relative',
  },
  chatHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1,
  },
  messagesContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
  },
  message: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    maxWidth: '70%',
    wordBreak: 'break-word',
  },
  userMessage: {
    backgroundColor: theme.palette.primary.light,
    alignSelf: 'flex-end',
    color: theme.palette.primary.contrastText,
  },
  aiMessage: {
    backgroundColor: theme.palette.secondary.light,
    alignSelf: 'flex-start',
    color: theme.palette.secondary.contrastText,
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  input: {
    flexGrow: 1,
    marginRight: theme.spacing(2),
  },
  sendButton: {
    minWidth: 100,
  },
  successIcon: {
    fontSize: 48,
    color: theme.palette.success.main,
    marginBottom: theme.spacing(2),
  },
  ticketPreview: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  priceTag: {
    display: 'inline-flex',
    alignItems: 'center',
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.shape.borderRadius,
    marginLeft: theme.spacing(1),
  },
  chatActions: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
  },
  chatHistoryItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    marginBottom: theme.spacing(1),
  },
  historyDrawerContent: {
    width: 400,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  chatHistoryItem: {
    cursor: 'pointer',
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      boxShadow: theme.shadows[1],
    },
  },
  priceChip: {
    marginBottom: theme.spacing(1),
  },
  chatHistoryHeader: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const issueCategories = [
  { title: 'Computer Issues', icon: Computer, subcategories: ['Slow performance', 'Blue screen', 'Software installation', 'Hardware problem', 'Driver issues'] },
  { title: 'Printer Problems', icon: Print, subcategories: ['Not printing', 'Paper jam', 'Print quality', 'Wireless printing', 'Ink/Toner low'] },
  { title: 'Network Issues', icon: Wifi, subcategories: ['No internet connection', 'Slow internet', 'Wi-Fi problems', 'VPN issues', 'Network drive access'] },
  { title: 'Email Problems', icon: Email, subcategories: ['Can\'t send/receive', 'Spam filter', 'Account setup', 'Email organization', 'Attachment issues'] },
  { title: 'Security Concerns', icon: Security, subcategories: ['Virus/Malware', 'Password reset', 'Phishing', 'Data encryption', 'Access control'] },
  { title: 'Data Backup', icon: Backup, subcategories: ['Backup setup', 'Data recovery', 'Cloud storage', 'Sync issues', 'Backup verification'] },
  { title: 'Account Management', icon: AccountCircle, subcategories: ['New account', 'Password change', 'Account permissions', 'Profile update', 'Account deletion'] },
  { title: 'Mobile Device', icon: PhoneAndroid, subcategories: ['Email setup', 'App issues', 'Device sync', 'Lost/Stolen device', 'Software update'] },
  { title: 'Video Conferencing', icon: VideoCall, subcategories: ['Audio problems', 'Video quality', 'Screen sharing', 'Connection issues', 'Software setup'] },
  { title: 'Other Issues', icon: BugReport, subcategories: [] },
];

export default function IssueSelectionPage() {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [expandedCard, setExpandedCard] = useState(null);
  const [customIssueIndex, setCustomIssueIndex] = useState(null);
  const [customIssue, setCustomIssue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [recentIssues, setRecentIssues] = useState([]);
  const [popularIssues, setPopularIssues] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messagesEndRef = useRef(null);
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const [ticketDetails, setTicketDetails] = useState(null);
  const [isEditingTicket, setIsEditingTicket] = useState(false);
  const [chatHistories, setChatHistories] = useState([]);
  const [priceBreakdown, setPriceBreakdown] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isHistoryDrawerOpen, setIsHistoryDrawerOpen] = useState(false);
  const [previousChatHistory, setPreviousChatHistory] = useState([]);
  const messageListRef = useRef(null);
  const inputRef = useRef(null);
  const location = useLocation();

  const loadChat = useCallback(async (chatId) => {
    try {
      setIsLoading(true);
      console.log('Loading chat:', chatId); // Debug log
      
      const response = await api.get(`/api/chatbot/chat/${chatId}`);
      console.log('Chat load response:', response.data); // Debug log
      
      if (response.data) {
        setActiveChat(response.data);
        if (Array.isArray(response.data.messages)) {
          setMessages(response.data.messages);
        }
        if (response.data.ticket) {
          setTicketDetails(response.data.ticket);
        }
        setIsHistoryDrawerOpen(false); // Close drawer after successful load
        toast.success('Chat loaded successfully');
      } else {
        throw new Error('Invalid chat data received');
      }
    } catch (error) {
      console.error('Error loading chat:', error);
      toast.error('Failed to load chat history: ' + (error.message || 'Unknown error'));
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Starting data fetch...');
        setIsLoading(true);
        setError(null);

        let hasAnyData = false;

        // Fetch recent issues
        try {
          const recentResponse = await api.get('/api/chatbot/recent-issues');
          if (Array.isArray(recentResponse.data)) {
            setRecentIssues(recentResponse.data);
            hasAnyData = hasAnyData || recentResponse.data.length > 0;
          }
        } catch (error) {
          console.warn('Failed to fetch recent issues:', error);
        }

        // Fetch popular issues
        try {
          const popularResponse = await api.get('/api/chatbot/popular-issues');
          const popularData = popularResponse.data || {};
          setPopularIssues(popularData);
          hasAnyData = hasAnyData || Object.keys(popularData).length > 0;
        } catch (error) {
          console.warn('Failed to fetch popular issues:', error);
        }

        // Fetch chat history
        try {
          const historiesResponse = await api.get('/api/chatbot/chat-histories');
          console.log('Chat histories response:', historiesResponse); // Debug log

          if (Array.isArray(historiesResponse.data)) {
            // Ensure we're getting proper data structure
            const processedHistories = historiesResponse.data.map(chat => ({
              id: chat._id || chat.id,
              title: chat.title || `Chat from ${new Date(chat.createdAt).toLocaleDateString()}`,
              createdAt: chat.createdAt,
              ticket: chat.ticket,
              messages: chat.messages || [],
              status: chat.ticket?.status || 'No ticket'
            }));

            console.log('Processed histories:', processedHistories); // Debug log
            setChatHistories(processedHistories);
            hasAnyData = hasAnyData || processedHistories.length > 0;
          }
        } catch (error) {
          console.error('Failed to fetch chat histories:', error);
        }

        // Fetch chat histories
        try {
          const historiesResponse = await api.get('/api/chatbot/chat-histories');
          if (Array.isArray(historiesResponse.data)) {
            setChatHistories(historiesResponse.data);
            hasAnyData = hasAnyData || historiesResponse.data.length > 0;
          }
        } catch (error) {
          console.warn('Failed to fetch chat histories:', error);
        }

        // Always proceed even if some data failed to load
        setIsLoading(false);

        // Only set error if we have no data at all
        if (!hasAnyData) {
          setError('Unable to load data. Some features may be limited.');
        }

      } catch (err) {
        console.error('Error in fetchData:', err);
        // Set error but don't prevent rendering
        setError(`Some data failed to load: ${err.message}`);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [location, loadChat]);


  useEffect(() => {
    const checkLoadingState = () => {
      const hasData = recentIssues.length > 0 || Object.keys(popularIssues).length > 0 ||
        messages.length > 0 || chatHistories.length > 0;

      if (!hasData && !error && !isLoading) {
        console.log('No data received but loading is false. Setting error.');
        setError('No data received from server');
      }

      console.log('Loading state check:', {
        hasData,
        isLoading,
        error,
        dataState: {
          recentIssues: recentIssues.length,
          popularIssues: Object.keys(popularIssues).length,
          messages: messages.length,
          chatHistories: chatHistories.length
        }
      });
    };

    checkLoadingState();
  }, [isLoading, error, recentIssues, popularIssues, messages, chatHistories]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  const filteredCategories = useMemo(() => {
    return issueCategories.filter(category =>
      category.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      category.subcategories.some(sub => sub.toLowerCase().includes(searchTerm.toLowerCase()))
    );
  }, [searchTerm]);

  const handleCardClick = (index) => {
    setExpandedCard(expandedCard === index ? null : index);
    setCustomIssueIndex(null);
  };

  const handleSubcategoryClick = (category, subcategory) => {
    navigate('/create-ticket', { state: { category, subcategory } });
  };

  const handleCustomIssueClick = (index) => {
    setCustomIssueIndex(index);
    setCustomIssue('');
  };

  const handleCustomIssueSubmit = (category) => {
    if (customIssue.trim()) {
      navigate('/create-ticket', { state: { category, subcategory: customIssue } });
    }
    setCustomIssueIndex(null);
    setCustomIssue('');
  };

  const handleClearChat = async () => {
    try {
      await api.post('/api/chatbot/clear-chat');
      setMessages([]);
      toast.success('Chat history cleared successfully');
    } catch (error) {
      console.error('Error clearing chat history:', error);
      setError(`Failed to clear chat history: ${error.message}`);
    }
  };

  const handleSendMessage = async () => {
    if (!input.trim()) return;

    const newMessage = { text: input, sender: 'user' };
    setMessages(prev => [...prev, newMessage]);
    setInput('');
    setIsProcessing(true);

    try {
      const response = await api.post('/api/chatbot/chat', {
        message: input,
        chatId: activeChat?.id,
        ticketId: ticketDetails?.id
      });

      setMessages(prev => [...prev, {
        text: response.data.message,
        sender: 'ai',
        ticketDetails: response.data.ticketDetails
      }]);

      if (response.data.ticketDetails) {
        setTicketDetails(response.data.ticketDetails);
        setPriceBreakdown(response.data.ticketDetails.pricingBreakdown);
      }

      if (response.data.priceUpdate) {
        setPriceBreakdown(response.data.priceUpdate);
      }

    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateTicket = async (updates) => {
    try {
      const response = await api.patch(`/api/tickets/${ticketDetails.id}`, updates);
      setTicketDetails(response.data);
      setIsEditingTicket(false);
      toast.success('Ticket updated successfully');
    } catch (error) {
      console.error('Error updating ticket:', error);
      toast.error('Failed to update ticket');
    }
  };

  const handleCreateAnotherTicket = () => {
    setIsSuccessDialogOpen(false);
    setMessages([]);
    setInput('');
  };

  const handleGoToDashboard = () => {
    navigate('/dashboard', { state: { previousChatHistory: messages } });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    console.log('Current state:', {
      isLoading,
      error,
      recentIssues,
      popularIssues,
      messages,
      chatHistories
    });
  }, [isLoading, error, recentIssues, popularIssues, messages, chatHistories]);

  if (isLoading && !recentIssues.length && !Object.keys(popularIssues).length &&
    !messages.length && !chatHistories.length) {
    return (
      <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
        <Typography variant="body2" color="textSecondary" style={{ marginTop: '1rem' }}>
          Loading data...
        </Typography>
      </Box>
    );
  }

  if (error) {
    toast.error(error, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
    });
  }

  const hasNoData = !recentIssues.length && !Object.keys(popularIssues).length &&
    !messages.length && !chatHistories.length;

  if (hasNoData) {
    return (
      <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        <Alert severity="error" style={{ marginBottom: theme.spacing(2) }}>
          {error || 'No data available'}
        </Alert>
        <Button
          onClick={() => window.location.reload()}
          color="primary"
          variant="contained"
          style={{ marginTop: theme.spacing(1) }}
        >
          Retry
        </Button>
      </Box>
    );
  }

  if (error) {
    return (
      <Box className={classes.root} display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
        <Alert severity="error" style={{ marginBottom: theme.spacing(2) }}>{error}</Alert>
        <Button onClick={() => window.location.reload()} color="primary" variant="contained">
          Retry
        </Button>
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <Typography variant="h4" component="h1" className={classes.header}>
        IT Support Assistant
      </Typography>

      <Paper className={classes.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="AI Chat" />
          <Tab label="Manual Selection" />
        </Tabs>
      </Paper>

      {tabValue === 0 ? (
        <Paper className={classes.chatContainer}>
          <Box className={classes.chatHeader}>
            <Typography variant="h6">Chat with AI Assistant</Typography>
            <Box className={classes.chatActions}>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<Delete />}
                onClick={handleClearChat}
                size="small"
              >
                Clear Chat History
              </Button>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<History />}
                onClick={() => setIsHistoryDrawerOpen(true)}
                size="small"
              >
                Chat History
              </Button>
            </Box>
          </Box>

          <Box className={classes.messagesContainer} ref={messageListRef}>
            {messages.map((message, index) => (
              <Box
                key={index}
                className={clsx(classes.message, {
                  [classes.userMessage]: message.sender === 'user',
                  [classes.aiMessage]: message.sender === 'ai',
                })}
              >
                <Typography>{message.text}</Typography>
                {message.ticketDetails && (
                  <Card className={classes.ticketPreview}>
                    <CardContent>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography variant="subtitle1">
                          Ticket #{message.ticketDetails.ticketId}
                        </Typography>
                        <Typography className={classes.priceTag}>
                          <AttachMoney fontSize="small" />
                          ${message.ticketDetails.pricing.finalPrice}
                        </Typography>
                      </Box>
                      <Box mt={1}>
                        <Button
                          size="small"
                          startIcon={<Edit />}
                          onClick={() => setIsEditingTicket(true)}
                        >
                          Edit Ticket
                        </Button>
                        <Button
                          size="small"
                          startIcon={<Description />}
                          onClick={() => setTicketDetails(message.ticketDetails)}
                        >
                          View Details
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </Box>
            ))}
            {isProcessing && (
              <Box display="flex" justifyContent="center" my={2}>
                <CircularProgress size={24} />
              </Box>
            )}
            <div ref={messagesEndRef} />
          </Box>

          <Box className={classes.inputContainer}>
            <TextField
              className={classes.input}
              variant="outlined"
              placeholder="Type your message here..."
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
              disabled={isProcessing}
              inputRef={inputRef}
            />
            <Button
              variant="contained"
              color="primary"
              endIcon={<Send />}
              onClick={handleSendMessage}
              className={classes.sendButton}
              disabled={isProcessing}
            >
              Send
            </Button>
          </Box>
        </Paper>
      ) : (
        <>
          <Autocomplete
            freeSolo
            options={issueCategories.flatMap(cat => [cat.title, ...cat.subcategories])}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.searchBar}
                label="Search for issues"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
            )}
            onInputChange={(_, newValue) => setSearchTerm(newValue)}
          />
          {recentIssues.length > 0 && (
            <Box className={classes.chipContainer}>
              <Typography variant="subtitle1" style={{ marginRight: theme.spacing(2) }}>
                Recent Issues:
              </Typography>
              {recentIssues.map((issue, index) => (
                <Chip
                  key={index}
                  label={issue.title || `${issue.category} - ${issue.subcategory}`}
                  onClick={() => handleSubcategoryClick(issue.category, issue.subcategory)}
                  clickable
                  className={classes.recentChip}
                />
              ))}
            </Box>
          )}
          <Grid container spacing={3} className={classes.cardGrid}>
            {filteredCategories.map((category, index) => (
              <Grow
                in={true}
                style={{ transformOrigin: '0 0 0' }}
                {...{ timeout: 1000 + index * 100 }}
                key={index}
              >
                <Grid item xs={12} sm={6} md={4}>
                  <Card className={classes.card} elevation={expandedCard === index ? 8 : 1}>
                    <CardContent
                      className={clsx(classes.cardContent, {
                        [classes.cardContentExpanded]: expandedCard === index,
                      })}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        flexDirection={expandedCard === index ? 'row' : 'column'}
                        mb={expandedCard === index ? 2 : 0}
                        width="100%"
                      >
                        <div className={classes.iconContainer}>
                          <category.icon className={clsx(classes.icon, {
                            [classes.iconExpanded]: expandedCard === index,
                          })} />
                        </div>
                        <Typography
                          variant="h6"
                          component="h2"
                          className={clsx(classes.categoryTitle, {
                            [classes.categoryTitleExpanded]: expandedCard === index,
                          })}
                        >
                          {category.title}
                        </Typography>
                      </Box>
                      <Fade in={expandedCard === index}>
                        <div className={classes.subcategoriesContainer}>
                          {expandedCard === index && (
                            <>
                              <Divider style={{ margin: theme.spacing(2, 0) }} />
                              {category.subcategories.map((subcategory, subIndex) => (
                                <Button
                                  key={subIndex}
                                  onClick={() => handleSubcategoryClick(category.title, subcategory)}
                                  className={classes.subcategoryButton}
                                  fullWidth
                                  endIcon={<ArrowForward />}
                                >
                                  <Box display="flex" alignItems="center" width="100%">
                                    <Typography variant="body2" style={{ flexGrow: 1, textAlign: 'left' }}>
                                      {subcategory}
                                    </Typography>
                                    {popularIssues[subcategory] && (
                                      <Chip
                                        size="small"
                                        label={`${popularIssues[subcategory]}+`}
                                        className={classes.popularChip}
                                        icon={<Whatshot style={{ fontSize: 12 }} />}
                                      />
                                    )}
                                  </Box>
                                </Button>
                              ))}
                              {customIssueIndex === index ? (
                                <Box mt={2}>
                                  <TextField
                                    className={classes.customIssueInput}
                                    variant="outlined"
                                    size="small"
                                    value={customIssue}
                                    onChange={(e) => setCustomIssue(e.target.value)}
                                    placeholder="Describe your issue"
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <IconButton
                                            aria-label="clear custom issue"
                                            onClick={() => setCustomIssue('')}
                                            edge="end"
                                          >
                                            <Clear />
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                  <Button
                                    className={classes.customIssueSubmit}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleCustomIssueSubmit(category.title)}
                                    disabled={!customIssue.trim()}
                                    fullWidth
                                  >
                                    Submit Custom Issue
                                  </Button>
                                </Box>
                              ) : (
                                <Button
                                  className={classes.customIssueButton}
                                  onClick={() => handleCustomIssueClick(index)}
                                  variant="outlined"
                                  color="primary"
                                  startIcon={<Add />}
                                  fullWidth
                                >
                                  Have another issue?
                                </Button>
                              )}
                            </>
                          )}
                        </div>
                      </Fade>
                    </CardContent>
                    <IconButton
                      className={clsx(classes.expandButton, {
                        [classes.expandButtonExpanded]: expandedCard === index,
                      })}
                      onClick={() => handleCardClick(index)}
                      aria-expanded={expandedCard === index}
                      aria-label="show more"
                    >
                      <ExpandMore />
                    </IconButton>
                  </Card>
                </Grid>
              </Grow>
            ))}
          </Grid>
        </>
      )}
      <Dialog open={isSuccessDialogOpen} onClose={() => setIsSuccessDialogOpen(false)}>
        <DialogTitle>Ticket Created Successfully</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            <CheckCircle className={classes.successIcon} />
            <Typography variant="body1" gutterBottom>
              Your support ticket has been created. What would you like to do next?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateAnotherTicket} color="primary">
            Create Another Ticket
          </Button>
          <Button onClick={handleGoToDashboard} color="primary" variant="contained">
            Go to Dashboard
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Drawer
        anchor="right"
        open={isHistoryDrawerOpen}
        onClose={() => setIsHistoryDrawerOpen(false)}
      >
        <Box className={classes.historyDrawerContent}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6">Chat History</Typography>
            <IconButton onClick={() => setIsHistoryDrawerOpen(false)}>
              <Clear />
            </IconButton>
          </Box>

          {chatHistories.length > 0 ? (
            <List>
              {chatHistories.map((chat) => (
                <ListItem
                  key={chat.id}
                  button
                  className={classes.chatHistoryItem}
                  onClick={() => loadChat(chat.id)}
                >
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        {chat.title || `Chat from ${new Date(chat.createdAt).toLocaleDateString()}`}
                      </Typography>
                    }
                    secondary={
                      <Typography variant="body2" color="textSecondary">
                        {chat.ticket ? (
                          <>
                            Ticket #{chat.ticket.id} - {chat.ticket.status}
                            {chat.ticket.title && ` - ${chat.ticket.title}`}
                          </>
                        ) : (
                          'No ticket created'
                        )}
                      </Typography>
                    }
                  />
                  {chat.ticket && (
                    <ListItemSecondaryAction>
                      <Chip
                        size="small"
                        label={`$${chat.ticket.suggestedPrice}`}
                        color="primary"
                        className={classes.priceChip}
                      />
                      <Typography variant="caption" display="block" align="right" style={{ marginTop: 4 }}>
                        {new Date(chat.createdAt).toLocaleDateString()}
                      </Typography>
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              ))}
            </List>
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" p={3}>
              <Typography color="textSecondary" align="center" gutterBottom>
                No chat history available
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
            </Box>
          )}
        </Box>
      </Drawer>
      <Dialog
        open={isEditingTicket}
        onClose={() => setIsEditingTicket(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Ticket</DialogTitle>
        <DialogContent>
          {ticketDetails && (
            <Box>
              <TextField
                fullWidth
                label="Title"
                value={ticketDetails.title}
                onChange={(e) => setTicketDetails({
                  ...ticketDetails,
                  title: e.target.value
                })}
                margin="normal"
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Description"
                value={ticketDetails.description}
                onChange={(e) => setTicketDetails({
                  ...ticketDetails,
                  description: e.target.value
                })}
                margin="normal"
              />
              {priceBreakdown && (
                <Box className={classes.priceBreakdown}>
                  <Typography variant="subtitle1">Price Breakdown:</Typography>
                  <Typography>Base Price: ${priceBreakdown.basePrice}</Typography>
                  <Typography>
                    Complexity Factor: {priceBreakdown.complexityMultiplier}x
                  </Typography>
                  <Typography>
                    Priority Multiplier: {priceBreakdown.priorityMultiplier}x
                  </Typography>
                  <Typography variant="h6">
                    Final Price: ${ticketDetails.suggestedPrice}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsEditingTicket(false)}>Cancel</Button>
          <Button
            onClick={() => handleUpdateTicket(ticketDetails)}
            color="primary"
            variant="contained"
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}