// components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { tokenManager } from '../services/TokenManager';
import LoadingSpinner from '../components/common/LoadingSpinner';
import logger  from '../utils/logger';

export default function ProtectedRoute({ children, allowedRoles = [] }) {
  const location = useLocation();
  const { isAuthenticated, authLoading, currentUser } = useAuth();

  logger.debug('ProtectedRoute state:', {
    isAuthenticated,
    authLoading,
    currentUser,
    pathname: location.pathname,
    allowedRoles,
    hasValidToken: tokenManager.isAuthenticated()
  });

  if (authLoading) {
    logger.debug('ProtectedRoute - Loading auth state');
    return <LoadingSpinner message="Checking authentication..." />;
  }

  if (!isAuthenticated) {
    logger.debug('ProtectedRoute - Not authenticated, redirecting to login');
    sessionStorage.setItem('redirectUrl', location.pathname);
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!tokenManager.isAuthenticated()) {
    logger.debug('ProtectedRoute - Invalid token, clearing and redirecting');
    tokenManager.clearSilent();
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(currentUser?.role)) {
    logger.debug('ProtectedRoute - Unauthorized role:', currentUser?.role);
    return <Navigate to="/unauthorized" replace />;
  }

  return children;
}