// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ControlsPanel_controlsPanel__nlgBQ {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--background-paper);
  border-top: 1px solid var(--border-color);
}

.ControlsPanel_controlGroup__J-xZ9 {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 16px;
}

@media (max-width: 600px) {
  .ControlsPanel_controlsPanel__nlgBQ {
    padding: 8px;
  }
  
  .ControlsPanel_controlGroup__J-xZ9 {
    margin: 0 8px;
  }
} `, "",{"version":3,"sources":["webpack://./src/components/RemoteSession/ControlsPanel/ControlsPanel.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,yCAAyC;EACzC,yCAAyC;AAC3C;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,cAAc;AAChB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,aAAa;EACf;AACF","sourcesContent":[".controlsPanel {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 16px;\n  background-color: var(--background-paper);\n  border-top: 1px solid var(--border-color);\n}\n\n.controlGroup {\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin: 0 16px;\n}\n\n@media (max-width: 600px) {\n  .controlsPanel {\n    padding: 8px;\n  }\n  \n  .controlGroup {\n    margin: 0 8px;\n  }\n} "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"controlsPanel": `ControlsPanel_controlsPanel__nlgBQ`,
	"controlGroup": `ControlsPanel_controlGroup__J-xZ9`
};
export default ___CSS_LOADER_EXPORT___;
