import React from 'react';
import { Box, ClickAwayListener, Paper } from '@mui/material';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';

const EmojiPicker = ({ onEmojiSelect, onClose }) => {
  return (
    <ClickAwayListener onClickAway={onClose}>
      <Paper
        sx={{
          position: 'absolute',
          bottom: '100%',
          right: 0,
          marginBottom: '8px',
          zIndex: 1000
        }}
      >
        <Picker
          data={data}
          onEmojiSelect={(emoji) => onEmojiSelect(emoji.native)}
          theme="light"
          set="apple"
        />
      </Paper>
    </ClickAwayListener>
  );
};

export default EmojiPicker; 