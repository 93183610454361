// services/RemoteControlService.js
import { getIceServers } from '../utils/iceServers';
import { encrypt, decrypt } from '../utils/encryption';

export class RemoteControlService {
    constructor(dispatch) {
        this.dispatch = dispatch;
        this.peerConnection = null;
        this.dataChannel = null;
        this.screenStream = null;
        this.encryptionKey = null;
    }

    async initialize() {
        try {
            this.dispatch({ type: 'INITIALIZE_START' });

            // Initialize WebRTC connection
            this.peerConnection = new RTCPeerConnection({
                iceServers: await getIceServers()
            });

            // Set up data channel
            this.dataChannel = this.peerConnection.createDataChannel('remoteControl', {
                ordered: true
            });

            this.setupEventListeners();

            this.dispatch({ type: 'INITIALIZE_SUCCESS' });
            this.dispatch({
                type: 'UPDATE_CONNECTION',
                payload: {
                    rtc: this.peerConnection,
                    dataChannel: this.dataChannel
                }
            });
        } catch (error) {
            console.error('Failed to initialize remote control:', error);
            this.dispatch({
                type: 'INITIALIZE_FAILURE',
                error: error.message
            });
        }
    }

    setupEventListeners() {
        // WebRTC connection events
        this.peerConnection.onicecandidate = ({ candidate }) => {
            if (candidate) {
                // Send candidate to peer through signaling server
                this.sendSignalingMessage('ice-candidate', candidate);
            }
        };

        this.peerConnection.onconnectionstatechange = () => {
            this.dispatch({
                type: 'UPDATE_STATUS',
                payload: {
                    connected: this.peerConnection.connectionState === 'connected'
                }
            });
        };

        // Data channel events
        this.dataChannel.onopen = () => {
            this.dispatch({
                type: 'UPDATE_STATUS',
                payload: { controlActive: true }
            });
        };

        this.dataChannel.onclose = () => {
            this.dispatch({
                type: 'UPDATE_STATUS',
                payload: { controlActive: false }
            });
        };

        this.dataChannel.onmessage = async (event) => {
            try {
                const decryptedData = await decrypt(event.data, this.encryptionKey);
                this.handleRemoteControlMessage(JSON.parse(decryptedData));
            } catch (error) {
                console.error('Error processing remote control message:', error);
            }
        };
    }

    async startRemoteControl() {
        if (!this.dataChannel || this.dataChannel.readyState !== 'open') {
            throw new Error('Data channel not ready');
        }

        this.dispatch({ type: 'START_REMOTE_CONTROL' });
    }

    async stopRemoteControl() {
        if (this.dataChannel) {
            this.dataChannel.close();
        }
        this.dispatch({ type: 'STOP_REMOTE_CONTROL' });
    }

    async sendRemoteControlInput(inputData) {
        if (!this.dataChannel || this.dataChannel.readyState !== 'open') {
            throw new Error('Data channel not ready');
        }

        try {
            const encryptedData = await encrypt(JSON.stringify(inputData), this.encryptionKey);
            this.dataChannel.send(encryptedData);
        } catch (error) {
            console.error('Error sending remote control input:', error);
            throw error;
        }
    }

    async startScreenSharing() {
        try {
            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always'
                },
                audio: false
            });

            this.screenStream = stream;
            this.dispatch({
                type: 'UPDATE_STREAMS',
                payload: { screen: stream }
            });

            // Add track to peer connection
            stream.getTracks().forEach(track => {
                this.peerConnection.addTrack(track, stream);
            });

            return stream;
        } catch (error) {
            console.error('Error starting screen share:', error);
            throw error;
        }
    }

    stopScreenSharing() {
        if (this.screenStream) {
            this.screenStream.getTracks().forEach(track => track.stop());
            this.dispatch({
                type: 'UPDATE_STREAMS',
                payload: { screen: null }
            });
        }
    }

    async updateMetrics() {
        if (!this.peerConnection) return;

        try {
            const stats = await this.peerConnection.getStats();
            let metrics = {
                bandwidth: 0,
                latency: 0,
                packetLoss: 0,
                resolution: { width: 0, height: 0 }
            };

            stats.forEach(report => {
                if (report.type === 'candidate-pair' && report.state === 'succeeded') {
                    metrics.latency = report.currentRoundTripTime * 1000;
                }
                if (report.type === 'inbound-rtp' && report.kind === 'video') {
                    metrics.packetLoss = report.packetsLost;
                    metrics.bandwidth = report.bytesReceived * 8 / 1000000; // Mbps
                }
                if (report.type === 'track' && report.kind === 'video') {
                    metrics.resolution = {
                        width: report.frameWidth,
                        height: report.frameHeight
                    };
                }
            });

            this.dispatch({
                type: 'UPDATE_METRICS',
                payload: metrics
            });
        } catch (error) {
            console.error('Error updating metrics:', error);
        }
    }

    cleanup() {
        this.stopScreenSharing();
        if (this.dataChannel) {
            this.dataChannel.close();
        }
        if (this.peerConnection) {
            this.peerConnection.close();
        }
    }
}