// components/LandingPage.js
import React from 'react';
import { Typography, Button, Container } from '@material-ui/core';

export default function LandingPage() {
  const handleGoogleLogin = () => {
    const backendURL = process.env.REACT_APP_API_URL || 'https://it-guy.seo-top.dev/api';
    const state = Math.random().toString(36).substring(7);
    // Store state in sessionStorage to verify callback
    sessionStorage.setItem('oauth_state', state);
    window.location.href = `${backendURL}/auth/google?state=${state}`;
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '2rem' }}>
      <Typography variant="h2" gutterBottom>
        Welcome to IT Support Platform
      </Typography>
      <Typography variant="body1" paragraph>
        Get instant IT support or offer your expertise to others.
      </Typography>
      <Button 
        variant="contained" 
        color="primary" 
        onClick={handleGoogleLogin}
      >
        Login with Google
      </Button>
    </Container>
  );
}