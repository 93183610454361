// Frontend/src/components/UserDashboard
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Typography, Grid, Paper, Button, List, ListItem, ListItemText, Dialog,
  DialogTitle, DialogContent, DialogActions, Snackbar, Card, CardContent, Chip, Box, CircularProgress
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../services/api';
import { useAuth } from '../contexts/AuthContext';
import { showNotification } from '../utils/notification';
import { socketManager } from '../services/SocketManager';
import { ConnectionStatus } from './shared/ConnectionStatus';
import { useTokenHandler } from '../hooks/useTokenHandler';
import { useSocketConnection } from '../hooks/useSocketConnection';
import { tokenManager } from '../services/TokenManager';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  ticketList: {
    maxHeight: 400,
    overflow: 'auto',
  },
  connectionStatus: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  statusOnline: {
    backgroundColor: theme.palette.success.main,
  },
  statusOffline: {
    backgroundColor: theme.palette.error.main,
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function UserDashboard() {
  const mounted = useRef(true);
  const initializingRef = useRef(false);
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { checkTokenValidity } = useTokenHandler();
  const { currentUser, isAuthenticated, authLoading } = useAuth();
  const { socket, isConnected, isConnecting, error: socketError } = useSocketConnection();
  const socketRef = useRef(null);

  const handleRemoteSessionInvite = useCallback((data) => {
    console.log('Remote session invite received:', data);
    setRemoteSessionState(prev => ({ ...prev, invite: data, isConnecting: false, error: null }));
    setSnackbar({
      open: true,
      message: `Remote session invitation for ticket: ${data.ticketTitle}`,
      severity: 'info',
      action: (
        <Button
          color="inherit"
          size="small"
          onClick={() => handleJoinSession(data.roomId)}
        >
          Join
        </Button>
      ),
    });
  }, [handleJoinSession]);

  const handleJoinSession = useCallback(async (roomId) => {
    try {
      // Ensure socket is connected before joining
      await socketManager.ensureConnection();

      if (!socketManager.isConnected) {
        showNotification('Cannot join session: Not connected to server', 'error');
        return;
      }

      await socketManager.emit('join', {
        roomId,
        userId: currentUser._id,
        role: currentUser.role,
        name: currentUser.name
      });

      navigate(`/remote-session/${roomId}`);
    } catch (error) {
      console.error('Error joining session:', error);
      showNotification(
        error.message || 'Error joining remote session',
        'error'
      );
    }
  }, [currentUser, navigate]);

  const [dashboardState, setDashboardState] = useState({
    isLoading: true,
    error: null,
    tickets: [],
    activeITUsers: 0
  });

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    selectedTicket: null
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
    action: null
  });

  const [remoteSessionState, setRemoteSessionState] = useState({
    invite: null,
    isConnecting: false,
    error: null
  });

  const [socketState, setSocketState] = useState({
    isConnected: false,
    isConnecting: false,
    error: null
  });

  const [loadingStates, setLoadingStates] = useState({
    isInitializing: false,
    isDataLoading: false,
    error: null
  });

  const [connectionStatus, setConnectionStatus] = useState({
    auth: 'Loading',
    user: 'Unknown',
    socket: 'Disconnected',
    data: 'Loading'
  });

  const handleSessionEnded = useCallback(() => {
    showNotification('Remote session ended by service worker', 'info');
    setRemoteSessionState(prev => ({ ...prev, invite: null }));
  }, []);

  const handleDeclineSession = useCallback(async () => {
    if (remoteSessionState.invite?.roomId) {
      try {
        await socketManager.emit('declineRemoteSession', {
          roomId: remoteSessionState.invite.roomId,
          userId: currentUser._id
        });
        setRemoteSessionState(prev => ({ ...prev, invite: null }));
      } catch (error) {
        console.error('Error declining session:', error);
        showNotification('Failed to decline session', 'error');
      }
    }
  }, [currentUser?._id, remoteSessionState.invite]);

  const fetchDashboardData = useCallback(async () => {
    if (!mounted.current || !currentUser?._id) {
      console.log('Skipping fetchDashboardData - conditions not met:', {
        mounted: mounted.current,
        hasUserId: !!currentUser?._id
      });
      return;
    }

    try {
      console.log('Setting loading state...');
      setDashboardState(prev => ({ ...prev, isLoading: true, error: null }));

      console.log('Making API request...');
      const response = await api.get('/api/tickets/user', {
        params: {
          userId: currentUser._id
        },
        headers: {
          'Service-Name': 'ticket-service'
        }
      });

      if (!mounted.current) {
        console.log('Component unmounted during fetch, skipping update');
        return;
      }

      if (!response.data) {
        throw new Error('No data received from ticket service');
      }

      console.log('Updating dashboard state with data:', response.data);
      setDashboardState(prev => ({
        ...prev,
        isLoading: false,
        tickets: response.data,
        error: null
      }));
    } catch (error) {
      console.error('Error in fetchDashboardData:', error);
      if (!mounted.current) return;
      
      setDashboardState(prev => ({
        ...prev,
        isLoading: false,
        error: 'Failed to load dashboard data'
      }));
      showNotification('Error fetching tickets', 'error');
    }
  }, [currentUser?._id]);

  const handleDeleteTicket = async (ticketId) => {
    try {
      await api.delete(`/api/tickets/${ticketId}`);
      setSnackbar({
        open: true,
        message: 'Ticket deleted successfully',
        severity: 'success'
      });
      fetchDashboardData();
    } catch (error) {
      console.error('Error deleting ticket:', error);
      setSnackbar({
        open: true,
        message: 'Error deleting ticket',
        severity: 'error'
      });
    }
  };

  const handleOpenDialog = useCallback((ticket) => {
    setDialogState(prev => ({ ...prev, selectedTicket: ticket, isOpen: true }));
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogState(prev => ({ ...prev, isOpen: false }));
  }, []);

  // Status counts for chart
  const statusCounts = useMemo(() => {
    const counts = { open: 0, in_progress: 0, resolved: 0 };
    dashboardState.tickets.forEach(ticket => {
      if (counts.hasOwnProperty(ticket.status)) {
        counts[ticket.status]++;
      }
    });
    return counts;
  }, [dashboardState.tickets]);

  // Chart data
  const chartData = {
    labels: ['Open', 'In Progress', 'Resolved'],
    datasets: [{
      label: 'Ticket Status',
      data: [statusCounts.open, statusCounts.in_progress, statusCounts.resolved],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    }]
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Ticket Status',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1
        }
      }
    }
  };

  const handleCreateTicketClick = () => {
    navigate('/issue-selection');
  };

  // Simplify socket initialization
  useEffect(() => {
    console.log('UserDashboard mounted with state:', {
      authLoading,
      isAuthenticated,
      currentUser,
      dashboardLoading: dashboardState.isLoading
    });
  }, [authLoading, isAuthenticated, currentUser, dashboardState.isLoading]);

  useEffect(() => {
    console.log('Checking authentication and user ID:', {
      isAuthenticated,
      userId: currentUser?._id
    });

    if (!currentUser?._id || !isAuthenticated) {
      console.log('Skipping data fetch - not ready:', {
        hasUserId: !!currentUser?._id,
        isAuthenticated
      });
      return;
    }

    const initializeData = async () => {
      try {
        console.log('Fetching dashboard data...');
        await fetchDashboardData();
        console.log('Dashboard data fetched successfully');
      } catch (error) {
        console.error('Error initializing dashboard:', error);
        showNotification('Error loading dashboard data', 'error');
      }
    };

    initializeData();

    // Set up periodic data refresh
    const refreshInterval = setInterval(() => {
      if (mounted.current) {
        fetchDashboardData().catch(console.error);
      }
    }, 30000);

    return () => {
      mounted.current = false;
      clearInterval(refreshInterval);
    };
  }, [currentUser, isAuthenticated, fetchDashboardData]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const logInterval = setInterval(() => {
        const socket = socketRef.current || socketManager.socket;
        if (socket) {
          console.log('Connection state:', {
            hasSocket: !!socket,
            connected: socket.connected,
            socketId: socket.id,
            isConnected: socketState.isConnected,
            dashboardLoading: dashboardState.isLoading,
            currentUser: !!currentUser
          });
        }
      }, 5000);
      return () => clearInterval(logInterval);
    }
  }, [socketState, dashboardState.isLoading]);

  useEffect(() => {
    return () => {
      mounted.current = false;
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  if (authLoading) {
    console.log('Auth loading state:', authLoading);
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (isAuthenticated === false) {
    console.log('Auth is not authenticated');
    return null;
  }

  if (!currentUser || currentUser.role !== 'user') {
    console.log('User is not a user');
    return null;
  }

  if (dashboardState.isLoading) {
    console.log('Dashboard is loading');
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        flexDirection="column"
      >
        <CircularProgress />
        <Typography variant="body2" color="textSecondary" style={{ marginTop: 16 }}>
          Loading dashboard...
        </Typography>
      </Box>
    );
  }

  if (dashboardState.error) {
    console.log('Dashboard has an error:', dashboardState.error);
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
        flexDirection="column"
      >
        <Alert severity="error" style={{ marginBottom: 16 }}>
          {dashboardState.error}
        </Alert>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
        >
          Retry
        </Button>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <ConnectionStatus
        isConnected={isConnected}
        isConnecting={isConnecting}
        error={socketError}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom>User Dashboard</Typography>
          <Chip label={`Active IT Service Users: ${dashboardState.activeITUsers}`} color="primary" />
        </Grid>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" gutterBottom>Need IT Support?</Typography>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                className={classes.createTicketButton}
                onClick={handleCreateTicketClick}
              >
                Create New Support Ticket
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Ticket Status</Typography>
            <Bar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>My Tickets</Typography>
            <List className={classes.ticketList}>
              {dashboardState.tickets.map((ticket) => (
                <ListItem key={ticket._id} button onClick={() => handleOpenDialog(ticket)}>
                  <ListItemText
                    primary={ticket.title}
                    secondary={`Status: ${ticket.status} | Price: $${ticket.suggestedPrice || 'N/A'} | Priority: ${ticket.priority || 'N/A'}`}
                  />
                  {ticket.status === 'open' && (
                    <Button onClick={(e) => { e.stopPropagation(); handleDeleteTicket(ticket._id); }} color="secondary">
                      Delete
                    </Button>
                  )}
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Dialog open={dialogState.isOpen} onClose={handleCloseDialog}>
        <DialogTitle>{dialogState.selectedTicket?.title}</DialogTitle>
        <DialogContent>
          <Typography><strong>Description:</strong> {dialogState.selectedTicket?.description}</Typography>
          <Typography><strong>Status:</strong> {dialogState.selectedTicket?.status}</Typography>
          <Typography><strong>Suggested Price:</strong> ${dialogState.selectedTicket?.suggestedPrice}</Typography>
          <Typography><strong>Priority:</strong> {dialogState.selectedTicket?.priority}</Typography>
          <Typography><strong>Created At:</strong> {dialogState.selectedTicket?.createdAt && new Date(dialogState.selectedTicket.createdAt).toLocaleString()}</Typography>
          {dialogState.selectedTicket?.resolvedAt && (
            <Typography><strong>Resolved At:</strong> {new Date(dialogState.selectedTicket.resolvedAt).toLocaleString()}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!remoteSessionState.invite}
        onClose={() => setRemoteSessionState(prev => ({ ...prev, invite: null }))}
      >
        <DialogTitle>Remote Session Invitation</DialogTitle>
        <DialogContent>
          <Typography>
            An IT support agent is inviting you to a remote session for ticket: {remoteSessionState.invite?.ticketTitle}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDeclineSession}
            color="secondary"
          >
            Decline
          </Button>
          <Button
            onClick={() => {
              if (remoteSessionState.invite?.roomId) {
                handleJoinSession(remoteSessionState.invite.roomId);
              }
            }}
            color="primary"
            autoFocus
          >
            Join Session
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
        action={snackbar.action}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          action={snackbar.action}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}

