// hooks/useRemoteControl.js
import { useEffect, useCallback } from 'react';
import { useRemoteControl } from '../contexts/RemoteControlContext';
import { RemoteControlService } from '../services/RemoteControlService';

export function useRemoteControlSession(roomId) {
    const { state, dispatch } = useRemoteControl();
    let remoteControlService = null;

    const initialize = useCallback(async () => {
        try {
            remoteControlService = new RemoteControlService(dispatch);
            await remoteControlService.initialize();
        } catch (error) {
            console.error('Failed to initialize remote control session:', error);
        }
    }, [dispatch]);

    const startRemoteControl = useCallback(async () => {
        try {
            await remoteControlService?.startRemoteControl();
        } catch (error) {
            console.error('Failed to start remote control:', error);
            dispatch({
                type: 'REMOTE_CONTROL_ERROR',
                error: error.message
            });
        }
    }, [dispatch]);

    const stopRemoteControl = useCallback(async () => {
        try {
            await remoteControlService?.stopRemoteControl();
        } catch (error) {
            console.error('Failed to stop remote control:', error);
        }
    }, []);

    const sendRemoteControlInput = useCallback(async (inputData) => {
        try {
            await remoteControlService?.sendRemoteControlInput(inputData);
        } catch (error) {
            console.error('Failed to send remote control input:', error);
        }
    }, []);

    const startScreenSharing = useCallback(async () => {
        try {
            await remoteControlService?.startScreenSharing();
        } catch (error) {
            console.error('Failed to start screen sharing:', error);
        }
    }, []);

    const stopScreenSharing = useCallback(() => {
        remoteControlService?.stopScreenSharing();
    }, []);

    useEffect(() => {
        let metricsInterval;

        const setup = async () => {
            await initialize();
            // Start monitoring metrics
            metricsInterval = setInterval(() => {
                remoteControlService?.updateMetrics();
            }, 1000);
        };

        if (roomId) {
            setup();
        }

        return () => {
            clearInterval(metricsInterval);
            remoteControlService?.cleanup();
        };
    }, [roomId, initialize]);

    return {
        ...state,
        startRemoteControl,
        stopRemoteControl,
        sendRemoteControlInput,
        startScreenSharing,
        stopScreenSharing
    };
}