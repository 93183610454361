// components/RemoteSession/Layout/index.js
import React, { useMemo } from 'react';
import { Box, Grid, useTheme, useMediaQuery } from '@mui/material';
import VideoPanel from '../VideoPanel';
import ControlsPanel from '../ControlsPanel';
import ChatPanel from '../ChatPanel';
import RemoteControlPanel from '../RemoteControlPanel/index';
import SessionInfoPanel from '../SessionInfo';
import Toolbar from '../ControlsPanel/Toolbar';
import { useRemoteSessionContext } from '../../../contexts/RemoteSessionContext';
import styles from './Layout.module.css';

const Layout = ({
  // Video Props
  localStream,
  remoteStream,
  screenStream,
  isScreenSharing,
  onScreenShare,
  
  // Control Props
  isRemoteControlActive,
  isControlling,
  isRemoteControlRequested,
  onRequestRemoteControl,
  onApproveRemoteControl,
  onDenyRemoteControl,
  onStopRemoteControl,
  onRemoteControlData,
  
  // Media Props
  isAudioEnabled,
  isVideoEnabled,
  isRemoteAudioEnabled,
  isRemoteVideoEnabled,
  onToggleAudio,
  onToggleVideo,
  mediaDevices,
  onSwitchAudioDevice,
  onSwitchVideoDevice,
  
  // Panel States
  activeTab,
  setActiveTab,
  isChatOpen,
  setIsChatOpen,
  isControlPanelOpen,
  setIsControlPanelOpen,
  isSessionInfoOpen,
  setIsSessionInfoOpen,
  
  // Session Info
  sessionInfo,
  connectionQuality,
  networkStats,
  sessionDuration,
  
  // Recording
  isRecording,
  onToggleRecording,
  recordingTime,
  
  // Messages
  messages,
  unreadMessages,
  onNewMessage,
  
  // Connection
  onRestartConnection,
  onMediaFailure,
  onEndSession
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { currentUser } = useRemoteSessionContext();
  
  const layoutConfig = useMemo(() => ({
    mainPanel: {
      xs: 12,
      md: isChatOpen || isControlPanelOpen ? 8 : 12
    },
    sidePanel: {
      xs: 12,
      md: 4
    }
  }), [isChatOpen, isControlPanelOpen]);

  const renderSidePanel = () => {
    if (isChatOpen) {
      return (
        <ChatPanel
          messages={messages}
          unreadCount={unreadMessages}
          onNewMessage={onNewMessage}
          onClose={() => setIsChatOpen(false)}
        />
      );
    }
    
    if (isControlPanelOpen) {
      return (
        <RemoteControlPanel
          isActive={isRemoteControlActive}
          isControlling={isControlling}
          isRequested={isRemoteControlRequested}
          onRequest={onRequestRemoteControl}
          onApprove={onApproveRemoteControl}
          onDeny={onDenyRemoteControl}
          onStop={onStopRemoteControl}
          onData={onRemoteControlData}
          onClose={() => setIsControlPanelOpen(false)}
        />
      );
    }
    
    if (isSessionInfoOpen) {
      return (
        <SessionInfoPanel
          sessionInfo={sessionInfo}
          connectionQuality={connectionQuality}
          networkStats={networkStats}
          duration={sessionDuration}
          onClose={() => setIsSessionInfoOpen(false)}
        />
      );
    }
    
    return null;
  };

  return (
    <Box className={styles.layoutContainer}>
      <Grid container spacing={2}>
        <Grid item {...layoutConfig.mainPanel}>
          <VideoPanel
            localStream={localStream}
            remoteStream={remoteStream}
            screenStream={screenStream}
            isScreenSharing={isScreenSharing}
            isLocalAudioEnabled={isAudioEnabled}
            isLocalVideoEnabled={isVideoEnabled}
            isRemoteAudioEnabled={isRemoteAudioEnabled}
            isRemoteVideoEnabled={isRemoteVideoEnabled}
            connectionQuality={connectionQuality}
          />
          <ControlsPanel
            isAudioEnabled={isAudioEnabled}
            isVideoEnabled={isVideoEnabled}
            isScreenSharing={isScreenSharing}
            isRecording={isRecording}
            recordingTime={recordingTime}
            onToggleAudio={onToggleAudio}
            onToggleVideo={onToggleVideo}
            onScreenShare={onScreenShare}
            onToggleRecording={onToggleRecording}
            mediaDevices={mediaDevices}
            onSwitchAudioDevice={onSwitchAudioDevice}
            onSwitchVideoDevice={onSwitchVideoDevice}
            onRestartConnection={onRestartConnection}
            onMediaFailure={onMediaFailure}
          />
        </Grid>
        {(isChatOpen || isControlPanelOpen || isSessionInfoOpen) && (
          <Grid item {...layoutConfig.sidePanel}>
            {renderSidePanel()}
          </Grid>
        )}
      </Grid>
      
      <Toolbar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isChatOpen={isChatOpen}
        setIsChatOpen={setIsChatOpen}
        isControlPanelOpen={isControlPanelOpen}
        setIsControlPanelOpen={setIsControlPanelOpen}
        isSessionInfoOpen={isSessionInfoOpen}
        setIsSessionInfoOpen={setIsSessionInfoOpen}
        unreadMessages={unreadMessages}
        isServiceWorker={currentUser?.role === 'serviceWorker'}
        onEndSession={onEndSession}
        connectionQuality={connectionQuality}
      />
    </Box>
  );
};

export default Layout;