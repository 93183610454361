import React, { useState } from 'react';
import { Button, Typography, Container, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import { useAuth } from '../contexts/AuthContext';
import api from '../services/api';
import { useNavigate } from 'react-router-dom';

export default function Onboarding() {
  const [userType, setUserType] = useState('');
  const { checkUserLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await api.patch('/api/users/profile', { role: userType === 'it' ? 'service_worker' : 'user' });
      await checkUserLoggedIn(); // Re-fetch user data
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating user type:', error);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Welcome to IT Support Platform
      </Typography>
      <Typography variant="body1" gutterBottom>
        Please tell us how you'll be using the platform:
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormControl component="fieldset">
          <FormLabel component="legend">I am a:</FormLabel>
          <RadioGroup aria-label="user type" name="userType" value={userType} onChange={(e) => setUserType(e.target.value)}>
            <FormControlLabel value="regular" control={<Radio />} label="Regular user seeking IT support" />
            <FormControlLabel value="it" control={<Radio />} label="IT service provider" />
          </RadioGroup>
        </FormControl>
        <Button type="submit" variant="contained" color="primary" disabled={!userType}>
          Continue
        </Button>
      </form>
    </Container>
  );
}