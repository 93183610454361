import React from 'react';
import { Typography } from '@mui/material';

const Timer = ({ time }) => {
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;

    return [
      hours > 0 ? hours : null,
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ]
      .filter(Boolean)
      .join(':');
  };

  return (
    <Typography variant="body2" component="span">
      {formatTime(time)}
    </Typography>
  );
};

export default Timer; 