import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid } from '@material-ui/core';
import api from '../services/api';
import TicketList from './TicketList';
import UserList from './UserList';
import ReportingDashboard from './ReportingDashboard';

export default function AdminDashboard() {
  const [tickets, setTickets] = useState([]);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    fetchTickets();
    fetchUsers();
  }, []);

  const fetchTickets = async () => {
    try {
      const response = await api.get('/tickets');
      setTickets(response.data);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get('/users');
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h4">Admin Dashboard</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <TicketList tickets={tickets} />
        </Paper>
      </Grid>
      <Grid item xs={12} md={6}>
        <Paper>
          <UserList users={users} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper>
          <ReportingDashboard />
        </Paper>
      </Grid>
    </Grid>
  );
}