import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import {
  Typography, Grid, Paper, Button, List, ListItem, ListItemText, Dialog,
  DialogTitle, DialogContent, DialogActions, Snackbar, MenuItem, Select,
  FormControl, InputLabel, Card, CardContent, CardActions, Switch,
  FormControlLabel, CircularProgress, Box
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import api from '../services/api';
import { showNotification } from '../utils/notification';
import { useAuth } from '../contexts/AuthContext';
import { useSocketConnection } from '../hooks/useSocketConnection';
import { ConnectionStatus } from './shared/ConnectionStatus';
import { useTokenHandler } from '../hooks/useTokenHandler';
import { tokenManager } from '../services/TokenManager';
import { socketManager } from '../services/SocketManager';
import { socketService } from '../services/SocketService';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardContent: {
    flexGrow: 1,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  ticketList: {
    maxHeight: 400,
    overflow: 'auto',
  },
  switchContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  loadingOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  },
  connectionStatus: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  statusOnline: {
    backgroundColor: theme.palette.success.main,
  },
  statusOffline: {
    backgroundColor: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },
  connectionStatus: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  statusIndicator: {
    width: 10,
    height: 10,
    borderRadius: '50%',
    marginRight: theme.spacing(1),
  },
  statusOnline: {
    backgroundColor: theme.palette.success.main,
  },
  statusOffline: {
    backgroundColor: theme.palette.error.main,
  }
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

let isInitialFetchDone = false;

export default function ServiceWorkerDashboard() {
  const { checkTokenValidity } = useTokenHandler();
  const classes = useStyles();
  const navigate = useNavigate();
  const { currentUser, isAuthenticated, loading: authLoading } = useAuth();
  const mounted = useRef(true);
  const initializingRef = useRef(false);
  const socketRef = useRef(null);
  const { socket, isConnected, isConnecting } = useSocketConnection();

  const [socketState, setSocketState] = useState({
    isConnected: false,
    isConnecting: false,
    error: null
  });

  const [loadingStates, setLoadingStates] = useState({
    isInitializing: false,
    isDataLoading: false,
    error: null
  });

  const [dashboardState, setDashboardState] = useState({
    isLoading: true,
    error: null
  });

  const [tickets, setTickets] = useState([]);
  const [earnings, setEarnings] = useState(0);

  const [dialogState, setDialogState] = useState({
    isOpen: false,
    selectedTicket: null
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [filters, setFilters] = useState({
    sortField: 'createdAt',
    sortOrder: 'desc',
    showAssignedOnly: true
  });

  const [connectionStatus, setConnectionStatus] = useState({
    auth: 'Loading',
    user: 'Unknown',
    socket: 'Disconnected',
    data: 'Loading'
  });

  const handleError = useCallback((error, action) => {
    console.error(`Error ${action}:`, error);

    let message = error.message;
    if (error.message.includes('Socket not connected')) {
      message = 'Cannot start session: Connection lost. Please try again.';
    } else if (error.message.includes('timeout')) {
      message = 'Connection timed out. Please try again.';
    }

    showNotification(message, 'error');
  }, []);

  const initializeSocket = useCallback(async () => {
    if (!currentUser?._id || !isAuthenticated) return null;

    try {
      const socket = socketManager.connect({
        userId: currentUser._id,
        role: currentUser.role
      });

      socket.on('connect', () => {
        if (mounted.current) {
          setSocketState(prev => ({ 
            ...prev, 
            isConnected: true,
            isConnecting: false,
            error: null 
          }));
        }
      });

      socket.on('connect_error', (error) => {
        if (mounted.current) {
          console.error('Socket connection error:', error);
          setSocketState(prev => ({
            ...prev,
            isConnected: false,
            isConnecting: false,
            error: error.message
          }));
        }
      });

      socket.on('disconnect', (reason) => {
        if (mounted.current) {
          console.log('Socket disconnected:', reason);
          setSocketState(prev => ({
            ...prev,
            isConnected: false,
            error: reason === 'io server disconnect' ? 'Server disconnected' : null
          }));
        }
      });

      return socket;
    } catch (error) {
      console.error('Socket initialization error:', error);
      if (mounted.current) {
        setSocketState(prev => ({
          ...prev,
          isConnecting: false,
          error: error.message
        }));
      }
      return null;
    }
  }, [currentUser, isAuthenticated]);

  const fetchDashboardData = useCallback(async () => {
    if (!checkTokenValidity()) return;

    console.log('Starting fetchDashboardData...', {
      mounted: mounted.current,
      initializing: initializingRef.current
    });

    if (!mounted.current) {
      console.log('Component not mounted, aborting fetch');
      return;
    }

    setDashboardState(prev => {
      console.log('Setting loading state to true');
      return { ...prev, isLoading: true, error: null };
    });

    try {
      console.log('Making API requests...');
      const [ticketsResponse, earningsResponse] = await Promise.all([
        api.get('/api/tickets', {
          params: {
            assigned: filters.showAssignedOnly,
            sortBy: filters.sortField,
            sortOrder: filters.sortOrder,
            status: ['Not Started', 'In Progress']
          }
        }),
        api.get('/api/service-workers/earnings')
      ]);

      if (!mounted.current) {
        console.log('Component unmounted during fetch, aborting state updates');
        return;
      }

      setTickets(ticketsResponse.data || []);
      setEarnings(earningsResponse.data?.totalEarnings || 0);

      setLoadingStates(prev => ({
        ...prev,
        isDataLoading: false,
        initialized: true,
        error: null
      }));

      setDashboardState(prev => ({
        ...prev,
        isLoading: false,
        error: null
      }));

      console.log('All states updated successfully');
      isInitialFetchDone = true;

    } catch (error) {
      console.error('Error in fetchDashboardData:', error);

      if (error.response?.status === 401) {
        tokenManager.handleTokenExpiration();
        return;
      }
      if (mounted.current) {
        const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';

        setLoadingStates(prev => ({
          ...prev,
          isDataLoading: false,
          error: errorMessage
        }));

        setDashboardState(prev => ({
          ...prev,
          error: `Failed to load data: ${errorMessage}`,
          isLoading: false
        }));

        showNotification(`Error loading dashboard data: ${errorMessage}`, 'error');
      }

      if (error.response?.status === 401) {
        navigate('/login');
      }
      throw error;
    }
  }, [filters.showAssignedOnly, filters.sortField, filters.sortOrder, navigate, checkTokenValidity]);

  const handleTicketAction = useCallback(async (ticketId, action, newStatus) => {
    setDashboardState(prev => ({ ...prev, isLoading: true }));

    try {
      let response;
      switch (action) {
        case 'update':
          response = await api.patch(`/api/tickets/${ticketId}`, { status: newStatus });
          break;
        case 'assign':
          response = await api.post(`/api/tickets/${ticketId}/assign`);
          break;
        default:
          throw new Error('Invalid action');
      }

      await fetchDashboardData();
      showNotification('Ticket updated successfully', 'success');

      if (action === 'assign') {
        setDialogState({ isOpen: false, selectedTicket: null });
      }

      return response.data;
    } catch (error) {
      console.error('Error handling ticket action:', error);
      showNotification(`Error: ${error.message}`, 'error');
      throw error;
    } finally {
      setDashboardState(prev => ({ ...prev, isLoading: false }));
    }
  }, [fetchDashboardData]);

  const initiateRemoteSession = useCallback(async (ticketId) => {
    if (!checkTokenValidity()) return;

    try {
      setDashboardState(prev => ({ ...prev, isLoading: true }));
      
      if (!socketService.isConnected()) {
        await socketService.connect();
      }
      
      const socket = socketService.getSocket();
      if (!socket?.connected) {
        throw new Error('Failed to establish socket connection');
      }

      const roomId = `remote-session-${ticketId}-${Date.now()}`;

      console.log('Initiating remote session with socket:', {
        socketId: socket.id,
        connected: socket.connected,
        ticketId,
        roomId,
        serviceWorkerId: currentUser._id
      });

      const response = await new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('Remote session request timeout'));
        }, 10000);

        socket.emit('initiateRemoteSession', {
          ticketId,
          roomId,
          serviceWorkerId: currentUser._id,
          serviceWorkerName: currentUser.name,
          ticketTitle: dialogState.selectedTicket?.title
        }, (response) => {
          clearTimeout(timeout);
          if (response?.error) {
            reject(new Error(response.error));
          } else {
            resolve(response);
          }
        });
      });

      if (response?.success) {
        setDialogState({ isOpen: false, selectedTicket: null });
        navigate(`/remote-session/${roomId}`);
      } else {
        throw new Error(response?.error || 'Failed to initiate remote session');
      }

    } catch (error) {
      console.error('Remote session error:', error);
      showNotification(error.message, 'error');
    } finally {
      setDashboardState(prev => ({ ...prev, isLoading: false }));
    }
  }, [checkTokenValidity]);

  const logSocketStatus = useCallback(() => {
    const socket = socketManager.getSocket();
    console.log('Socket status:', {
      hasSocket: !!socket,
      connected: socket?.connected,
      id: socket?.id,
      state: socketState
    });
  }, [socketState]);

  const isLoading = useMemo(() => {
    const loading =
      authLoading ||
      !isAuthenticated ||
      !currentUser?._id ||
      loadingStates.isInitializing ||
      loadingStates.isDataLoading ||
      socketState.isConnecting;

    console.log('Loading state check:', {
      authLoading,
      isAuthenticated,
      hasUser: !!currentUser?._id,
      isInitializing: loadingStates.isInitializing,
      isDataLoading: loadingStates.isDataLoading,
      isConnecting: socketState.isConnecting,
      isConnected: socketState.isConnected,
      loading
    });

    return loading;
  }, [authLoading, isAuthenticated, currentUser, loadingStates, socketState]);

  const statusCounts = useMemo(() => {
    const counts = { open: 0, in_progress: 0, resolved: 0 };
    tickets.forEach(ticket => {
      if (counts.hasOwnProperty(ticket.status)) {
        counts[ticket.status]++;
      }
    });
    return counts;
  }, [tickets]);

  const chartData = useMemo(() => ({
    labels: ['Open', 'In Progress', 'Resolved'],
    datasets: [{
      label: 'Ticket Status',
      data: [statusCounts.open, statusCounts.in_progress, statusCounts.resolved],
      backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
    }]
  }), [statusCounts]);

  const getLoadingMessage = useCallback(() => {
    if (!isAuthenticated) return 'Please log in...';
    if (!currentUser?._id) return 'Loading user data...';
    if (isConnecting) return 'Establishing connection...';
    if (loadingStates.isDataLoading) return 'Loading dashboard data...';
    if (loadingStates.isInitializing) return 'Initializing...';
    return 'Loading...';
  }, [isAuthenticated, currentUser, isConnecting, loadingStates]);

  const connect = useCallback(async () => {
    if (socketState.isConnecting || !currentUser?._id) return;

    try {
      setSocketState(prev => ({ ...prev, isConnecting: true, error: null }));
      
      const token = tokenManager.getToken();
      if (!token) {
        throw new Error('No authentication token available');
      }

      await initializeSocket();
      
      setSocketState(prev => ({
        ...prev,
        isConnecting: false,
        isConnected: true,
        error: null
      }));

      return true;
    } catch (error) {
      console.error('Connection error:', error);
      setSocketState(prev => ({
        ...prev,
        isConnecting: false,
        isConnected: false,
        error: error.message
      }));
      throw error;
    }
  }, [currentUser, socketState.isConnecting, initializeSocket]);

  const checkSocketConnection = useCallback(async () => {
    if (!socketRef.current?.connected) {
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('Connection timeout'));
        }, 5000);

        const checkInterval = setInterval(() => {
          if (socketRef.current?.connected) {
            clearInterval(checkInterval);
            clearTimeout(timeout);
            resolve(true);
          }
        }, 100);

        // Cleanup on rejection
        timeout.unref?.();
        checkInterval.unref?.();
      });
    }
    return true;
  }, []);

  // Primary initialization & cleanup effect
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const initialize = useCallback(async () => {
    if (!checkTokenValidity() || !currentUser?._id || !isAuthenticated) return;
    if (!currentUser?._id) return 'Loading user data...';
    try {
      if (!mounted.current) return;
      
      setLoadingStates(prev => ({
        ...prev,
        isInitializing: true,
        error: null
      }));

      await initializeSocket();
    if (socketState.isConnecting || !currentUser?._id) return;
      if (mounted.current) {
        await fetchDashboardData();
        setLoadingStates(prev => ({
          ...prev,
          isInitializing: false,
          initialized: true
        }));
      }
    } catch (error) {
      console.error('Initialization error:', error);
      if (mounted.current) {
        setLoadingStates(prev => ({
          ...prev,
          isInitializing: false,
          error: error.message
        }));
      }
    }
  }, [checkTokenValidity, currentUser?._id, isAuthenticated, initializeSocket, fetchDashboardData]);

  // Socket event handlers and state management
  useEffect(() => {
    if (socket) {
      socketRef.current = socket;
    }
    return () => {
      socketRef.current = null;
    };
  }, [socket]);

  const handleSocketEvent = useCallback((event, handler) => {
    if (socketRef.current) {
      socketRef.current.on(event, handler);
      return () => socketRef.current?.off(event, handler);
    }
  }, []);

  // Update socket state handling
  useEffect(() => {
    if (!socketRef.current) return;

    const handlers = {
      connect: () => {
        console.log('Socket connected');
        setSocketState(prev => ({ ...prev, isConnected: true }));
      },
      disconnect: (reason) => {
        console.log('Socket disconnected:', reason);
        setSocketState(prev => ({ ...prev, isConnected: false }));
      },
      error: (error) => {
        console.error('Socket error:', error);
        setSocketState(prev => ({ ...prev, error }));
      }
    };

    Object.entries(handlers).forEach(([event, handler]) => {
      socketRef.current.on(event, handler);
    });

    return () => {
      if (socketRef.current) {
        Object.entries(handlers).forEach(([event, handler]) => {
          socketRef.current.off(event, handler);
        });
      }
    };
  }, []);

  // Data refresh and status updates
  useEffect(() => {
    if (!loadingStates.initialized || !socketState.isConnected) return;

    // Update connection status
    setConnectionStatus({
      auth: isAuthenticated ? 'Authenticated' : 'Not Authenticated',
      user: currentUser?.role || 'Unknown',
      socket: socketState.isConnected ? 'Connected' :
        socketState.isConnecting ? 'Connecting' : 'Disconnected',
      data: loadingStates.isDataLoading ? 'Loading' : 'Loaded'
    });

    // Set up data refresh interval
    const refreshInterval = setInterval(() => {
      fetchDashboardData().catch(error => {
        console.error('Error refreshing data:', error);
        if (error.message.includes('connection') &&
          !socketState.isConnected &&
          !socketState.isConnecting) {
          connect().catch(console.error);
        }
      });
    }, 30000);

    return () => clearInterval(refreshInterval);
  }, [
    loadingStates.initialized,
    socketState,
    isAuthenticated,
    currentUser?.role,
    loadingStates.isDataLoading,
    fetchDashboardData,
    connect
  ]);

  // Debug logging
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      const logInterval = setInterval(() => {
        const socket = socketRef.current || socketManager.socket;
        if (socket) {
          console.log('Connection state:', {
            hasSocket: !!socket,
            connected: socket.connected,
            socketId: socket.id,
            isConnected: socketState.isConnected,
            dashboardLoading: dashboardState.isLoading,
            currentUser: !!currentUser
          });
        }
      }, 5000);

      return () => clearInterval(logInterval);
    }
  }, [socketState, dashboardState.isLoading]);

  if (isLoading) {
    const status = {
      auth: authLoading ? 'Checking' : isAuthenticated ? 'Authenticated' : 'Not Authenticated',
      user: currentUser ? currentUser.role : 'Not Loaded',
      socket: isConnected ? 'Connected' : isConnecting ? 'Connecting' : 'Disconnected',
      data: loadingStates.isDataLoading ? 'Loading' : loadingStates.initialized ? 'Loaded' : 'Not Started'
    };

    console.log('Loading state details:', status);

    return (
      <div className={classes.loadingOverlay}>
        <div style={{ textAlign: 'center' }}>
          <CircularProgress />
          <Typography variant="body2" style={{ marginTop: 16 }}>
            {getLoadingMessage()}
          </Typography>
          <Typography variant="body2" style={{ marginTop: 8, fontSize: '0.8rem', color: '#666' }}>
            Auth: {status.auth} | User: {status.user} |
            Socket: {status.socket} | Data: {status.data}
          </Typography>
          {(socketState.error || loadingStates.error) && (
            <Typography variant="body2" color="error" style={{ marginTop: 8 }}>
              {socketState.error || loadingStates.error}
            </Typography>
          )}
        </div>
      </div>
    );
  }

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Ticket Status',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: { stepSize: 1 }
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.connectionStatus}>
        <Typography variant="body2">
          Auth: {connectionStatus.auth} | User: {connectionStatus.user} | Socket: {connectionStatus.socket} | Data: {connectionStatus.data}
        </Typography>
      </div>
      <Typography variant="h4" gutterBottom>IT Service Provider Dashboard</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography variant="h6" gutterBottom>Earnings</Typography>
              <Typography variant="h4">${earnings}</Typography>
            </CardContent>
            <CardActions>
              <Button color="primary" onClick={fetchDashboardData}>Refresh Earnings</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Ticket Status</Typography>
            <Bar data={chartData} options={chartOptions} />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" gutterBottom>Tickets</Typography>
            <div className={classes.switchContainer}>
              <FormControlLabel
                control={
                  <Switch
                    checked={filters.showAssignedOnly}
                    onChange={() => setFilters(prev => ({ ...prev, showAssignedOnly: !prev.showAssignedOnly }))}
                    color="primary"
                  />
                }
                label="Show Assigned Tickets Only"
              />
            </div>
            <Grid container spacing={2}>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel>Sort By</InputLabel>
                  <Select
                    value={filters.sortField}
                    onChange={(e) => setFilters(prev => ({ ...prev, sortField: e.target.value }))}
                  >
                    <MenuItem value="createdAt">Date Created</MenuItem>
                    <MenuItem value="suggestedPrice">Price</MenuItem>
                    <MenuItem value="priority">Priority</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl className={classes.formControl}>
                  <InputLabel>Order</InputLabel>
                  <Select
                    value={filters.sortOrder}
                    onChange={(e) => setFilters(prev => ({ ...prev, sortOrder: e.target.value }))}
                  >
                    <MenuItem value="asc">Ascending</MenuItem>
                    <MenuItem value="desc">Descending</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <List className={classes.ticketList}>
              {tickets.map((ticket) => (
                <ListItem key={ticket._id} button onClick={() => setDialogState({ isOpen: true, selectedTicket: ticket })}>
                  <ListItemText
                    primary={ticket.title}
                    secondary={`Status: ${ticket.status} | Price: $${ticket.suggestedPrice || 'N/A'} | Priority: ${ticket.priority || 'N/A'}`}
                  />
                  {!filters.showAssignedOnly && !ticket.assignedTo && (
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTicketAction(ticket._id, 'assign');
                      }}
                      color="primary"
                    >
                      Assign to Me
                    </Button>
                  )}
                </ListItem>
              ))}
              {tickets.length === 0 && (
                <ListItem>
                  <ListItemText primary="No tickets found" />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>

      <Dialog open={dialogState.isOpen} onClose={() => setDialogState({ isOpen: false, selectedTicket: null })}>
        <DialogTitle>{dialogState.selectedTicket?.title}</DialogTitle>
        <DialogContent>
          <Typography><strong>Description:</strong> {dialogState.selectedTicket?.description}</Typography>
          <Typography><strong>Status:</strong> {dialogState.selectedTicket?.status}</Typography>
          <Typography><strong>Suggested Price:</strong> ${dialogState.selectedTicket?.suggestedPrice || 'N/A'}</Typography>
          <Typography><strong>Priority:</strong> {dialogState.selectedTicket?.priority || 'N/A'}</Typography>
          <Typography><strong>Created By:</strong> {dialogState.selectedTicket?.createdBy?.name}</Typography>
          <Typography><strong>Created At:</strong> {dialogState.selectedTicket?.createdAt && new Date(dialogState.selectedTicket.createdAt).toLocaleString()}</Typography>
        </DialogContent>
        <DialogActions>
          {dialogState.selectedTicket?.status === 'open' && (
            <Button
              onClick={() => handleTicketAction(dialogState.selectedTicket._id, 'update', 'in_progress')}
              color="primary"
              disabled={dashboardState.isLoading}
            >
              Start Working
            </Button>
          )}
          {dialogState.selectedTicket?.status === 'in_progress' && (
            <>
              <Button
                onClick={() => handleTicketAction(dialogState.selectedTicket._id, 'update', 'open')}
                color="secondary"
                disabled={dashboardState.isLoading}
              >
                Stop Working
              </Button>
              <Button
                onClick={() => handleTicketAction(dialogState.selectedTicket._id, 'update', 'resolved')}
                color="primary"
                disabled={dashboardState.isLoading}
              >
                Mark as Resolved
              </Button>
              <Button
                onClick={() => initiateRemoteSession(dialogState.selectedTicket._id)}
                color="primary"
                variant="contained"
                disabled={!socketState.isConnected || dashboardState.isLoading}
              >
                Start Remote Session
              </Button>
            </>
          )}
          {!dialogState.selectedTicket?.assignedTo && (
            <Button
              onClick={() => handleTicketAction(dialogState.selectedTicket._id, 'assign')}
              color="primary"
              disabled={dashboardState.isLoading}
            >
              Assign to Me
            </Button>
          )}
          <Button onClick={() => setDialogState({ isOpen: false, selectedTicket: null })} color="default" disabled={dashboardState.isLoading}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
}
