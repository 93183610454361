// contexts/RemoteControlContext.js
import React, { createContext, useReducer, useContext, useEffect } from 'react';
import { createEncryptionKey, encrypt, decrypt } from '../utils/encryption';

export const initialState = {
    connections: {
        rtc: null,
        dataChannel: null,
        screenShare: null,
        remoteControl: null
    },
    streams: {
        local: null,
        remote: null,
        screen: null
    },
    status: {
        connected: false,
        recording: false,
        controlActive: false,
        permissions: {
            screen: false,
            audio: false,
            video: false
        }
    },
    security: {
        e2eEncryption: false,
        sessionKey: null,
        verified: false
    },
    metrics: {
        bandwidth: 0,
        latency: 0,
        packetLoss: 0,
        resolution: { width: 0, height: 0 }
    },
    connectionStatus: 'disconnected',
    isInitialized: false,
    error: null
};

export const RemoteControlContext = createContext();

export function RemoteControlProvider({ children }) {
    const [state, dispatch] = useReducer(remoteControlReducer, initialState);

    // Initialize security on mount
    useEffect(() => {
        const initializeSecurity = async () => {
            try {
                const sessionKey = await createEncryptionKey();
                dispatch({
                    type: 'UPDATE_SECURITY',
                    payload: {
                        sessionKey,
                        e2eEncryption: true
                    }
                });
            } catch (error) {
                console.error('Failed to initialize security:', error);
                dispatch({
                    type: 'REMOTE_CONTROL_ERROR',
                    error: 'Failed to initialize security'
                });
            }
        };

        initializeSecurity();
    }, []);

    // Monitor connection status
    useEffect(() => {
        const checkConnection = () => {
            const { rtc, dataChannel } = state.connections;
            if (rtc?.connectionState === 'connected' && dataChannel?.readyState === 'open') {
                dispatch({
                    type: 'UPDATE_STATUS',
                    payload: { connected: true }
                });
            } else {
                dispatch({
                    type: 'UPDATE_STATUS',
                    payload: { connected: false }
                });
            }
        };

        const interval = setInterval(checkConnection, 1000);
        return () => clearInterval(interval);
    }, [state.connections]);

    return (
        <RemoteControlContext.Provider value={{ state, dispatch }}>
            {children}
        </RemoteControlContext.Provider>
    );
}

export function useRemoteControl() {
    const context = useContext(RemoteControlContext);
    if (!context) {
        throw new Error('useRemoteControl must be used within a RemoteControlProvider');
    }
    return context;
}

// Add the missing reducer
const remoteControlReducer = (state, action) => {
  switch (action.type) {
    case 'SET_REMOTE_CONTROL_STATE':
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};