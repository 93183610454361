import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import {
  Mic,
  MicOff,
  Videocam,
  VideocamOff,
  ScreenShare,
  StopScreenShare,
  Chat,
  People,
  Settings
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    margin: theme.spacing(0, 1),
  }
}));

const Toolbar = ({
  audioEnabled,
  videoEnabled,
  screenShareEnabled,
  onToggleAudio,
  onToggleVideo,
  onToggleScreenShare,
  onOpenChat,
  onOpenParticipants,
  onOpenSettings
}) => {
  const classes = useStyles();

  return (
    <div className={classes.toolbar}>
      <Tooltip title={audioEnabled ? "Mute" : "Unmute"}>
        <IconButton 
          className={classes.button}
          onClick={onToggleAudio}
          color={audioEnabled ? "primary" : "default"}
        >
          {audioEnabled ? <Mic /> : <MicOff />}
        </IconButton>
      </Tooltip>

      <Tooltip title={videoEnabled ? "Stop Video" : "Start Video"}>
        <IconButton
          className={classes.button}
          onClick={onToggleVideo}
          color={videoEnabled ? "primary" : "default"}
        >
          {videoEnabled ? <Videocam /> : <VideocamOff />}
        </IconButton>
      </Tooltip>

      <Tooltip title={screenShareEnabled ? "Stop Sharing" : "Share Screen"}>
        <IconButton
          className={classes.button}
          onClick={onToggleScreenShare}
          color={screenShareEnabled ? "primary" : "default"}
        >
          {screenShareEnabled ? <StopScreenShare /> : <ScreenShare />}
        </IconButton>
      </Tooltip>

      <Tooltip title="Chat">
        <IconButton
          className={classes.button}
          onClick={onOpenChat}
        >
          <Chat />
        </IconButton>
      </Tooltip>

      <Tooltip title="Participants">
        <IconButton
          className={classes.button}
          onClick={onOpenParticipants}
        >
          <People />
        </IconButton>
      </Tooltip>

      <Tooltip title="Settings">
        <IconButton
          className={classes.button}
          onClick={onOpenSettings}
        >
          <Settings />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Toolbar;
