// components/RemoteSession/ChatPanel/index.js
import React, { useState, useRef, useEffect } from 'react';
import { 
  Box, 
  IconButton, 
  TextField, 
  Typography, 
  Avatar,
  Tooltip,
  CircularProgress,
  Badge 
} from '@mui/material';
import { 
  Close, 
  Send, 
  AttachFile,
  InsertEmoticon,
  Image,
  Download
} from '@mui/icons-material';
import EmojiPicker from './EmojiPicker';
import { useRemoteSessionContext } from '../../../contexts/RemoteSessionContext';
import { formatMessageTime } from '../../../utils/dateTime';
import styles from './ChatPanel.module.css';

const ChatPanel = ({
  messages,
  unreadCount,
  onNewMessage,
  onClose
}) => {
  const [messageText, setMessageText] = useState('');
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const messagesEndRef = useRef(null);
  const fileInputRef = useRef(null);
  const { currentUser, socketManager, roomId } = useRemoteSessionContext();

  // Auto scroll to bottom when new messages arrive
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Handle message sending
  const handleSendMessage = async () => {
    if (!messageText.trim()) return;

    const message = {
      type: 'text',
      content: messageText.trim(),
      senderId: currentUser._id,
      senderName: currentUser.name,
      timestamp: Date.now()
    };

    try {
      await socketManager.emit('socket:chatMessage', { roomId, message });
      setMessageText('');
      onNewMessage(message);
    } catch (error) {
      console.error('Failed to send message:', error);
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      
      const response = await fetch('/api/upload', {
        method: 'POST',
        body: formData
      });
      
      const { fileUrl, fileType } = await response.json();
      
      const message = {
        type: fileType.startsWith('image/') ? 'image' : 'file',
        content: fileUrl,
        fileName: file.name,
        fileSize: file.size,
        senderId: currentUser._id,
        senderName: currentUser.name,
        timestamp: Date.now()
      };

      await socketManager.emit('socket:chatMessage', { roomId, message });
      onNewMessage(message);
    } catch (error) {
      console.error('Failed to upload file:', error);
    } finally {
      setIsUploading(false);
    }
  };

  // Render message content based on type
  const renderMessageContent = (message) => {
    switch (message.type) {
      case 'text':
        return <Typography>{message.content}</Typography>;
      
      case 'image':
        return (
          <Box className={styles.imageContainer}>
            <img 
              src={message.content} 
              alt={message.fileName}
              className={styles.imageMessage}
              onClick={() => window.open(message.content, '_blank')}
            />
          </Box>
        );
      
      case 'file':
        return (
          <Box className={styles.fileContainer}>
            <AttachFile />
            <Typography>{message.fileName}</Typography>
            <IconButton 
              size="small"
              onClick={() => window.open(message.content, '_blank')}
            >
              <Download />
            </IconButton>
          </Box>
        );
      
      default:
        return null;
    }
  };

  return (
    <Box className={styles.chatPanelContainer}>
      <Box className={styles.chatHeader}>
        <Typography variant="h6">Chat</Typography>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Box>

      <Box className={styles.messagesContainer}>
        {messages.map((message, index) => (
          <Box
            key={index}
            className={`${styles.messageWrapper} ${
              message.senderId === currentUser._id ? styles.ownMessage : ''
            }`}
          >
            <Avatar 
              className={styles.avatar}
              src={message.senderAvatar}
            >
              {message.senderName[0]}
            </Avatar>
            
            <Box className={styles.messageContent}>
              <Typography variant="caption" className={styles.senderName}>
                {message.senderName}
              </Typography>
              
              {renderMessageContent(message)}
              
              <Typography variant="caption" className={styles.timestamp}>
                {formatMessageTime(message.timestamp)}
              </Typography>
            </Box>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Box>

      <Box className={styles.inputContainer}>
        <IconButton onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}>
          <InsertEmoticon />
        </IconButton>
        
        <IconButton onClick={() => fileInputRef.current?.click()}>
          <Badge color="secondary" variant="dot" invisible={!isUploading}>
            <Image />
          </Badge>
        </IconButton>
        
        <TextField
          fullWidth
          multiline
          maxRows={4}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          placeholder="Type a message..."
          disabled={isUploading}
        />
        
        <IconButton 
          onClick={handleSendMessage}
          disabled={!messageText.trim() || isUploading}
        >
          {isUploading ? <CircularProgress size={24} /> : <Send />}
        </IconButton>
      </Box>

      {isEmojiPickerOpen && (
        <EmojiPicker
          onEmojiSelect={(emoji) => {
            setMessageText(prev => prev + emoji);
            setIsEmojiPickerOpen(false);
          }}
          onClose={() => setIsEmojiPickerOpen(false)}
        />
      )}

      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileUpload}
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
        style={{ display: 'none' }}
      />
    </Box>
  );
};

export default ChatPanel;