import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box
} from '@mui/material';

const DeviceSelector = ({
  open,
  onClose,
  mediaDevices,
  onSwitchAudioDevice,
  onSwitchVideoDevice,
  currentAudioDevice,
  currentVideoDevice,
  onMediaFailure
}) => {
  const handleAudioChange = async (event) => {
    try {
      await onSwitchAudioDevice(event.target.value);
    } catch (error) {
      onMediaFailure(error);
    }
  };

  const handleVideoChange = async (event) => {
    try {
      await onSwitchVideoDevice(event.target.value);
    } catch (error) {
      onMediaFailure(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Device Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Microphone</InputLabel>
            <Select
              value={currentAudioDevice?.deviceId || ''}
              onChange={handleAudioChange}
              label="Microphone"
            >
              {mediaDevices.audio.map(device => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Microphone ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Camera</InputLabel>
            <Select
              value={currentVideoDevice?.deviceId || ''}
              onChange={handleVideoChange}
              label="Camera"
            >
              {mediaDevices.video.map(device => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.label || `Camera ${device.deviceId}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default DeviceSelector; 