import React, { useState } from 'react';
import { Box, IconButton, Tooltip, Menu, MenuItem, Badge, Divider } from '@mui/material';
import {
  Mic, MicOff,
  Videocam, VideocamOff,
  ScreenShare, StopScreenShare,
  FiberManualRecord,
  Settings,
  RestartAlt,
  CallEnd,
  KeyboardArrowUp,
} from '@mui/icons-material';
import DeviceSelector from './DeviceSelector';
import Timer from './Timer';
import styles from './ControlsPanel.module.css';

const ControlsPanel = ({
  // Media Controls
  isAudioEnabled,
  isVideoEnabled,
  isScreenSharing,
  isRecording,
  recordingTime,
  onToggleAudio,
  onToggleVideo,
  onScreenShare,
  onToggleRecording,
  
  // Devices
  mediaDevices,
  onSwitchAudioDevice,
  onSwitchVideoDevice,
  
  // Connection
  onRestartConnection,
  onMediaFailure,
  
  // Session
  onEndSession,
  connectionQuality
}) => {
  // State for device menus
  const [anchorEl, setAnchorEl] = useState(null);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [expandedControls, setExpandedControls] = useState(false);

  // Handle device menu
  const handleDeviceMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeviceMenuClose = () => {
    setAnchorEl(null);
  };

  // Render main controls
  const renderMainControls = () => (
    <Box className={styles.mainControls}>
      <Tooltip title={isAudioEnabled ? 'Turn off microphone' : 'Turn on microphone'}>
        <IconButton 
          onClick={onToggleAudio}
          color={isAudioEnabled ? 'primary' : 'error'}
        >
          {isAudioEnabled ? <Mic /> : <MicOff />}
        </IconButton>
      </Tooltip>

      <Tooltip title={isVideoEnabled ? 'Turn off camera' : 'Turn on camera'}>
        <IconButton 
          onClick={onToggleVideo}
          color={isVideoEnabled ? 'primary' : 'error'}
        >
          {isVideoEnabled ? <Videocam /> : <VideocamOff />}
        </IconButton>
      </Tooltip>

      <Tooltip title={isScreenSharing ? 'Stop sharing screen' : 'Share screen'}>
        <IconButton 
          onClick={onScreenShare}
          color={isScreenSharing ? 'secondary' : 'default'}
        >
          {isScreenSharing ? <StopScreenShare /> : <ScreenShare />}
        </IconButton>
      </Tooltip>

      <Tooltip title={isRecording ? 'Stop recording' : 'Start recording'}>
        <IconButton 
          onClick={onToggleRecording}
          color={isRecording ? 'error' : 'default'}
        >
          <Badge
            variant="dot"
            invisible={!isRecording}
            color="error"
          >
            <FiberManualRecord />
          </Badge>
        </IconButton>
      </Tooltip>
    </Box>
  );

  // Render additional controls
  const renderAdditionalControls = () => (
    <Box className={styles.additionalControls}>
      <Tooltip title="Device settings">
        <IconButton onClick={() => setSettingsOpen(true)}>
          <Settings />
        </IconButton>
      </Tooltip>

      <Tooltip title="Restart connection">
        <IconButton 
          onClick={onRestartConnection}
          disabled={connectionQuality === 'good'}
        >
          <RestartAlt />
        </IconButton>
      </Tooltip>

      <Divider orientation="vertical" flexItem />

      <Tooltip title="End call">
        <IconButton 
          onClick={onEndSession}
          color="error"
          className={styles.endCallButton}
        >
          <CallEnd />
        </IconButton>
      </Tooltip>
    </Box>
  );

  return (
    <Box className={styles.controlsPanelContainer}>
      {isRecording && (
        <Box className={styles.recordingIndicator}>
          <FiberManualRecord color="error" />
          <Timer time={recordingTime} />
        </Box>
      )}

      <Box className={styles.controlsWrapper}>
        {renderMainControls()}
        
        <IconButton 
          className={styles.expandButton}
          onClick={() => setExpandedControls(!expandedControls)}
        >
          <KeyboardArrowUp 
            className={expandedControls ? styles.expanded : ''} 
          />
        </IconButton>
        
        {expandedControls && renderAdditionalControls()}
      </Box>

      <DeviceSelector
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        mediaDevices={mediaDevices}
        onSwitchAudioDevice={onSwitchAudioDevice}
        onSwitchVideoDevice={onSwitchVideoDevice}
        currentAudioDevice={mediaDevices.audio.find(d => d.active)}
        currentVideoDevice={mediaDevices.video.find(d => d.active)}
        onMediaFailure={onMediaFailure}
      />
    </Box>
  );
};

export default ControlsPanel; 