// services/SocketManager.js
import io from 'socket.io-client';
import { config } from '../config';
import { showNotification } from '../utils/notification';
import EventEmitter from 'events';
import { tokenManager } from './TokenManager';

class SocketManager extends EventEmitter {
  constructor() {
    super();
    this.socket = null;
    this.state = 'disconnected';
    this.reconnectAttempts = 0;
    this.maxReconnectAttempts = 5;
  }

  async initialize(config) {
    try {
      // Get the correct WebSocket URL based on environment
      const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
      const host = process.env.REACT_APP_WS_HOST || window.location.hostname;
      const port = process.env.REACT_APP_WS_PORT || '3003';
      const wsUrl = `${protocol}//${host}:${port}/socket.io`;

      // Initialize socket with authentication
      this.socket = io(wsUrl, {
        transports: ['websocket'],
        auth: {
          token: config.token
        },
        reconnection: true,
        reconnectionAttempts: this.maxReconnectAttempts,
        reconnectionDelay: 1000,
        timeout: 10000
      });

      // Setup event handlers
      this.setupEventHandlers();
      
      return new Promise((resolve, reject) => {
        this.socket.on('connect', () => {
          this.state = 'connected';
          resolve(this.socket);
        });

        this.socket.on('connect_error', (error) => {
          console.error('Socket connection error:', error);
          reject(error);
        });
      });
    } catch (error) {
      console.error('Socket initialization error:', error);
      throw error;
    }
  }

  setupEventHandlers() {
    this.socket.on('disconnect', (reason) => {
      this.state = 'disconnected';
      if (reason === 'io server disconnect') {
        // Server disconnected us, try to reconnect
        this.socket.connect();
      }
    });

    this.socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
      this.state = 'error';
    });
  }

  async handleConnectionError(error) {
    this.state = 'error';
    
    if (error.message?.includes('jwt expired')) {
      tokenManager.handleTokenExpiration();
      return;
    }

    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      await this.attemptReconnection();
    } else {
      this.emit('maxReconnectAttemptsReached');
      this.cleanup();
    }
  }

  async attemptReconnection() {
    this.state = 'reconnecting';
    this.reconnectAttempts++;
    this.emit('stateChange', this.state);

    const delay = 1000 * Math.pow(2, this.reconnectAttempts - 1);
    
    await new Promise(resolve => setTimeout(resolve, delay));
    
    try {
      await this.initialize(config);
      this.reconnectAttempts = 0;
    } catch (error) {
      console.error('Reconnection attempt failed:', error);
    }
  }

  setupHeartbeat() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }

    this.heartbeatInterval = setInterval(() => {
      if (this.socket?.connected) {
        this.socket.emit('heartbeat');
      }
    }, 30000);

    this.socket.on('heartbeat', () => {
      this.lastHeartbeat = Date.now();
    });
  }

  cleanup() {
    if (this.socket) {
      this.socket.removeAllListeners();
      this.socket.disconnect();
      this.socket = null;
    }
    
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
      this.heartbeatInterval = null;
    }
    
    this.state = 'disconnected';
    this.reconnectAttempts = 0;
    this.lastHeartbeat = null;
    this.removeAllListeners();
  }

  async joinRoom(roomId, options = {}) {
    if (!this.isConnected()) {
      throw new Error('Socket not connected');
    }

    return new Promise((resolve, reject) => {
      this.socket.emit('room:join', { roomId, ...options }, (response) => {
        if (response.error) {
          reject(new Error(response.error));
        } else {
          resolve(response.data);
        }
      });
    });
  }

  leaveRoom(roomId) {
    if (this.socket?.connected && roomId) {
      this.socket.emit('room:leave', { roomId });
    }
  }

  // Remote session methods
  async initiateRemoteSession(params) {
    if (!this.socket?.connected) {
      throw new Error('Socket not connected');
    }

    return new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error('Remote session initiation timeout'));
      }, 10000);

      this.socket.emit('initiateRemoteSession', params, (response) => {
        clearTimeout(timeout);
        if (response?.error) {
          reject(new Error(response.error));
        } else {
          resolve(response);
        }
      });
    });
  }

  onSessionStarted(callback) {
    this.socket?.on('sessionStarted', callback);
  }

  offSessionStarted() {
    this.socket?.off('sessionStarted');
  }

  // WebRTC signaling methods
  sendOffer(roomId, offer) {
    if (this.socket?.connected) {
      this.socket.emit('offer', { roomId, offer });
    }
  }

  sendAnswer(roomId, answer) {
    if (this.socket?.connected) {
      this.socket.emit('answer', { roomId, answer });
    }
  }

  sendIceCandidate(roomId, candidate) {
    if (this.socket?.connected) {
      this.socket.emit('iceCandidate', { roomId, candidate });
    }
  }

  onOffer(callback) {
    this.socket?.on('offer', callback);
  }

  onAnswer(callback) {
    this.socket?.on('answer', callback);
  }

  onIceCandidate(callback) {
    this.socket?.on('iceCandidate', callback);
  }

  offOffer(callback) {
    this.socket?.off('offer', callback);
  }

  offAnswer(callback) {
    this.socket?.off('answer', callback);
  }

  offIceCandidate(callback) {
    this.socket?.off('iceCandidate', callback);
  }

  // Health check methods
  isSocketHealthy() {
    return this.socket?.connected && 
           this.lastHeartbeat && 
           Date.now() - this.lastHeartbeat < 35000; // Slightly longer than heartbeat interval
  }

  setupHealthCheck() {
    if (this.healthCheckInterval) {
      clearInterval(this.healthCheckInterval);
    }

    this.healthCheckInterval = setInterval(() => {
      if (!this.isSocketHealthy()) {
        console.warn('Socket health check failed, attempting reconnection...');
        this.attemptReconnection();
      }
    }, 45000); // Check less frequently than heartbeat
  }

  // Connection state checks
  isConnected() {
    return this.socket?.connected || false;
  }

  getState() {
    return {
      initialized: !!this.socket,
      connected: this.socket?.connected || false,
      state: this.state
    };
  }

  // Connection helpers
  async waitForConnection(timeout = 5000) {
    return new Promise((resolve, reject) => {
      if (this.socket?.connected) {
        resolve(this.socket);
        return;
      }

      const timeoutId = setTimeout(() => {
        this.socket?.removeAllListeners('connect');
        reject(new Error('Connection timeout'));
      }, timeout);

      this.socket.once('connect', () => {
        clearTimeout(timeoutId);
        resolve(this.socket);
      });

      this.socket.once('connect_error', (error) => {
        clearTimeout(timeoutId);
        reject(error);
      });
    });
  }

  // Event handling
  on(event, callback) {
    if (!this.socket) return;
    this.socket.on(event, callback);
  }

  off(event, callback) {
    if (!this.socket) return;
    this.socket.off(event, callback);
  }

  emit(event, data) {
    if (!this.socket) {
      throw new Error('Socket not initialized');
    }
    return new Promise((resolve, reject) => {
      this.socket.emit(event, data, (response) => {
        if (response?.error) {
          reject(new Error(response.error));
        } else {
          resolve(response);
        }
      });
    });
  }

  // Cleanup and disconnection
  disconnect() {
    this.cleanup();
  }

}

export const socketManager = new SocketManager();
