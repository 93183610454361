// components/RemoteSession/SessionInfo.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';
import { useRemoteSessionContext } from '../../contexts/RemoteSessionContext';

const SessionInfo = () => {
  const { 
    isSessionInfoOpen, 
    toggleSessionInfo, 
    roomId,
    localStream,
    remoteStream,
    isScreenSharing,
    isMuted,
    isVideoOff,
    currentParticipants
  } = useRemoteSessionContext();

  return (
    <Dialog 
      open={isSessionInfoOpen} 
      onClose={toggleSessionInfo}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Session Information</DialogTitle>
      <DialogContent>
        <Typography variant="h6" gutterBottom>Connection Details</Typography>
        <Typography>Session ID: {roomId}</Typography>
        <Typography>Connection Status: {remoteStream ? 'Connected' : 'Connecting...'}</Typography>
        
        <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Media Status</Typography>
        <Typography>Camera: {isVideoOff ? 'Off' : 'On'}</Typography>
        <Typography>Microphone: {isMuted ? 'Muted' : 'Active'}</Typography>
        <Typography>Screen Sharing: {isScreenSharing ? 'Active' : 'Inactive'}</Typography>
        
        <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>Participants</Typography>
        {currentParticipants?.map((participant, index) => (
          <Typography key={index}>
            {participant.name} ({participant.role})
          </Typography>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={toggleSessionInfo} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionInfo;