import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';

export default function UserList({ users }) {
  return (
    <>
      <Typography variant="h6">Users</Typography>
      <List>
        {users.map((user) => (
          <ListItem key={user._id}>
            <ListItemText 
              primary={user.name} 
              secondary={`Email: ${user.email} | Role: ${user.role}`} 
            />
          </ListItem>
        ))}
      </List>
    </>
  );
}