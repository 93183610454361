// components/RemoteSession/RemoteSessionWrapper.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import RemoteSession from './index';
import SessionLoader from './SessionLoader';
import SessionError from './SessionError';

function RemoteSessionWrapper() {
  const { roomId } = useParams();
  const { currentUser, socketStatus } = useAuth();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!socketStatus.isConnected && !socketStatus.isConnecting && socketStatus.error) {
      setError('Socket connection failed. Please try refreshing the page.');
    }
  }, [socketStatus]);

  if (!currentUser || !roomId) {
    return <SessionLoader message="Initializing session..." />;
  }

  if (error) {
    return (
      <SessionError 
        error={error}
        onRetry={() => window.location.reload()}
      />
    );
  }

  if (!socketStatus.isConnected) {
    return <SessionLoader message="Establishing connection..." />;
  }

  return <RemoteSession roomId={roomId} />;
}

export default RemoteSessionWrapper;